import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./BannerItem.scss"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerUri from "../../utils/ObtenerUri"
import Button from "../../components/atoms/Button/Button"
import IconEstadoProyecto from "../Icon/IconEstadoProyecto"

const BannerItem = ({ proyecto, activeSlide, totalSlide, linkBanner }) => {
  const onClickBanner = event => {
    if (proyecto?.estilo === "estilo2") {
      localStorage.setItem("modal-open", "true")
    }
  }

  const distritoName = proyecto?.proyecto?.barrios?.edges[0]?.node?.name
  const bannerLink = proyecto?.proyecto?.uri
    ? ObtenerUri(proyecto?.proyecto?.uri)
    : "/"
  const etiquetaEstado = proyecto?.etiquetaDeEstado

  return (
    <>
      <div
        className="banner-item banner-item-inicio hidden"
        style={{ backgroundColor: proyecto?.colorFondo, display: "block" }}
        aria-hidden="true"
        onClick={onClickBanner}
      >
        <div className="banner-item-contenido mx-auto mwx-1680 relative">
          <div className="banner-item-seccion relative">
            {etiquetaEstado && (
              <IconEstadoProyecto
                text={etiquetaEstado}
                style={{ color: proyecto?.colorFondo }}
              />
            )}
            {distritoName && (
              <Link
                tabindex="-1"
                to={bannerLink}
                className="banner-titulo-distrito size-26 font-roboto weight-400"
              >
                {distritoName}
              </Link>
            )}
            <Link
              tabindex="-1"
              to={bannerLink}
              className="banner-titulo-proyecto size-48 font-roboto weight-300"
              dangerouslySetInnerHTML={{ __html: proyecto?.titulo }}
            />
            <Link
              tabindex="-1"
              to={bannerLink}
              className="banner-subtitulo font-roboto weight-400"
              dangerouslySetInnerHTML={{ __html: proyecto?.subtitulo }}
            />
            <Button
              url={bannerLink}
              type="link"
              size="small"
              classEle="banner-boton2"
            >
              <span className="btn--svg">
                <span
                  className="btn--svg__label"
                  style={{ color: proyecto?.colorFondo }}
                >
                  VER PROYECTO
                </span>
                <svg
                  className="btn--svg__circle"
                  width="190"
                  x="0px"
                  y="0px"
                  viewBox="0 0 60 60"
                  enableBackground="new 0 0 60 60"
                >
                  <circle
                    className="js-discover-circle"
                    fill="#2D2D2D"
                    cx="30"
                    cy="30"
                    r="28.7"
                  />
                </svg>
                <svg
                  className="btn--svg__border"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  viewBox="2 29.3 56.9 13.4"
                  enableBackground="new 2 29.3 56.9 13.4"
                  width="190"
                >
                  <g
                    className="btn--svg__border--left js-discover-left-border"
                    id="Calque_2"
                  >
                    <path
                      fill="none"
                      stroke="#2D2D2D"
                      strokeWidth="0.5"
                      strokeMiterlimit="1"
                      d="M30.4,41.9H9c0,0-6.2-0.3-6.2-5.9S9,30.1,9,30.1h21.4"
                    />
                  </g>
                  <g
                    className="btn--svg__border--right js-discover-right-border"
                    id="Calque_3"
                  >
                    <path
                      fill="none"
                      stroke="#2D2D2D"
                      strokeWidth="0.5"
                      strokeMiterlimit="1"
                      d="M30.4,41.9h21.5c0,0,6.1-0.4,6.1-5.9s-6-5.9-6-5.9H30.4"
                    />
                  </g>
                </svg>
              </span>
              <svg
                className="banner-boton-icon"
                x="0px"
                y="0px"
                viewBox="0 0 16 16"
              >
                <polygon fill={proyecto?.colorFondo} points="8,3 0,13 16,13 	" />
              </svg>
            </Button>
            {/* <div className="banner-boton2 font-lato size-21 weight-400 btn btn--svg js-animated-button">
                <Link to={bannerLink} className="btn--svg__label" style={{color: proyecto?.colorFondo}}>
                  VER PROYECTO
                  <svg className="banner-boton-icon" x="0px" y="0px" viewBox="0 0 16 16">
                    <polygon fill={proyecto?.colorFondo} points="8,3 0,13 16,13 	"/>
                  </svg>
                </Link>
                
                <svg className="btn--svg__circle" width="190" x="0px" y="0px" viewBox="0 0 60 60"
                    enableBackground="new 0 0 60 60">
                  <circle className="js-discover-circle" fill="#2D2D2D" cx="30" cy="30" r="28.7"/>
                </svg>
                <svg className="btn--svg__border" x="0px" y="0px" preserveAspectRatio="none" viewBox="2 29.3 56.9 13.4"
                    enableBackground="new 2 29.3 56.9 13.4" width="190">
                  <g className="btn--svg__border--left js-discover-left-border" id="Calque_2">
                    <path fill="none" stroke="#2D2D2D" strokeWidth="0.5" strokeMiterlimit="1"
                          d="M30.4,41.9H9c0,0-6.2-0.3-6.2-5.9S9,30.1,9,30.1h21.4"/>
                  </g>
                  <g className="btn--svg__border--right js-discover-right-border" id="Calque_3">
                    <path fill="none" stroke="#2D2D2D" strokeWidth="0.5" strokeMiterlimit="1" d="M30.4,41.9h21.5c0,0,6.1-0.4,6.1-5.9s-6-5.9-6-5.9H30.4"/>
                  </g>
                </svg>
              </div> */}
            {/* <div
              className="banner-boton font-lato size-21 weight-400"
            >
              Conócelos
              <StaticImage
                src="../../images/boton-flecha-right.png"
                alt="Porcentaje"
                placeholder="NONE"
                layout="fixed"
                className="banner-boton-imagen"
              />
            </div> */}
            <Link
              className="banner-flotante font-roboto color-negro"
              aria-hidden="true"
              to="/legal-promociones/"
              tabindex="-1"
            >
              {proyecto?.estilo === "estilo1" ? (
                <div>
                  <h2
                    className="banner-titulo2-ahorra weight-400 parrafo-0"
                    dangerouslySetInnerHTML={{ __html: proyecto.tituloAhorra }}
                  />
                  <div
                    className="banner-descuento2 weight-300 mb-0 mt-0"
                    dangerouslySetInnerHTML={{ __html: proyecto.texto2ahorra }}
                  />
                </div>
              ) : (
                <>
                  <div className="banner-ahorra">
                    {proyecto?.texto2ahorra && proyecto?.imagenAhorra ? (
                      <>
                        <GatsbyImage
                          tab="img"
                          className="banner-ahorra-imagen"
                          image={
                            proyecto.imagenAhorra.sourceUrlSharp.childImageSharp
                              .gatsbyImageData
                          }
                          alt={GenerarAlt(proyecto.imagenAhorra)}
                        />
                        <h3
                          className="banner-ahorra-titulo size-22-1 weight-300 mt-0 mb-0"
                          dangerouslySetInnerHTML={{
                            __html: proyecto.textoAhorra,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {proyecto?.imagenAhorra && (
                          <GatsbyImage
                            tab="img"
                            className="banner-ahorra2-imagen"
                            image={
                              proyecto.imagenAhorra.sourceUrlSharp
                                .childImageSharp.gatsbyImageData
                            }
                            alt={GenerarAlt(proyecto.imagenAhorra)}
                          />
                        )}
                        {proyecto.imagenAhorra && (
                          <h3
                            className="banner-ahorra-titulo size-22-1 weight-500 mt-0 mb-0"
                            dangerouslySetInnerHTML={{
                              __html: proyecto.textoAhorra,
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {proyecto.textoPreDescuento && (
                    <h3
                      className="banner-predescuento mt-0 mb-0 weight-500"
                      dangerouslySetInnerHTML={{
                        __html: proyecto.textoPreDescuento,
                      }}
                    />
                  )}
                  {proyecto.dsctoHasta && (
                    <h4 className="banner-descuento size-29 mt-0 mb-0 weight-500">
                      Dscto. hasta
                    </h4>
                  )}
                  {proyecto.dsctoHasta && (
                    <h3 className="banner-soles size-50 mt-0 weight-500">
                      {proyecto.dsctoHasta}
                    </h3>
                  )}
                  {proyecto.dsctoDesde && (
                    <h4 className="banner-descuento size-29 mt-0 banner-descuento-desde weight-500">
                      Precios desde {proyecto.dsctoDesde}
                    </h4>
                  )}
                  {proyecto.textoExtra && (
                    <h3
                      className="banner-predescuento banner-extra mt-0 mb-0"
                      dangerouslySetInnerHTML={{ __html: proyecto.textoExtra }}
                    />
                  )}
                  {proyecto.textoExtra2 && (
                    <h3
                      className="banner-texto-extra2 banner-extra mt-0 mb-0"
                      dangerouslySetInnerHTML={{ __html: proyecto.textoExtra2 }}
                    />
                  )}
                </>
              )}
            </Link>
          </div>
          {proyecto?.imagenProyecto && (
            <GatsbyImage
              tab="img"
              className={`banner-item-seccion mov-none banner-item-seccion--${proyecto?.estilo}`}
              image={
                proyecto?.imagenProyecto?.sourceUrlSharp?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={GenerarAlt(proyecto?.imagenProyecto)}
            />
          )}
          {proyecto.imagenProyectoResponsive && (
            <GatsbyImage
              tab="img"
              className="banner-item-seccion mov-block"
              image={
                proyecto.imagenProyectoResponsive.sourceUrlSharp.childImageSharp
                  .gatsbyImageData
              }
              alt={GenerarAlt(proyecto.imagenProyectoResponsive)}
            />
          )}
          <svg
            className="banner-svg svg-animacion mov-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1680 631"
          >
            <path stroke="#2e2e2e" d="M 0,631 L 0,396 L 1680,626 L 1680,0" />
            <path stroke="#2e2e2e" d="M 3,631 L 3,396 L 1677,626 L 1677,0" />
          </svg>
          <svg
            className="banner-svg svg-animacion mov-block w-100"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 362 493"
          >
            <path stroke="#2e2e2e" d="M 0,493 L 0,122 L 362,493 L 362,0" />
            <path stroke="#2e2e2e" d="M 2,493 L 2,122 L 360,493 L 360,0" />
          </svg>
        </div>
      </div>
    </>
  )
}

BannerItem.defaultProps = {
  linkBanner: false,
}

export default BannerItem
