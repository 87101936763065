import React from 'react'
import Fancybox from "../Fancybox/Fancybox"
import GenerarAlt from "../../utils/GenerarAlt"

const ImagenSliderRodeate = ({imagen, titulo, texto, clase, nombre}) => {

  return (
    <div>
      <div className="height-slider relative hidden">
          {imagen?.imagen?.sourceUrlSharp  &&
            <Fancybox>
              <img
                className={`${clase} w-100 rodeate-info-slider-imagen pointer`}
                src={imagen?.imagen?.sourceUrl}
                data-fancybox={nombre}
                data-caption={titulo}
                data-src={imagen?.imagen?.sourceUrl}
                alt={GenerarAlt(imagen.imagen)}
              />
            </Fancybox>

          }
        {
          titulo &&
          <h3 className="rodeate-info-slider-parrafo size-24-1 font-lato weight-400 parrafo-0 mt-0 color-blanco" dangerouslySetInnerHTML={{__html: titulo}} />
        }
        {
          texto &&
          <div className="rodeate-info-slider-parrafo rodeate-info-slider-parrafo2 size-24-1 font-lato weight-400 parrafo-0 mt-0 color-blanco" dangerouslySetInnerHTML={{__html: texto}} />
        }
      </div>
    </div>
  )
}

export default ImagenSliderRodeate
