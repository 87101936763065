import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import GenerarAlt from "../../utils/GenerarAlt"
import "./BannerItemStyle3.scss"
import Button from "../atoms/Button/Button"
import { Link } from "gatsby"

const BannerItemStyle3 = ({ proyecto }) => {
  return (
    <div className="relative">
      {proyecto?.estilo3BotonTexto && (
        <div className="banner-float-button">
          <Link to={proyecto.estilo3BotonLink} className="banner-boton4">
            {proyecto.estilo3BotonTexto}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4 12H20M20 12L16 8M20 12L16 16"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </Link>
        </div>
      )}

      <div className="only-desktop style3-banner-item">
        <GatsbyImage
          tab="img"
          className="style3-bg-img w-full"
          image={
            proyecto.estilo3ImagenFondoDesktop.sourceUrlSharp.childImageSharp
              .gatsbyImageData
          }
          alt={GenerarAlt(proyecto.estilo3ImagenFondoDesktop)}
        />
        <GatsbyImage
          tab="img"
          className="style3-bg-main"
          objectFit="contain"
          image={
            proyecto.estilo3ImagenPrincipalDesktop.sourceUrlSharp
              .childImageSharp.gatsbyImageData
          }
          alt={GenerarAlt(proyecto.estilo3ImagenPrincipalDesktop)}
        />
      </div>
      <div className="only-mov style3-banner-item">
        <GatsbyImage
          tab="img"
          className="style3-bg-img w-full"
          image={
            proyecto.estilo3ImagenFondoMobile.sourceUrlSharp.childImageSharp
              .gatsbyImageData
          }
          alt={GenerarAlt(proyecto.estilo3ImagenFondoMobile)}
        />
        <GatsbyImage
          tab="img"
          className="style3-bg-main"
          image={
            proyecto.estilo3ImagenPrincipalMobile.sourceUrlSharp.childImageSharp
              .gatsbyImageData
          }
          alt={GenerarAlt(proyecto.estilo3ImagenPrincipalMobile)}
        />
      </div>
    </div>
  )
}

export default BannerItemStyle3
