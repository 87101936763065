import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import BannerSlider from "../Banner/BannerSlider"

const BANNER_INICIO_QUERY = graphql`
  {
    pionero {
      page(id: "10", idType: DATABASE_ID) {
        inicio {
          activoModal
          imagenModal {
            altText
          }
          proyectosBanner {
            estilo3BotonLink
            estilo3BotonTexto
            estilo3ImagenFondoDesktop {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            estilo3ImagenFondoMobile {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            estilo3ImagenPrincipalDesktop {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            estilo3ImagenPrincipalMobile {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            colorFondo
            dsctoDesde
            dsctoHasta
            estilo
            tituloAhorra
            titulo
            textoPreDescuento
            textoExtra
            textoAhorra
            texto2ahorra
            textoExtra2
            subtitulo
            proyecto {
              ... on Pionero_Proyecto {
                id
                title
                uri
                slug
                barrios {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
            modalActivado
            etiquetaDeEstado
            imagenAhorra {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            imagenProyecto {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            imagenProyectoResponsive {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

const BannerInicio = ({ titlePage }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg viewBox="0 0 53.76 32.08">
        <line
          strokeWidth="0.85px;"
          fill="none"
          stroke="#2d2d2d"
          strokeMiterlimit="10"
          x1="53.76"
          y1="16.04"
          x2="1.46"
          y2="16.04"
        />
        <polyline
          fill="none"
          stroke="#2d2d2d"
          strokeWidth="0.97px"
          strokeMiterlimit="10"
          points="16.38 0.34 0.69 16.04 16.38 31.73"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg viewBox="0 0 53.76 32.08">
        <line
          strokeWidth="0.85px"
          fill="none"
          stroke="#2d2d2d"
          strokeMiterlimit="10"
          y1="16.04"
          x2="52.3"
          y2="16.04"
        />
        <polyline
          fill="none"
          stroke="#2d2d2d"
          strokeMiterlimit="10"
          strokeWidth="0.97px"
          points="37.38 0.34 53.07 16.04 37.38 31.73"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    pauseOnFocus: false,
    afterChange: current => {
      const header = document.querySelector(".header")
      if (current === 6) {
        header.classList.add("header-white")
      } else {
        header.classList.remove("header-white")
      }
      setActiveSlide(current)
    },
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <StaticQuery
      query={BANNER_INICIO_QUERY}
      render={data => {
        let proyectos = data.pionero?.page?.inicio?.proyectosBanner

        return (
          <>
            <h2 className="d-none">Banner inicio</h2>
            {proyectos && settings && (
              <>
                <BannerSlider
                  configuracion={settings}
                  proyectos={proyectos}
                  activeSlide={activeSlide}
                  totalSlide={proyectos.length}
                  linkBanner={true}
                />
              </>
            )}
          </>
        )
      }}
    />
  )
}

export default BannerInicio
