import React from "react"
import { useEffect } from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerProyecto from "../../components/Proyecto/BannerProyecto"
import RodeateProyecto from "../../components/Proyecto/RodeateProyecto"
import ImagenesProyecto from "../../components/Proyecto/ImagenesProyecto"
import UbicacionProyecto from "../../components/Proyecto/UbicacionProyecto"
import OtrosProyecto from "../../components/Proyecto/OtrosProyecto"
import ContactoProyecto from "../../components/Proyecto/ContactoProyecto"
import CotizarProyecto from "../../components/Proyecto/CotizarProyecto"
import ObtenerUri from "../../utils/ObtenerUri"
import IframeProyectoEnVenta from "../../components/organisms/Iframe/IframeProyectoEnVenta"

export const query = graphql`
  query($id: ID!) {
    pionero {
      proyecto(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        slug
        uri

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        barrios {
          edges {
            node {
              name
            }
          }
        }
        estados {
          edges {
            node {
              name
              slug
            }
          }
        }
        proyecto {
          cotizarBotonFlotanteIdTag
          cotizarIdTag
          celularIdTag
          brochure {
            mediaItemUrl
          }
          idGaBrochure
          bannerTitulo
          bannerTextoSecundario
          bannerColorFondo
          bannerInfo
          bannerImagenProyecto {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          tituloIframe
          urlIframeMultimedia
          bannerResponsiveHomeProyecto {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                  quality: 100
                )
              }
            }
          }
          bannerDetallle {
            texto
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          tituloRodeateDe
          parrafoRodeateDe
          parrafoParaGaleriaAvanceDeObra
          numeroColumnasRodeateDe
          anchoColumnasRodeateDe
          botonesRodeateDe {
            tipo
            texto
            link
            linkPdf {
              ... on Pionero_Pdf {
                id
                uri
                slug
              }
            }
            idGa
            ocultar
          }
          galeriaAvanceRodeateDe {
            titulo
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          detalleRodeateDe {
            texto
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          esHomePet
          imagenPremioRodeateDe {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          parrafoPremioRodeateDe
          galeriaImagenes {
            ancho
            texto
            textoSecundario
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          sliderImagenes {
            texto
            textoSecundario
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          direccionMapa
          imagenMapa {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          botonesMapa {
            link
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          tituloSliderImagenes
          tituloImagenes
          tituloCotiza
          idProyectoCotiza
          textoBotonCotiza
          correoCotiza
          urlWhatsappCotiza
          esTourMultimedia
          imagenMultimedia {
            ...imagen
          }
          logoBannerInterna {
            ...imagen
          }
          urlGraciasCotiza {
            ... on Pionero_Proyecto {
              uri
            }
          }
          tituloOtrosDepas
          proyectoAnteriorOtrosDepas {
            ... on Pionero_Proyecto {
              uri
              title
              barrios {
                edges {
                  node {
                    name
                  }
                }
              }
              proyecto {
                imagenNavegacionAnterior {
                  ...imagen
                }
              }
            }
          }
          proyectoSiguienteOtrosDepas {
            ... on Pionero_Proyecto {
              uri
              title
              barrios {
                edges {
                  node {
                    name
                  }
                }
              }
              proyecto {
                imagenNavegacionSiguiente {
                  ...imagen
                }
              }
            }
          }
          tituloContactanos
          parrafoContactanos
          telefonoContactanos {
            nombre
            telefono
          }
        }
      }
    }
  }

  fragment imagen on Pionero_MediaItem {
    altText
    title
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          quality: 100
        )
      }
    }
  }
`

export default function Proyecto({ data }) {
  const page = data?.pionero?.proyecto

  const url_wsp = page?.proyecto?.urlWhatsappCotiza

  useEffect(() => {
    const header = document.querySelector(".header-btn-wsp")
    if (url_wsp) {
      header.href = url_wsp
    }
  }, [url_wsp])

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="43.28 1.06 2.12 42.22 43.28 83.39"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="1.06 1.06 42.22 42.22 1.06 83.39"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  const settings2 = {
    dots: true,
    fade: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  typeof window !== `undefined` &&
    page.proyecto?.bannerColorFondo &&
    document.documentElement.style.setProperty(
      "--color-pagina",
      page.proyecto?.bannerColorFondo
    )
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")

  return (
    <Layout className="proyecto" isProyecto={true}>
      <Seo post={page} />
      <h1 className="none">{page?.title} </h1>
      <BannerProyecto
        slug={page?.slug}
        distrito={page?.barrios?.edges[0]?.node.name}
        estado={page?.estados?.edges[0]?.node.name}
        titulo={page?.proyecto?.bannerTitulo}
        detalle={page?.proyecto?.bannerDetallle}
        imagen={page?.proyecto?.bannerImagenProyecto}
        fondoColor={page?.proyecto?.bannerColorFondo}
        info={page?.proyecto?.bannerInfo}
        isHome={page?.proyecto?.esHomePet}
        imagenResponsive={page?.proyecto?.bannerResponsiveHomeProyecto}
        textoSecundario={page?.proyecto?.bannerTextoSecundario}
        brochure={page?.proyecto?.brochure?.mediaItemUrl}
        idGaBrochure={page?.proyecto?.idGaBrochure}
        logoInfo={page?.proyecto?.logoBannerInterna}
        cotizarBotonFlotanteIdTag={page?.proyecto?.cotizarBotonFlotanteIdTag}
        cotizarIdTag={page?.proyecto?.cotizarIdTag}
      />
      <RodeateProyecto
        titulo={page?.proyecto?.tituloRodeateDe}
        parrafoAvance={page?.proyecto?.parrafoParaGaleriaAvanceDeObra}
        parrafo={page?.proyecto?.parrafoRodeateDe}
        detalle={page?.proyecto?.detalleRodeateDe}
        botones={page?.proyecto?.botonesRodeateDe}
        nColumnas={page?.proyecto?.numeroColumnasRodeateDe}
        aColumnas={page?.proyecto?.anchoColumnasRodeateDe}
        imagenPremio={page?.proyecto?.imagenPremioRodeateDe}
        parrafoPremio={page?.proyecto?.parrafoPremioRodeateDe}
        galeriaObra={page?.proyecto?.galeriaAvanceRodeateDe}
        id={page?.databaseId}
        settings={settings2}
        brochure={page?.proyecto?.brochure?.mediaItemUrl}
      />
      <ImagenesProyecto
        tituloPage={page?.title}
        galeria={page?.proyecto?.sliderImagenes}
        configuracion={settings}
        classTitulo="bg-pagina"
        titulo={page?.proyecto?.tituloSliderImagenes}
      />
      <ImagenesProyecto
        tituloPage={`${page?.title} 2`}
        configuracion={settings}
        galeria={page?.proyecto?.galeriaImagenes}
        arrow={false}
        titulo={page?.proyecto?.tituloImagenes}
      />

      {page?.proyecto?.urlIframeMultimedia && (
        <IframeProyectoEnVenta
          titulo={page?.proyecto?.tituloIframe}
          iframe={page?.proyecto?.urlIframeMultimedia}
          isTour={page?.proyecto?.esTourMultimedia}
          imagen={page?.proyecto?.imagenMultimedia}
        />
      )}

      <UbicacionProyecto
        tituloPage={page?.title}
        direccion={page?.proyecto?.direccionMapa}
        imagen={page?.proyecto?.imagenMapa}
        botones={page?.proyecto?.botonesMapa}
      />
      <CotizarProyecto
        titulo={page?.proyecto?.tituloCotiza}
        textoBoton={page?.proyecto?.textoBotonCotiza}
        idProyecto={page?.proyecto?.idProyectoCotiza}
        nomProyecto={page?.title}
        isGracias={false}
        urlGracias={ObtenerUri(page?.proyecto?.urlGraciasCotiza.uri)}
        correoCotiza={page?.proyecto?.correoCotiza}
        urlWsp={page?.proyecto?.urlWhatsappCotiza}
      />
      <OtrosProyecto
        titulo={page?.proyecto?.tituloOtrosDepas}
        proyectoAnterior={page?.proyecto?.proyectoAnteriorOtrosDepas}
        proyectoSiguiente={page?.proyecto?.proyectoSiguienteOtrosDepas}
      />
      <ContactoProyecto
        titulo={page?.proyecto?.tituloContactanos}
        parrafo={page?.proyecto?.parrafoContactanos}
        telefonos={page?.proyecto?.telefonoContactanos}
        celularIdTag={page?.proyecto?.celularIdTag}
      />
    </Layout>
  )
}
