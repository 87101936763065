import React from "react"
import { Grid } from "unsemantic/assets/react/dist/index"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./ProyectosBarrio.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerUri from "../../utils/ObtenerUri"

const ProyectosBarrio = ({ proyectos, barrio, grilla, titulo }) => {
  let cantRows = 1

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="43.28 1.06 2.12 42.22 43.28 83.39"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="1.06 1.06 42.22 42.22 1.06 83.39"
        />
      </svg>
    </button>
  )

  if (proyectos.length > 3) cantRows = 2

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    pauseOnFocus: false,
    rows: cantRows,
    responsive: [
      {
        breakpoint: 767,
        infinite: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          rows: 1,
        },
      },
    ],
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  return (
    <section className="proyecto-bm-seccion bg-negro visible">
      <h3 className="none">Slider {titulo}</h3>
      <div className="proyecto-bm-contenido grid-p-0 mwx-1438 mx-auto">
        <Slider {...settings} className="momentoInicio-slider">
          {proyectos &&
            proyectos.map(({ node: proyecto }, i) => (
              <Grid
                key={i}
                desktop={grilla}
                tablet={grilla}
                mobile="100"
                className="proyecto-bm-item"
              >
                <Link
                  to={`${ObtenerUri(proyecto.uri)}`}
                  className="relative block zoom-animacion"
                >
                  {proyecto?.proyecto?.imagenBarrio && (
                    <GatsbyImage
                      className="proyecto-bm-item-imagen w-100"
                      image={
                        proyecto?.proyecto?.imagenBarrio.sourceUrlSharp
                          .childImageSharp.gatsbyImageData
                      }
                      alt={GenerarAlt(proyecto?.proyecto?.imagenBarrio)}
                    />
                  )}
                  <div className="proyecto-bm-imagen-overlay overlay">
                    <h3 className="titulo color-blanco size-23 weight-300 font-roboto mt-0 mb-0 ">
                      {barrio ? barrio : proyecto.barrios.edges[0].node.name}
                    </h3>
                    <h3
                      className="titulo color-blanco size-49 weight-300 font-roboto mt-0 parrafo-0"
                      dangerouslySetInnerHTML={{
                        __html: proyecto.proyecto.bannerTitulo,
                      }}
                    />
                  </div>
                </Link>
              </Grid>
            ))}
        </Slider>
      </div>
    </section>
  )
}

export default ProyectosBarrio
