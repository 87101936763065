import React from "react"
import { useState, useEffect, useRef } from "react"
import ReactModal from "react-modal"
import PestaniaProyecto from "./PestaniaProyecto"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./CotizaProyecto.scss"
import { drupalLogout } from "./ApiCotizar"
import FormGracias from "../Formularios/FormGracias"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "none",
    overflow: "initial",
  },
}

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body")
}

const CotizarProyecto = ({
  titulo,
  textoBoton,
  idProyecto,
  nomProyecto,
  isGracias,
  urlGracias,
  tituloGracias,
  parrafoGracias,
  correoCotiza,
  urlWsp,
}) => {
  const configuracion = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    pauseOnFocus: false,
    lazyLoad: true,
  }

  const [starsCount, setStarsCount] = useState([])
  const [nroDorm, setNroDorm] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(isGracias)
  const mountedRef = useRef(true)

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const objectComparisonCallback = (arrayItemA, arrayItemB) => {
    var modelo_a = arrayItemA.modelo.slice(16)
    if (modelo_a < arrayItemB.modelo) {
      return -1
    }

    if (arrayItemA.modelo > arrayItemB.modelo) {
      return 1
    }

    return 0
  }

  useEffect(() => {
    const fetchData = async () => {
      if (idProyecto) {
        const resultados = await drupalLogout(idProyecto)
        if (resultados) {
          let datos = Object.values(resultados),
            dorm = [],
            newDatos = []
          datos.map(dato => (dorm = [...dorm, dato?.nroDormitorio]))
          const nroDorm = dorm
            .filter((valor, indice) => dorm.indexOf(valor) === indice)
            .sort()
          nroDorm.map(
            nro =>
              (newDatos = [
                ...newDatos,
                datos.filter(dato => dato?.nroDormitorio === nro),
              ])
          )

          newDatos.forEach(element => {
            element.sort(objectComparisonCallback)
          })

          setNroDorm(nroDorm)
          setStarsCount(newDatos)
        }
      }
    }

    fetchData()

    return () => {
      mountedRef.current = false
      setNroDorm("")
      setStarsCount([])
      setIsModalOpen(false)
    }
  }, [idProyecto])
  return (
    <section className="cotizar-seccion visible" id="cotizar">
      <div className="cotizar-contenido">
        {titulo && (
          <h3 className="ubicacion-titulo size-45 font-roboto weight-300 text-center">
            {titulo}
          </h3>
        )}
        {starsCount && (
          <PestaniaProyecto
            nroDorm={nroDorm}
            starsCount={starsCount}
            configuracion={configuracion}
            textoBoton={textoBoton}
            nomProyecto={nomProyecto}
            urlGracias={urlGracias}
            correoCotiza={correoCotiza}
            idProyecto={idProyecto}
            urlWsp={urlWsp}
          />
        )}
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={handleModalClose}
          contentLabel="Modal Cotizar"
          style={customStyles}
        >
          <button onClick={handleModalClose} className="form-cerrar">
            x
          </button>
          {isGracias && (
            <FormGracias titulo={tituloGracias} parrafo={parrafoGracias} />
          )}
        </ReactModal>
      </div>
    </section>
  )
}

export default CotizarProyecto
