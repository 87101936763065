import React from "react";
import "./BannerProyectos.scss"
import Text from "../../atoms/Text/Text";

const BannerProyectos = ({data, className = "bg-verde"}) => {

    const title = data?.tituloDescripcion;
    const texto = data?.textoDescripcion;

    return(
        <section className={`banner-proyectos ${className}`}>
            <div className="mwx-1680 mx-auto color-negro font-roboto">
                {
                    title && <h2 className="banner-proyectos__title weight-400">{title}</h2>
                }
                {
                    texto && <Text value={texto} className="banner-proyectos__text weight-300"/>
                }
            </div>
        </section>
    )
}

export default BannerProyectos;