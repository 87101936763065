import React, { useState } from "react"
import Slider from "react-slick"
import { InView } from "react-intersection-observer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import BarriosInicio from "../Inicio/BarriosInicio"
import MomentosInicio from "../Inicio/MomentosInicio"

const SliderDepas = ({ title1, title2, momentoId, barrioId }) => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [counter, setCounter] = useState(1)

  const onSection = (inView, entry) => {
    if (!inView) return false
    if (counter === 1) {
      setTimeout(() => {
        nav2.slickGoTo(1)
      }, 1000)
      setCounter(2)
    }
  }

  return (
    <InView onChange={onSection}>
      <div className="depa-tabs">
        <Slider
          slidesToShow={2}
          focusOnSelect={true}
          asNavFor={nav2}
          ref={slider1 => setNav1(slider1)}
          aria-hidden="true"
        >
          <div className="text-right" aria-hidden="true">
            <div className={`depa-tabs__item depa-tabs__item-left ${barrioId}`}>
              {title1}
            </div>
          </div>
          <div aria-hidden="true">
            <div
              className={`depa-tabs__item depa-tabs__item-right ${momentoId}`}
            >
              {title2}
            </div>
          </div>
        </Slider>
      </div>
      <Slider
        slidesToShow={1}
        swipeToSlide={true}
        infinite={true}
        arrows={true}
        autoplay={false}
        autoplaySpeed={5000}
        draggable={false}
        swipe={false}
        touchMove={false}
        pauseOnHover={false}
        asNavFor={nav1}
        aria-hidden="true"
        ref={slider2 => setNav2(slider2)}
        responsive={[
          {
            breakpoint: 767,
            settings: {
              pauseOnHover: true,
            },
          },
        ]}
      >
        <div aria-hidden="true">
          <BarriosInicio />
        </div>
        <div aria-hidden="true">
          <MomentosInicio />
        </div>
      </Slider>
    </InView>
  )
}

export default SliderDepas

