const token_url = `${process.env.GATSBY_PAGINA_URL_EVOLTA}/oauth2/token`;
const loginUrl = `${process.env.GATSBY_PAGINA_URL_EVOLTA}/api/inmcotizador?proyecto=`;
const user = process.env.GATSBY_PAGINA_USER_EVOLTA;
const pass = process.env.GATSBY_PAGINA_PASS_EVOLTA;
const grant = process.env.GATSBY_PAGINA_GRANT_EVOLTA;

export const isLoggedIn = async () =>{

  try{
    if (typeof window === 'undefined') {
      return Promise.resolve(false);
    }
  
    const token = localStorage.getItem('access-token') !== null ? JSON.parse(localStorage.getItem('access-token')) : null;
  
    if (token !== null && token.expirationDate > Math.floor(Date.now() / 1000)) {
      return Promise.resolve(token);
    }else{
      const response = await fetch(token_url, {
        method: 'POST',
        body: 'username=' + user + '&grant_type=' + grant + '&password=' + pass,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
  
      if(response.ok){
        const result = await response.json();
        const token = await saveToken(result);
        return Promise.resolve(token);
      }
    }
  
    if (token === null) {
      const response = await fetch(token_url, {
        method: 'POST',
        body: 'username=' + user + '&grant_type=' + grant + '&password=' + pass,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
  
      if(response.ok){
        const result = await response.json();
        const token = await saveToken(result);
        return Promise.resolve(token);
      }
    }
  }catch(e){
    return false;
  }


}

export const saveToken = (json) => {
  const token = { ...json };
  token.date = Math.floor(Date.now() / 1000);
  token.expirationDate = token.date + (token.expires_in);
  localStorage.setItem('access-token', JSON.stringify(token));
  return token;
};

export const drupalLogout = async (id) => {
  const oauthToken = await isLoggedIn();
  if(oauthToken){
    const logoutoken = oauthToken.access_token;
    if (logoutoken) {
      const res = await fetch(`${loginUrl}${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${logoutoken}`,
        },
      });
      if (res.ok) {
        const data = await res.json();
        return Promise.resolve(data);
      }
    }
  }
};
