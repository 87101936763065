import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./UbicacionProyecto.scss"
import GenerarAlt from "../../utils/GenerarAlt"

const UbicacionProyecto = ({ direccion, imagen, botones, tituloPage }) => {
  return (
    <section className="ubicacion-seccion">
      <h3 className="none">Mapa {tituloPage}</h3>
      <div className="ubicacion-contenido mwx-1680 mx-auto">
        {direccion && (
          <h3
            className="ubicacion-titulo size-45 font-roboto weight-300 text-center"
            dangerouslySetInnerHTML={{ __html: direccion }}
          />
        )}
        {imagen && (
          <GatsbyImage
            className="w-100"
            image={imagen?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
            alt={GenerarAlt(imagen)}
          />
        )}
      </div>
      <div className="ubicacion-botones mwx-1680 mx-auto">
        {botones &&
          botones.map((boton, i) => (
            <a key={i} className="ubicacion-boton bg-negro" href={boton?.link}>
              {boton?.imagen && (
                <GatsbyImage
                  className="ubicacion-boton-imagen"
                  image={
                    boton?.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData
                  }
                  alt={GenerarAlt(boton.imagen)}
                />
              )}
            </a>
          ))}
      </div>
    </section>
  )
}

export default UbicacionProyecto
