import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./NuestroHomePet.scss"
import ReactModal from "react-modal"
import SoloTexto from "../../utils/SoloTexto"
import GenerarAlt from "../../utils/GenerarAlt"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "none",
    overflow: "initial",
  },
}

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body")
}

const NuestroHomePet = ({ titulo, parrafo, texto, videos }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModal2Open, setIsModal2Open] = useState(false)

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const handleModal2Close = event => {
    setIsModal2Open(false)
  }

  return (
    <section className="nuestrohomepet-seccion bg-negro">
      <h3 className="none">{SoloTexto(titulo)}</h3>
      <div className="nuestrohomepet-contenido mwx-1680 mx-auto">
        <h3
          className="nuestrohomepet-titulo color-marron text-center font-roboto weight-300 size-56"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
        <div
          className="nuestrohomepet-parrafo color-marron text-center font-lato weight-300 size-23 negrita"
          dangerouslySetInnerHTML={{ __html: parrafo }}
        />
        {videos && (
          <div
            className="nuestrohomepet-video mwx-1136 mx-auto"
            aria-hidden="true"
            onClick={() => setIsModalOpen(true)}
          >
            <h4 className="none">Video Pionero</h4>
            <GatsbyImage
              className="nuestrohomepet-imagen"
              image={
                videos[0].imagen.sourceUrlSharp.childImageSharp.gatsbyImageData
              }
              alt={GenerarAlt(videos[0].imagen)}
            />
          </div>
        )}
        <div
          className="nuestrohomepet-textovideos color-marron mx-auto mwx-136 font-roboto weight-300"
          dangerouslySetInnerHTML={{ __html: texto }}
        />
        {videos && (
          <div
            className="nuestrohomepet-video mwx-1136 mx-auto"
            aria-hidden="true"
            onClick={() => setIsModal2Open(true)}
          >
            <h4 className="none">Video Making of</h4>
            <GatsbyImage
              className="nuestrohomepet-imagen"
              image={
                videos[1].imagen.sourceUrlSharp.childImageSharp.gatsbyImageData
              }
              alt={GenerarAlt(videos[1].imagen)}
            />
          </div>
        )}
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Video1"
        style={customStyles}
      >
        <iframe
          src={videos[0].link}
          frameBorder="0"
          className="nuestrohomepet-iframe"
          title="Video 1 Home Pet"
        />
        <button onClick={handleModalClose} className="form-cerrar">
          x
        </button>
      </ReactModal>
      <ReactModal
        isOpen={isModal2Open}
        onRequestClose={handleModal2Close}
        contentLabel="Modal Video2"
        style={customStyles}
      >
        <iframe
          src={videos[1].link}
          frameBorder="0"
          className="nuestrohomepet-iframe"
          title="Video 2 Home Pet"
        />
        <button onClick={handleModal2Close} className="form-cerrar">
          x
        </button>
      </ReactModal>
    </section>
  )
}

export default NuestroHomePet
