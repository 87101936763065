import React, { useState } from "react"
import "./BannerNosotros.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactModal from "react-modal"
import GenerarAlt from "../../utils/GenerarAlt"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "none",
    overflow: "initial",
  },
}

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body")
}

const BannerNosotros = ({ titulo, parrafo, imagen, urlVideo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  return (
    <section className="bannernosotros-seccion bg-negro">
      <h2 className="d-none" dangerouslySetInnerHTML={{ __html: titulo }} />
      <div className="bannernosotros-contenido mwx-1178 mx-auto">
        <svg
          className="bannernosotros-svg mwx-653 mx-auto block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1695.08 233.01"
        >
          <polygon
            fill="var(--color-pagina)"
            points="167.63 107.63 223.46 107.63 223.46 203.12 167.63 203.12 167.63 231.96 320.71 231.96 320.71 203.12 261.12 203.12 261.12 107.63 320.71 107.63 320.71 78.78 167.63 78.78 167.63 107.63"
          />
          <path
            fill="var(--color-pagina)"
            d="M114.55,126.94c0,7.35-2.25,12.4-6.76,15.25S96.4,146.4,87.27,146.4H37.45V107.47H87.27a57.15,57.15,0,0,1,15,1.61,17.06,17.06,0,0,1,9.1,5.87c2.12,2.75,3.19,6.69,3.19,11.75Zm21.57-37.42Q119.26,78.76,89,78.79H0V232H37.45V174.43H88.67q30.37,0,47.33-11t17-37.1v-.19q0-25.87-16.85-36.64"
          />
          <path
            fill="var(--color-pagina)"
            d="M465.15,191.14q12.57-13,12.55-35.46V155q0-22.45-12.55-35.44t-40.92-13q-28.37,0-40.93,13T370.74,155v.65q0,22.46,12.56,35.46t40.93,13q28.36,0,40.92-13m-90.93,32.61q-20.46-9.2-30.89-26.58t-10.4-41.49V155q0-24.14,10.4-41.49T374.21,87q20.5-9.27,50-9.26T474.18,87q20.44,9.24,30.84,26.56T515.41,155v.65q0,24.14-10.4,41.49t-30.84,26.58Q453.75,233,424.23,233t-50-9.27"
          />
          <path
            fill="var(--color-pagina)"
            d="M1564.63,191.14q12.54-13,12.56-35.46V155q0-22.45-12.56-35.44t-40.92-13q-28.38,0-40.93,13T1470.21,155v.65q0,22.46,12.57,35.46t40.93,13q28.35,0,40.92-13m-90.94,32.61q-20.49-9.2-30.9-26.58t-10.39-41.49V155q0-24.14,10.39-41.49T1473.69,87q20.5-9.27,50-9.26T1573.66,87q20.44,9.24,30.84,26.56T1614.88,155v.65q0,24.14-10.39,41.49t-30.84,26.58q-20.42,9.26-49.95,9.27t-50-9.27"
          />
          <polygon
            fill="var(--color-pagina)"
            points="1014.87 78.78 1014.87 196.97 575.43 78.78 536.96 78.78 536.96 231.95 574.35 231.95 574.35 114.4 1014.32 231.95 1052.27 231.95 1052.27 78.78 1014.87 78.78"
          />
          <polygon
            fill="var(--color-pagina)"
            points="1081.47 231.86 1234.56 231.86 1234.56 202.37 1118.62 202.37 1118.62 168.85 1225.41 168.85 1225.41 140.42 1118.62 140.42 1118.62 108.25 1234.56 108.25 1234.56 78.73 1081.47 78.73 1081.47 231.86"
          />
          <path
            fill="var(--color-pagina)"
            d="M1369.36,137.79c-4.06,2.41-10.22,3.65-18.48,3.65h-51V107.7h48.91a67.3,67.3,0,0,1,14.72,1.3,15.87,15.87,0,0,1,8.79,4.95c2.08,2.46,3.1,6,3.1,10.57,0,6.46-2,10.89-6.07,13.27m21.27,15.79c7.32-2.47,13-6.45,17-12.12s6-12.95,6-21.93q0-22.66-16-31.84t-44.82-9.07H1262.2V231.81h37.7V169.4H1352c8.82,0,15.32,1.81,19.5,5.35s6.23,9.24,6.23,17v40h37.58V189.57c0-9.54-2.12-17.36-6.39-23.38s-10.34-10.25-18.26-12.62"
          />
          <path
            fill="var(--color-pagina)"
            stroke="var(--color-pagina)"
            strokeMiterlimit="10"
            d="M1694.58,24.61c0,14.44-9.67,24.11-24.11,24.11s-24.12-9.67-24.12-24.11S1656,.5,1670.47.5,1694.58,10.17,1694.58,24.61Zm-3.56,0C1691,12.23,1682.85,4,1670.47,4S1650,12.23,1650,24.61s8.18,20.63,20.49,20.63S1691,37,1691,24.61ZM1675.23,28,1682,37.7h-5.19l-6.12-9.39h-5.48V37.7h-4.48V11.38h11c6.19,0,9.89,3.13,9.89,8.53A7.72,7.72,0,0,1,1675.23,28Zm-4.12-13h-5.9v9.67h5.9c3.91,0,5.83-1.56,5.83-4.84S1675,14.94,1671.11,14.94Z"
          />
        </svg>
        <div
          className="text-center bannernosotros-titulo color-pagina size-37 font-roboto weight-300"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
        <div
          className="text-center bannernosotros-parrafo color-pagina size-17 font-lato weight-400 text-justify-center"
          dangerouslySetInnerHTML={{ __html: parrafo }}
        />
        <div
          className="pointer"
          aria-hidden="true"
          onClick={() => setIsModalOpen(true)}
        >
          <GatsbyImage
            className="bannernosotros-imagen-video w-100"
            image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
            alt={GenerarAlt(imagen)}
          />
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Video1"
        style={customStyles}
      >
        <iframe
          src={urlVideo}
          frameBorder="0"
          className="nuestrohomepet-iframe"
          title="Video Nosotros"
        />
        <button onClick={handleModalClose} className="form-cerrar">
          x
        </button>
      </ReactModal>
      <svg
        className="block mwx-1680 mx-auto svg-animacion mov-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1681 167"
      >
        <path
          stroke="var(--color-pagina)"
          d="M 0,167 L 0,3 L 1681,162 L 1681,0"
        />
        <path
          stroke="var(--color-pagina)"
          d="M 3,167 L 3,3 L 1678,162 L 1678,0"
        />
      </svg>
      <svg
        className="block mwx-1680 mx-auto svg-animacion mov-block"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 360 93"
      >
        <path stroke="var(--color-pagina)" d="M 0,93 L 0,0 L 360,90 L 360,0" />
        <path stroke="var(--color-pagina)" d="M 2,93 L 2,0 L 358,90 L 358,0" />
      </svg>
    </section>
  )
}

export default BannerNosotros
