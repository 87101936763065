import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerProyectos from "../../components/organisms/Banner/BannerProyectos"
import GridProyectos from "../../components/organisms/Grid/GridProyectos"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        proyectos_en_venta {
          proyectos {
            distrito
            proyectos {
              colorTextoTitulo
              proyecto {
                ... on Pionero_Proyecto {
                  id
                  title
                  uri
                  estados {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      title
                      srcSet
                      sourceUrl
                      sourceUrlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP, AVIF]
                            placeholder: NONE
                          )
                        }
                      }
                    }
                  }
                  proyecto {
                    grillaIdIdTag
                  }
                }
              }
            }
          }
          textoDescripcion
          tituloDescripcion
        }
      }
    }
  }
`

export default function Home_pet_home({ data }) {
  const page = data.pionero.page
  const pageData = page?.proyectos_en_venta

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-pagina", "#e8d5cc")
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")
  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      <BannerProyectos data={pageData} className="bg-orange-light" />
      <GridProyectos data={pageData} />
    </Layout>
  )
}

