import React from "react"
import "./ContactoInversionistas.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerTagIdPorTipo from "../../utils/ObtenerTagIdPorTipo"

const ContactoInversionistas = ({
  titulo,
  links,
  celularIdTag,
  whatsappIdTag,
  emailIdTag,
}) => {
  return (
    <section className="contactoinversionistas-seccion bg-negro">
      <div className="contactoinversionistas-contenido mx-auto mwx-1680">
        <h3
          className="contactoinversionistas-titulo color-blanco size-28 font-roboto weight-300"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
        <div className="contactoinversionistas-links">
          {links &&
            links.map((link, i) => (
              <a
                key={i}
                href={
                  link.tipo === "email"
                    ? `mailto:${link.valor}`
                    : link.tipo === "telefono"
                    ? `tel:${link.valor}`
                    : link.valor
                }
                target={link.tipo === "whatsapp" ? "_blank" : "_self"}
                className={`contactoinversionistas-link color-pagina ${ObtenerTagIdPorTipo(
                  link.tipo,
                  { celularIdTag, whatsappIdTag, emailIdTag }
                )}`}
                rel="noreferrer"
              >
                <GatsbyImage
                  className={`contactoinversionistas-link-imagen contactoinversionistas-imagen-${link.tipo.toLocaleLowerCase()}`}
                  image={
                    link.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData
                  }
                  alt={GenerarAlt(link.imagen)}
                />
                <span className="contactoinversionistas-link-texto size-36 color-pagina font-lato weight-400">
                  {link.texto}
                </span>
              </a>
            ))}
        </div>
      </div>
    </section>
  )
}

export default ContactoInversionistas
