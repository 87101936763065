import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./PioneroNosotros.scss"
import GenerarAlt from "../../utils/GenerarAlt"

const PioneroNosotros = ({ titulo, parrafo, imagen }) => {
  return (
    <section className="pioneronosotros-seccion bg-negro">
      <div className="pioneronosotros-contenido mwx-1680 mx-auto">
        <GatsbyImage
          className="pioneronosotros-imagen"
          image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={GenerarAlt(imagen)}
        />
        <div className="pioneronosotros-parrafo">
          <svg
            className="pioneronosotros-icono"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 46.1 24.88"
          >
            <g id="Capa_2" data-name="Capa 2">
              <g id="Capa_1-2" data-name="Capa 1">
                <line
                  className="cls-1"
                  fill="none"
                  stroke="var(--color-pagina)"
                  strokeMiterlimit="10"
                  strokeWidth="2px"
                  y1="12.44"
                  x2="44.68"
                  y2="12.44"
                />
                <polyline
                  className="cls-1"
                  fill="none"
                  stroke="var(--color-pagina)"
                  strokeMiterlimit="10"
                  strokeWidth="2px"
                  points="32.95 0.71 44.68 12.44 32.95 24.17"
                />
              </g>
            </g>
          </svg>
          <div>
            <h3
              className="pioneronosotros-titulo color-pagina font-roboto size-37 weight-300"
              dangerouslySetInnerHTML={{ __html: titulo }}
            />
            <div
              className="pioneronosotros-texto color-pagina font-lato size-17 weight-400"
              dangerouslySetInnerHTML={{ __html: parrafo }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PioneroNosotros
