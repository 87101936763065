import React from "react"
import "./OtrosProyecto.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerUri from "../../utils/ObtenerUri"
const OtrosProyecto = ({ titulo, proyectoAnterior, proyectoSiguiente }) => {
  const barrioAnteriorName = proyectoAnterior?.barrios?.edges[0]?.node.name
  const barrioSiguienteName = proyectoSiguiente?.barrios?.edges[0]?.node.name

  return (
    <section className="otros-seccion bg-pagina">
      <div className="otros-contenido mwx-1680 mx-auto">
        {titulo && (
          <h3 className="text-center size-57 font-roboto weight-300">
            {titulo}
          </h3>
        )}
        <div className="otros-proyectos">
          <div className="otros-proyectos-item otros-proyectos-anterior">
            {proyectoAnterior && (
              <Link
                to={ObtenerUri(proyectoAnterior?.uri)}
                className="relative block"
              >
                {proyectoAnterior.proyecto.imagenNavegacionAnterior && (
                  <GatsbyImage
                    className="otros-proyectos-imagen w-100"
                    image={
                      proyectoAnterior.proyecto.imagenNavegacionAnterior
                        .sourceUrlSharp.childImageSharp.gatsbyImageData
                    }
                    alt={GenerarAlt(
                      proyectoAnterior.proyecto.imagenNavegacionAnterior
                    )}
                  />
                )}
                <div className="color-blanco otros-proyectos__text font-roboto">
                  <svg
                    className="otros-proyectos__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 245.83 129.95"
                  >
                    <g id="Capa_2" data-name="Capa 2">
                      <g id="Capa_1-2" data-name="Capa 1">
                        <polygon
                          points="180.86 0 175.79 5.06 232.13 61.4 0 61.4 0 68.55 232.13 68.55 175.79 124.89 180.86 129.95 245.83 64.97 180.86 0"
                          fill="white"
                        />
                      </g>
                    </g>
                  </svg>
                  {barrioAnteriorName && (
                    <h4 className="otros-proyectos__barrio">
                      {barrioAnteriorName}
                    </h4>
                  )}
                  {proyectoAnterior.title && (
                    <h3 className="otros-proyectos__title2">
                      {proyectoAnterior.title}
                    </h3>
                  )}
                </div>
                {/*<GatsbyImage className="otros-proyectos-imagen otros-proyectos-imagen-hover w-100" image={proyectoAnterior.proyecto.imagenNavegacionHoverAnterior.sourceUrlSharp.childImageSharp.gatsbyImageData} alt={GenerarAlt(proyectoAnterior.proyecto.imagenNavegacionHoverAnterior)} />*/}
              </Link>
            )}
          </div>
          <div className="otros-proyectos-item otros-proyectos-siguiente">
            {proyectoSiguiente && (
              <Link
                to={ObtenerUri(proyectoSiguiente.uri)}
                className="relative block"
              >
                {proyectoSiguiente.proyecto.imagenNavegacionSiguiente && (
                  <GatsbyImage
                    className="otros-proyectos-imagen w-100"
                    image={
                      proyectoSiguiente.proyecto.imagenNavegacionSiguiente
                        .sourceUrlSharp.childImageSharp.gatsbyImageData
                    }
                    alt={GenerarAlt(
                      proyectoSiguiente.proyecto.imagenNavegacionSiguiente
                    )}
                  />
                )}
                <div className="color-blanco otros-proyectos__text font-roboto">
                  <svg
                    className="otros-proyectos__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 245.83 129.95"
                  >
                    <g id="Capa_2" data-name="Capa 2">
                      <g id="Capa_1-2" data-name="Capa 1">
                        <polygon
                          points="180.86 0 175.79 5.06 232.13 61.4 0 61.4 0 68.55 232.13 68.55 175.79 124.89 180.86 129.95 245.83 64.97 180.86 0"
                          fill="white"
                        />
                      </g>
                    </g>
                  </svg>
                  {barrioSiguienteName && (
                    <h4 className="otros-proyectos__barrio">
                      {barrioSiguienteName}
                    </h4>
                  )}
                  {proyectoSiguiente?.title && (
                    <h3 className="otros-proyectos__title2">
                      {proyectoSiguiente?.title}
                    </h3>
                  )}
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OtrosProyecto
