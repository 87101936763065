import React from "react";
import "./SliderProyectoFancybox.scss";
import Fancybox from "../../Fancybox/Fancybox";
import GenerarAlt from "../../../utils/GenerarAlt";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SliderProyectoFancybox = ({data,nombre,classTitulo="",arrow=true, ...others}) =>{

    const titulo = data?.titulo;

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
          {...props}
          className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === 0 ? true : false}
          type="button"
        >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="244.000000pt" height="244.000000pt" viewBox="0 0 244.000000 244.000000"
          preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,244.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M1011 2414 c-225 -38 -451 -151 -626 -315 -355 -333 -476 -865 -299
              -1323 60 -154 139 -278 257 -403 399 -420 1056 -496 1543 -178 104 68 238 195
              316 299 71 95 159 272 188 376 119 430 14 866 -285 1179 -186 195 -419 320
              -682 366 -105 18 -299 18 -412 -1z m480 -68 c230 -58 402 -156 559 -316 240
              -244 365 -592 331 -919 -29 -282 -142 -522 -336 -716 -316 -318 -779 -426
              -1200 -282 -377 130 -657 437 -757 834 -20 75 -23 114 -23 268 1 152 4 194 23
              268 69 274 221 502 447 672 130 98 333 182 503 210 128 21 330 13 453 -19z"/>
              <path d="M1155 1440 l-260 -260 262 -262 263 -263 20 20 20 20 -245 245 -245
              245 242 242 c209 209 240 244 229 257 -8 9 -16 16 -20 16 -3 0 -123 -117 -266
              -260z"/>
              </g>
          </svg>
        </button>
      );
      const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
          {...props}
          className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === slideCount - 1 ? true : false}
          type="button"
        >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="244.000000pt" height="245.000000pt" viewBox="0 0 244.000000 245.000000"
          preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,245.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M1009 2424 c-649 -117 -1093 -731 -994 -1374 21 -141 50 -232 110
              -356 206 -424 623 -684 1095 -684 504 0 953 307 1134 776 133 344 98 752 -90
              1063 -187 308 -496 519 -844 576 -115 18 -308 18 -411 -1z m384 -49 c403 -61
              757 -340 906 -717 61 -152 75 -234 76 -433 0 -154 -3 -193 -23 -268 -87 -342
              -302 -615 -607 -769 -157 -80 -296 -116 -476 -125 -328 -16 -638 105 -874 342
              -194 194 -307 434 -336 716 -51 490 254 995 712 1178 210 84 408 108 622 76z"/>
              <path d="M999 1694 c-11 -13 20 -48 229 -257 l242 -242 -245 -245 -245 -245
              20 -20 20 -20 263 263 262 262 -260 260 c-143 143 -263 260 -266 260 -4 0 -12
              -7 -20 -16z"/>
              </g>
          </svg>
        </button>
      );
    
      const settings = {
        dots: true,
        arrows: arrow,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true
            }
          }
        ]
      };

    return (
        <div>
            {
                data && 
                <Slider {...settings} className="slider-proyecto-fancybox">
                  { data && data.map((imagen,i)=>{
                    
                    return(
                        <div key={i}>
                            <div className="slider-proyecto-fancybox__item relative" {...others}>
                                {
                                    <div className={`slider-proyecto-fancybox__text-container font-roboto`}>
                                        <div className="slider-proyecto-fancybox__title color-blanco">{imagen?.titulo}</div>
                                        <div className="slider-proyecto-fancybox__texto color-blanco">{imagen?.texto}</div>
                                    </div>
                                }
                                {
                                    imagen?.imagen?.sourceUrlSharp  &&
                                    <Fancybox>
                                        <img
                                            className={`w-100 pointer slider-proyecto-fancybox__image img-cover`}
                                            src={imagen?.imagen?.sourceUrl}
                                            data-fancybox={nombre}
                                            data-caption={titulo}
                                            data-src={imagen?.imagen?.sourceUrl}
                                            alt={GenerarAlt(imagen.imagen)}
                                        />
                                    </Fancybox>
                                }
                            </div>
                        </div>
                    )
                  })
                  }
                </Slider>
              }
        </div>
    )
}

export default SliderProyectoFancybox;