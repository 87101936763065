import React from "react"
import "./GridEntregados.scss"
import Image from "../../atoms/Image/Image"

const GridEntregados = ({ data }) => {
  const proyectos = data?.proyectos

  return (
    <section className="bg-negro font-roboto">
      <h3 className="d-none">Grilla de proyectos entregados</h3>
      <div className="mx-auto mwx-1680 grid-entregados color-blanco flex-column-center">
        {proyectos &&
          proyectos.map((proyecto, i) => {
            return (
              <div className="grid-entregados__item flex" key={i}>
                <div className="grid-entregados__item-left">
                  <h3 className="grid-entregados__item__title weight-300 mb-0">
                    {proyecto.distrito}
                  </h3>
                  <div className="grid-entregados__item__subtitle weight-300">
                    {proyecto.texto}
                  </div>
                </div>
                {proyecto.imagen && (
                  <div className="grid-entregados__item-right w-100">
                    <Image
                      data={proyecto.imagen}
                      className="grid-entregados__item_image w-100"
                    />
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default GridEntregados
