import React from "react";

const Share = props => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.97 34.78">
            <path
            d="M1.33 5.1 28.2 1.62 40.47 0c1.53-.17 2.67.75 2.47 2a3.39 3.39 0 0 1-.72 1.48Q37.13 10 32 16.47l-13.58 17.2a2.34 2.34 0 0 1-1.73 1.11A2 2 0 0 1 14.62 33c-.84-4.22-1.7-8.43-2.46-12.67a4.93 4.93 0 0 0-1.73-3c-3.15-2.74-6.21-5.58-9.31-8.37C-.18 7.69-.3 7 .53 5.56l.8-.45m5 3.26.58.55c2.08 1.88 4.16 3.74 6.22 5.64a1 1 0 0 0 1.36.18c5.31-3 10.64-6.06 16-9.08.21-.12.4-.25.6-.38v-.12L6.36 8.37m26.71.51L33 8.77a3.85 3.85 0 0 0-.45.19q-8.15 4.62-16.3 9.23c-.46.26-.48.55-.39 1 .56 2.82 1.11 5.64 1.66 8.46 0 .21.11.41.2.7L33.07 8.88"
            style={{fill: "var(--color-pagina)"}}
            data-name="Capa 1"
            />
        </svg>
    )
}

export default Share;