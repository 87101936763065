import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./RodeateInversionistas.scss"
import GenerarAlt from "../../utils/GenerarAlt"

const RodeateInversionistas = ({ titulo, parrafo, iconos }) => {
  return (
    <section className="bg-pagina">
      <h3 className="d-none">{titulo}</h3>
      <div className="rodeateinversionistas-conenido mx-auto mwx-1560">
        <div className="size-56 font-roboto weight-300 rodeateinversionistas-titulo mt-0">
          {titulo}
        </div>
        <div
          className="rodeateinversionistas-parrafo size-22 weight-300 mt-0 mb-0"
          dangerouslySetInnerHTML={{ __html: parrafo }}
        />
        <div className="rodeateinversionistas-iconos">
          {iconos &&
            iconos.map((icono, i) => (
              <div key={i} className="rodeateinversionistas-icono text-center">
                <GatsbyImage
                  className="rodeateinversionistas-icono-imagen"
                  image={
                    icono.imagen.sourceUrlSharp.childImageSharp.gatsbyImageData
                  }
                  alt={GenerarAlt(icono.imagen)}
                />
                <h4 className="rodeateinversionistas-icono-titulo font-roboto weight-300 size-28 text-center">
                  {icono.titulo}
                </h4>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default RodeateInversionistas
