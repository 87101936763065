import React,{Fragment, useState} from "react";
import "./SliderProyecto.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import Image from "../../atoms/Image/Image";
import { Link } from "gatsby";
import ObtenerUri from "../../../utils/ObtenerUri" 

const SliderProyecto = ({data}) =>{
 
    const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
    const [clientYonMouseDown, setClientYonMouseDown] = useState(null);

    const countProject = data?.length;

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
      <button
        {...props}
        className={
          "slick-prev slick-arrow" +
          (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0}
        type="button"
      >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M2445 3700 l-1140 -1140 1140 -1140 1140 -1140 102 102 c57 57 103
          107 103 113 0 5 -462 472 -1027 1037 l-1028 1028 1028 1028 c565 565 1027
          1032 1027 1037 0 6 -46 56 -103 113 l-102 102 -1140 -1140z"/>
          </g>
        </svg>

      </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
      <button
        {...props}
        className={
          "slick-next slick-arrow" +
          (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1}
        type="button"
      >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1432 4737 c-56 -56 -102 -107 -102 -112 0 -6 462 -472 1027 -1037
        l1028 -1028 -1028 -1028 c-565 -565 -1027 -1032 -1027 -1037 0 -6 46 -56 103
        -113 l102 -102 1140 1140 1140 1140 -1140 1140 -1140 1140 -103 -103z"/>
        </g>
        </svg>
      </button>
    );
  
    const settings = {
      dots: false,
      arrows: true,
      infinite: countProject > 1 ? true : false,
      slidesToShow: 2,
      slidesToScroll: 1,
      // autoplay: false,
      autoplay: true,
      autoplaySpeed: 3500,
      pauseOnHover: false,
      pauseOnFocus: false,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
    };

    if(!data) return <></>
    const proyecto = data[0];
    const title = proyecto?.proyecto?.title;
    const colorTexto = proyecto?.colorTextoTitulo;
    const uri = proyecto?.proyecto?.uri;
    const estado = proyecto?.proyecto?.estados?.nodes[0];
    const featuredImage = proyecto?.proyecto?.featuredImage?.node;

    const handleOnMouseDown = (e) => {
      setClientXonMouseDown(e.clientX);
      setClientYonMouseDown(e.clientY);
      e.preventDefault();
    }
  
    const handleOnClick = (e) => {
      e.stopPropagation()
      if (clientXonMouseDown !== e.clientX || 
          clientYonMouseDown !== e.clientY) {
        e.preventDefault()
      }
    }

    return (
      <>
        {
          (countProject > 1) ? 
            <Slider {...settings}>
                {
                    data && data.map((proyecto, i) => {
                      if (!proyecto?.proyecto) return <></>;
                      const title = proyecto?.proyecto?.title;
                      const colorTexto = proyecto?.colorTextoTitulo;
                      const uri = proyecto?.proyecto?.uri;
                      const estado = proyecto?.proyecto?.estados?.nodes[0];
                      const featuredImage = proyecto?.proyecto?.featuredImage?.node;
                      const tagId = proyecto?.proyecto?.proyecto?.grillaIdIdTag;

                      return(
                        <Link to={ObtenerUri(uri)} key={i} className={`relative slider-proyecto__item ${tagId}`} onMouseDown={handleOnMouseDown}
                        onClick={handleOnClick}>
                          <div className="slider-proyecto-item-float">
                              { estado && <div className="slider-proyecto__estado bg-negro inline-flex color-orange-light uppercase">{estado.name}</div>}
                              { title && <div className="slider-proyecto__title block font-roboto" style={{color: colorTexto}}>{title}</div>}
                          </div>
                          {
                            featuredImage && 
                            <div>
                                <Image data={featuredImage} className="w-100 img-cover slider-proyecto__image"/>
                            </div>
                          }
                        </Link>
                      )
                    })
                }
            </Slider>
          :
          <div className="relative slider-proyecto__item">
            <div className="slider-proyecto-item-float">
                { estado && <div className="slider-proyecto__estado bg-negro inline-flex color-orange-light">{estado.name}</div>}
                { title && <Link to={ObtenerUri(uri)} className="slider-proyecto__title block font-roboto" style={{color: colorTexto}}>{title}</Link>}
            </div>
            {
              featuredImage && 
              <div>
                  <Image data={featuredImage} className="w-100 img-cover slider-proyecto__image"/>
              </div>
            }
          </div>
        }
      </>
    )
}

export default SliderProyecto;