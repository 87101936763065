import React, {useState} from 'react';
import ReactModal from 'react-modal';
import "./IframeProyectoEnVenta.scss";
import Image from '../../atoms/Image/Image';
import Email from '../../atoms/Icons/Email';
import Copy from '../../atoms/Icons/Copy';
import Whatsapp from '../../atoms/Icons/Whatsapp';
// import Messenger from '../../atoms/Icons/Messenger';
import Share from '../../atoms/Icons/Share';
import ObtenerUrlActual from '../../../utils/ObtenerUrlActual';

const IframeProyectoEnVenta = ({titulo,iframe, isTour, imagen}) => {

  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 'none',
      overflow: 'initial',
      backgroundColor: 'white !important'
    }
  };
  
  if (typeof(window) !== 'undefined') {
    ReactModal.setAppElement('body')
  }

  const [modalShare, setModalShare] = useState(false);
  const [modalIframe, setModalIframe] = useState(false);
  
  const modalShareClose = event => {
    setModalShare(false)
  }

  const modalIframeClose = event => {
    setModalIframe(false)
  }

  const handleCopy = () =>{
    var $newInput = document.createElement("input");
    var $body = document.body;
    $newInput.setAttribute("value", window.location.href);
    $body.appendChild($newInput);
    $newInput.select();
    document.execCommand("copy");
    $body.removeChild($newInput);
  }

    return (
        <div className="bg-negro">
          <div className="iframeProyecto mwx-1320 mx-auto">
            {
              titulo &&
              <h3 className="weight-500 text-center size-45 font-roboto iframeProyecto__title color-blanco">{titulo}</h3>
            }
            {
                iframe &&
                <iframe src={iframe} frameBorder="0" className={`iframeProyecto__iframe mx-auto block w-100 ${isTour && 'only-desktop'}`} title="Multimedia" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen/>
            }

            {
              isTour &&
              <>
                {
                  imagen && 
                  <div className="only-mov" onClick={()=>setModalIframe(true)} aria-hidden="true">
                    <Image className="iframeProyecto__imagen" data={imagen}/>
                  </div>
                }
              </>
            }
            <div className="text-center">
            <button onClick={()=>setModalShare(true)} className="iframeProyecto__link color-pagina mx-auto weight-bold font-lato inline-flex pointer">
              <Share/>
              Compartir
            </button>
            </div>
          </div>
          <ReactModal
            isOpen={modalIframe}
            onRequestClose={modalIframeClose}
            contentLabel="Compartir"
            style={customStyles}
          >
            <div className="iframeProyecto-modal-iframe">
              {
                iframe &&
                <iframe src={iframe} frameBorder="0" className="iframeProyecto-modal-iframe__iframe mx-auto block w-100" title="Multimedia modal iframe" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen/>
              }
            </div>
          <button onClick={modalIframeClose} className="form-cerrar">x</button>
          </ReactModal>
          <ReactModal
            isOpen={modalShare}
            onRequestClose={modalShareClose}
            contentLabel="Compartir"
            style={customStyles}
          >
          <dir className="iframeProyecto-modal">
            <h3 className="iframeProyecto-modal__title">Compartir este proyecto</h3>
            <div className="iframeProyecto-modal__grid">
              <button className="iframeProyecto-modal__button" onClick={handleCopy}>
                <Copy/> Copiar el enlace
              </button>
              <a className="iframeProyecto-modal__button" href={`mailto:?to=&body=${ObtenerUrlActual()}`}>
                <Email/> Correo electrónico
              </a>
              <a className="iframeProyecto-modal__button iframeProyecto-modal__button-wsp" href={`https://api.whatsapp.com/send?text=${ObtenerUrlActual()}`} rel="noreferrer" target="_blank">
                <Whatsapp/>WhatsApp
              </a>
              {/* <a className="iframeProyecto-modal__button" href={`https://www.facebook.com/dialog/send?
    app_id=728720008916834&link=${ObtenerUrlActual()}&redirect_uri=${ObtenerUrlActual()}`} target="_blank" rel="noreferrer">
                <Messenger/>
                Messenger
              </a> */}
            </div>
          </dir>
          <button onClick={modalShareClose} className="form-cerrar">x</button>
        </ReactModal>
        </div>
    );
}

export default IframeProyectoEnVenta;