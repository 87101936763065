import React from "react"
import "./BannerBarrioMomento.scss"
import { navigate } from "gatsby"
import changeTextSoltera from "../../help/changeTextSoltera"

const BannerBarrio = ({ titulo, fondoColor, barrio, momento }) => {
  return (
    <section
      className="banner-bm-seccion visible"
      style={{ backgroundColor: fondoColor }}
    >
      <h2 className="d-none">{changeTextSoltera(titulo)}</h2>
      <div className="banner-bm-contenido mwx-1680 mx-auto relative">
        <div className="banner-bm-flotante">
          <h3 className="size-81 font-roboto weight-300 mt-0 mb-0">
            {changeTextSoltera(titulo)}
          </h3>
          <div className="banner-bm-segun">
            <button
              className={`titulo-segun ${momento} size-21 text-right mt-0 color-negro pointer`}
              onClick={e => {
                e.preventDefault()
                navigate("/#momento")
              }}
            >
              Según tu momento de vida
            </button>
            <button
              className={`titulo-segun ${barrio} size-21 text-right mt-0 color-negro pointer`}
              onClick={e => {
                e.preventDefault()
                navigate("/#barrio")
              }}
            >
              Según tu barrio favorito
            </button>
          </div>
        </div>
        <svg
          className="banner-bm-svg w-100 svg-animacion mov-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1680 366"
        >
          <path stroke="#2e2e2e" d="M 0,366 L 0,168 L 1680,361 L 1680,0" />
          <path stroke="#2e2e2e" d="M 3,366 L 3,168 L 1677,361 L 1677,0" />
        </svg>
        <svg
          className="banner-bm-svg w-100 svg-animacion mov-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 358 130"
        >
          <path stroke="#2e2e2e" d="M 0,130 L 0,80 L 358,127 L 358,15" />
          <path stroke="#2e2e2e" d="M 3,130 L 3,80 L 355,127 L 355,15" />
        </svg>
      </div>
    </section>
  )
}

export default BannerBarrio
