import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./RoadeateProyecto.scss"
import GenerarAlt from "../../utils/GenerarAlt"
import BotonProyecto from "./BotonProyecto"

const RodeateProyecto = ({
  id,
  brochure,
  titulo,
  parrafo,
  detalle,
  botones,
  imagenPremio,
  parrafoPremio,
  aColumnas,
  nColumnas,
  galeriaObra,
  settings,
  parrafoAvance,
}) => {
  const counterVisibleButtons = botones.filter(ele => ele.ocultar !== true)

  return (
    <section className="rodeate-seccion">
      <h3 className="none">{titulo}</h3>
      {counterVisibleButtons?.length > 0 && (
        <div className="rodeate-botones mwx-1680 mx-auto">
          {botones &&
            botones.map((boton, i) => (
              <BotonProyecto
                key={i}
                boton={boton}
                linkPdf={boton?.uri}
                galeriaObra={galeriaObra}
                settings={settings}
                brochure={brochure}
              />
            ))}
        </div>
      )}
      <div className="rodeate-contenido mwx-1680 mx-auto">
        {titulo && (
          <div className="rodeate-contenido-titulo font-roboto weight-300">
            {titulo}
          </div>
        )}
        <div className="rodeate-contenido-detalle">
          {parrafo && (
            <div
              className="rodeate-contenido-parrafo size-21 font-lato weight-300 parrafo-0"
              dangerouslySetInnerHTML={{ __html: parrafo }}
            />
          )}
          <div>
            <div className={`rodeate-info rodeate-info-cols-${nColumnas}`}>
              {detalle &&
                detalle.map((info, i) => (
                  <div key={i} className={`rodeate-info-grid relative p-0`}>
                    <div className="rodeate-info-item">
                      {info?.imagen && (
                        <GatsbyImage
                          className="rodeate-info-imagen"
                          image={
                            info.imagen.sourceUrlSharp.childImageSharp
                              .gatsbyImageData
                          }
                          alt={GenerarAlt(info.imagen)}
                        />
                      )}
                      {info?.texto && (
                        <div
                          className="rodeate-info-parrafo size-21 font-lato weight-300 parrafo-0 mt-0 mb-0"
                          dangerouslySetInnerHTML={{ __html: info.texto }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {imagenPremio && parrafoPremio && (
        <div className="rodeate-premio mwx-1680 mx-auto">
          <GatsbyImage
            className="rodeate-premio-imagen"
            image={imagenPremio.sourceUrlSharp.childImageSharp.gatsbyImageData}
            alt={GenerarAlt(imagenPremio)}
          />
          <div
            className="rodeate-premio-parrafo size-21 font-lato weight-300 parrafo-0 mt-0 mb-0"
            dangerouslySetInnerHTML={{ __html: parrafoPremio }}
          />
        </div>
      )}

      {/* {
        iframe &&
        <div className="mwx-1680 mx-auto ">
          <div className="multimedia-seccion">
            {
              tituloIframe &&
              <h3 className="weight-500 text-center size-45 font-roboto">{tituloIframe}</h3>
            }

            <iframe src={iframe} frameBorder="0" className="multimedia-iframe mx-auto block" title="Multimedia" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen/>
          </div>
        </div>
      } */}
    </section>
  )
}

export default RodeateProyecto
