import { Link } from "gatsby"
import React from "react"
import "./Button.scss"

const Button = ({
  url = "/",
  size = "normal",
  type = "button",
  children,
  eventEle,
  classEle,
  refEle,
  ...others
}) => {
  return (
    <>
      {(type === "button" || type === "submit") && (
        <button
          type={type}
          className={`boton boton-size-${size} ${classEle}`}
          {...others}
          onClick={eventEle}
          ref={refEle}
          aria-hidden="true"
        >
          {children}
        </button>
      )}
      {type === "pdf" && (
        <a
          className={`boton boton-size-${size} ${classEle}`}
          {...others}
          onClick={eventEle}
          ref={refEle}
          aria-hidden="true"
        >
          {children}
        </a>
      )}
      {type === "link" && (
        <Link
          to={url}
          className={`boton boton-size-${size} ${classEle}`}
          {...others}
          onClick={eventEle}
          ref={refEle}
          tabindex="-1"
        >
          {children}
        </Link>
      )}
    </>
  )
}

export default Button
