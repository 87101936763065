import React from "react"
import { Fragment } from "react"
import BannerItem from "./BannerItem"
import Slider from "react-slick"
import "./BannerSlider.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import BannerItemStyle3 from "./BannerItemStyle3"

const BannerSlider = props => {
  const configuracion = props.configuracion
  const proyectos = props.proyectos
  const activeSlide = props.activeSlide
  const totalSlide = props.totalSlide
  const linkBanner = props.linkBanner
  return (
    <>
      {configuracion && proyectos && (
        <Slider
          {...configuracion}
          className="banner-slider banner-slider-inicio"
        >
          {Object.values(proyectos).map((proyecto, i) => {
            return (
              <Fragment key={i}>
                {proyecto.estilo === "estilo3" ? (
                  <BannerItemStyle3 proyecto={proyecto} />
                ) : (
                  <BannerItem
                    proyecto={proyecto}
                    activeSlide={activeSlide}
                    totalSlide={totalSlide}
                    linkBanner={linkBanner}
                  />
                )}
              </Fragment>
            )
          })}
        </Slider>
      )}
    </>
  )
}

export default BannerSlider
