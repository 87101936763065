import React from "react";
import SliderProyecto from "../Slider/SliderProyectos";
import "./GridProyectos.scss";

const GridProyectos = ({data}) => {
    
    const proyectos = data?.proyectos;

    return(
        <section className="grid-proyectos">
            {
                proyectos && proyectos.map((item, i)=>{
                    
                    const distrito = item?.distrito;
                    const proyectos = item?.proyectos;
                    const index = i+0;
                    const isPar = index % 2 ===0;
                    return(
                        <div key={i} className={`grid-proyectos__item ${isPar ? 'bg-negro color-orange-light slider-impar': 'bg-orange-light color-negro slider-par'}`}>
                            <div className="mx-auto mwx-1744">
                                <div className="grid-proyectos__distrito weight-300 font-roboto">{distrito}</div>
                                <SliderProyecto data={proyectos}/>
                            </div>
                        </div>
                    )
                })
            }
        </section>
    )
}

export default GridProyectos;