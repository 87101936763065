import React from 'react'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"

const CaptchaForm = ({event,eventCaptcha, children}) => {
    return (
        <>
            {
                (process.env.NODE_ENV === 'development') ?
                    <form onSubmit={event} encType="multipart/form-data" noValidate>
                        {children}
                    </form>
                :
                <GoogleReCaptchaProvider reCaptchaKey="6LfAtsYlAAAAAGXEg-SRGy5dNqoGCO39Lgq9-KlN">
                    <form onSubmit={event} encType="multipart/form-data" noValidate>
                        <GoogleReCaptcha
                            onVerify={eventCaptcha}
                        />
                        {children}
                    </form>
                </GoogleReCaptchaProvider>
            }
            
        </>
    )
}

export default CaptchaForm