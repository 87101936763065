import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./BannerPagina.scss"

const BannerPagina = ({ titulo, fondoColor, imagen }) => {
  return (
    <section className="banner-pagina" style={{ backgroundColor: fondoColor }}>
      <h2 className="d-none" dangerouslySetInnerHTML={{ __html: titulo }} />
      <div className="banner-pagina-contenido mwx-1680 mx-auto">
        <div className="banner-pagina-item">
          <div
            className="banner-pagina-titulo size-81 font-roboto weight-300"
            dangerouslySetInnerHTML={{ __html: titulo }}
          />
        </div>
        <GatsbyImage
          tab="img"
          className="banner-pagina-item banner-pagina-imagen"
          image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt="holamundo"
        />
        <svg
          className="banner-pagina-svg svg-animacion mov-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1680 366"
        >
          <path stroke="#2e2e2e" d="M 0,366 L 0,168 L 1680,361 L 1680,0" />
          <path stroke="#2e2e2e" d="M 3,366 L 3,168 L 1677,361 L 1677,0" />
        </svg>
        <svg
          className="banner-pagina-svg svg-animacion mov-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 750 566"
        >
          <path stroke="#2e2e2e" d="M 0,566 L 0,168 L 677,561 L 677,0"></path>
          <path stroke="#2e2e2e" d="M 3,566 L 3,168 L 677,561 L 677,0"></path>
        </svg>
      </div>
    </section>
  )
}

export default BannerPagina
