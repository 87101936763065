import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'

export const query = graphql`

  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        content
        
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
        }
        
      }
    }
  }

`

export default function PorDefecto ({data}) {

  const page = data.pionero.page;

  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina','#E8D5CC');
  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');
  
  return (
    <Layout>
      <Seo post={page}/>
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{__html: page.content}} />
    </Layout>
  )

}