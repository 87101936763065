import React from "react"
import {CreateLocalLink, CrearItemsHijos} from "../../utils/Utils"
import UniversalLink from "./UniversalLink"
import Icon from "../Icon/Icon"

const MenuItem = ({ menuItem, wordPressUrl }) => {
  const menuItemsChildrens = menuItem.childItems
  return (
    <li id={`menu-item-${menuItem.id}`}>
      <UniversalLink
        menuItem={menuItem}
        to={CreateLocalLink(menuItem, wordPressUrl)}
        className={ `${menuItem?.connectedNode?.node?.estados?.nodes[0]?.slug && "link-estado"}  header-item-${menuItem.label.toLowerCase()} ${menuItem?.cssClasses}`}
      >
        <span className="header-menu-texto no-events" dangerouslySetInnerHTML={{__html: menuItem.label}}/>
        {
          menuItem?.connectedNode?.node?.estados?.nodes[0]?.slug &&
            <span className="header-svg-estado no-events">
              <Icon icon={`menu-${menuItem?.connectedNode?.node?.estados?.nodes[0]?.slug}`} colorText="red"/>
            </span>
        }
      </UniversalLink>

      {CrearItemsHijos(menuItemsChildrens, wordPressUrl)}
    </li>
  )
}

export default MenuItem