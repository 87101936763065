import React from "react"
import BotonIframeProyecto from "./BotonIframeProyecto"
import BotonGaleriaProyecto from "./BotonGaleriaProyecto"
import Button from "../atoms/Button/Button"
import ObtenerUri from "../../utils/ObtenerUri"

const BotonProyecto = ({
  boton,
  brochure,
  settings,
  galeriaObra,
  linkPdf,
  parrafoAvance,
}) => {
  const { texto, idGa, ocultar, tipo } = boton

  return (
    <>
      {ocultar !== true && (
        <>
          {tipo === "pdf" && (
            <Button
              type={tipo}
              classEle="uppercase bg-negro color-pagina size-25 font-lato weight-300 boton-hover"
              href={ObtenerUri(boton?.linkPdf?.uri)}
              target="_blank"
              rel="noreferrer"
              id={idGa}
            >
              {texto}
            </Button>
          )}
          {tipo === "galeria" && (
            <BotonGaleriaProyecto
              boton={boton}
              galeriaObra={galeriaObra}
              settings={settings}
              parrafoAvance={parrafoAvance}
            />
          )}
          {tipo === "iframe" && <BotonIframeProyecto boton={boton} />}
        </>
      )}
    </>
  )
}
export default BotonProyecto
