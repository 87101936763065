import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerInicio from "../../components/Inicio/BannerInicio"
import DepasInicio from "../../components/Inicio/DepasInicio"
import ModalInicio from "../../components/Inicio/ModalInicio"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        uri

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        inicio {
          activoModal
          segunTuMomentoIdTag
          segunTuBarrioIdTag
          imagenModal {
            title
            altText
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          textoDeAcuerdoDepas
          tituloDepasEncantadores
          tituloSegunTuBarrio
          tituloSegunTuMomento
          segunTuMomentoIdTag
          segunTuBarrioIdTag
        }
      }
    }
  }
`

export default function Inicio({ data }) {
  const page = data.pionero?.page
  const inicio = data.pionero?.page?.inicio
  const activoModal = inicio?.activoModal
  const imagenModal = inicio?.imagenModal
  const tituloDepas = inicio?.tituloDepasEncantadores
  const tituloTab1 = inicio?.tituloSegunTuMomento
  const tituloTab2 = inicio?.tituloSegunTuBarrio
  const textDepas = inicio?.textoDeAcuerdoDepas

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-pagina", "#E8D5CC")
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      {activoModal && <ModalInicio imagen={imagenModal} />}
      <BannerInicio titlePage={page.title} />
      {tituloDepas && tituloTab1 && tituloTab2 && (
        <DepasInicio
          tituloDepas={tituloDepas}
          tituloTab1={tituloTab1}
          tituloTab2={tituloTab2}
          textDepas={textDepas}
          momentoId={inicio?.segunTuMomentoIdTag}
          barrioId={inicio?.segunTuBarrioIdTag}
        />
      )}
    </Layout>
  )
}
