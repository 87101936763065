import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import GenerarAlt from "../../../utils/GenerarAlt";

const Image = ({data, ...other}) => {

    if(!data) return <></>;

    return(
        <>
            <div>
                {
                    data &&
                    <GatsbyImage 
                        image={data?.sourceUrlSharp.childImageSharp.gatsbyImageData} 
                        alt={GenerarAlt(data)}
                        {...other}
                    />
                }
            </div>
        </>
    )
}

export default Image;