import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import MenuItem from '../Menu/MenuItem'
import { GatsbyImage } from "gatsby-plugin-image"

import './Footer.scss'
import GenerarAlt from "../../utils/GenerarAlt"

const FOOTER_QUERY = graphql`
{
  pionero {
    page(id: "675", idType: DATABASE_ID) {
      general {
        tituloOficinaCentral
        tituloInformacionDe
        telefonoOficinaCentral
        direccionOficinaCentral
        parrafoLasImagenesEn
        imagenFooter {
          ...imagenFooter
        }
      }
    }
    menuItems(where: {parentId: "", location: HCMS_MENU_FOOTER}) {
      ...menuItems
    }
    generalSettings {
      url
    }
  }
}

fragment menuItems on Pionero_RootQueryToMenuItemConnection {
  nodes {
    id
    label
    url
    title
    target
    cssClasses
    childItems(before: "") {
      edges {
        node {
          id
          label
          url
          title
          target
        }
      }
    }
  }
}

fragment imagenFooter on Pionero_MediaItem{
  altText
  title
  srcSet
  sourceUrl
  sourceUrlSharp {
    childImageSharp {
      gatsbyImageData(
        formats: [AUTO, WEBP, AVIF]
        placeholder: NONE
      )
    }
  }
}
`

const Footer = () => {

  return (
    <StaticQuery
      query={FOOTER_QUERY}
      render={(data) => {
        const menuItems = data.pionero?.menuItems?.nodes
        const wordPressUrl = data.pionero?.generalSettings?.url
        const footer = data.pionero.page?.general

        return(
          <>
            <footer className="footer bg-negro">
            <div className="footer-contenido mwx-1680 mx-auto d-flex">
              <div className="footer-item">
                {
                  (footer && footer.tituloInformacionDe) &&
                  <h3
                    className="footer-item-titulo weight-400">
                    {footer.tituloInformacionDe}
                  </h3>
                }

                {
                  menuItems &&
                  <ul>
                    {
                      menuItems.map((menuItem,i) => (
                        <MenuItem
                          key={i}
                          menuItem={menuItem}
                          wordPressUrl={wordPressUrl}
                        />
                      ))
                    }
                  </ul>
                }
              </div>
              <div className="footer-item">
                {
                  (footer && footer.tituloOficinaCentral) &&
                  <h3 className="footer-item-titulo weight-400">{footer.tituloOficinaCentral}</h3>
                }

                {
                  (footer && footer.telefonoOficinaCentral) &&
                  <a
                    href={`tel:051${footer.telefonoOficinaCentral}`}
                    className="footer-item-telefono color-blanco weight-400">
                      {footer.telefonoOficinaCentral}
                  </a>
                }

                {
                  (footer && footer.direccionOficinaCentral) &&
                  <div
                    className="footer-item-direccion color-blanco weight-400"
                    dangerouslySetInnerHTML={{ __html: footer.direccionOficinaCentral }}
                  />
                }
              </div>
              <div className="footer-item">
                {
                  (footer && footer.imagenFooter) &&
                  <a href="https://adiperu.pe/" target="_blank" rel="noopener noreferrer">
                    <GatsbyImage
                      className="footer-imagen weight-400"
                      image={footer.imagenFooter.sourceUrlSharp.childImageSharp.gatsbyImageData}
                      alt={GenerarAlt(footer.imagenFooter)} />
                  </a>
                }
              </div>
            </div>
            {
              (footer && footer.parrafoLasImagenesEn) &&
              <div
                className="footer-info color-blanco weight-400 mwx-1680 mx-auto"
                dangerouslySetInnerHTML={{ __html: footer.parrafoLasImagenesEn }}
              />
            }
          </footer>
          </>
        )
      }}
    />
  )

}

export default Footer
