import React, { useState, useRef } from "react"
import { navigate, Link } from "gatsby"
import Button from "../../atoms/Button/Button"
import "./FormCierra.scss"
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import CaptchaForm from "../Captcha/CaptchaForm"

var getUrlParameter = function getUrlParameter(sParam) {
  if (typeof window !== `undefined`) {
    var sPageURL = window.location.search.substring(1)
    var sURLVariables = sPageURL.split("&")
    var sParameterName
    var i
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1])
      }
    }
  }
}

const FormCierra = ({ idProyecto, slugGracias, color, pageName }) => {
  const [nombre, setNombre] = useState("")
  const [dni, setDni] = useState("")
  const [telefono, setTelefono] = useState("")
  const [email, setEmail] = useState("")
  const [envio, setEnvio] = useState("")
  const [aceptar, setAceptar] = useState("")
  // const [aceptar2, setAceptar2] = useState('')

  const [validNombres, setValidNombres] = useState("")
  const [validDni, setValidDni] = useState("")
  const [validTelefono, setValidTelefono] = useState("")
  const [validEmail, setValidEmail] = useState("")
  const [validAceptar, setValidAceptar] = useState("")
  // const [validAceptar2, setValidAceptar2] = useState('')

  const [token, setToken] = useState()

  // let hpionero = useRef(null);
  // let hpionero2 = useRef(null);
  const btnSubmitRef = useRef(null)

  let utm_source = ""
  let utm_medium = ""
  let utm_campaign = ""
  let utm_term = ""
  let utm_content = ""

  utm_campaign = getUrlParameter("utm_campaign")
  utm_content = getUrlParameter("utm_content")
  utm_medium = getUrlParameter("utm_medium")
  utm_source = getUrlParameter("utm_source")
  utm_term = getUrlParameter("utm_term")

  const handleSubmit = async event => {
    event.preventDefault()

    let errores = 0

    if (nombre === "") {
      setValidNombres(true)
      errores++
    } else setValidNombres(false)
    if (dni === "") {
      setValidDni(true)
      errores++
    } else setValidDni(false)
    if (telefono === "") {
      setValidTelefono(true)
      errores++
    } else setValidTelefono(false)
    if (
      email === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      setValidEmail(true)
      errores++
    } else setValidEmail(false)
    if (!token) errores++
    if (aceptar === "" || aceptar === false) {
      setValidAceptar(true)
      errores++
    } else setValidAceptar(false)
    // if(aceptar2 === "" || aceptar2 === false ) {setValidAceptar2(true); errores++;}
    // else setValidAceptar2(false);
    // if(hpionero.current.value !== '') errores++;console.log(11);
    // if(hpionero2.current.value !== 'apros') errores++;console.log(12);
    // if(hpionero.current.value !== '' || hpionero2.current.value !== 'apros')
    setEnvio(true)
    btnSubmitRef.current.disabled = true

    // if(errores === 0){
    //   fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/3841/feedback`, {
    //     method: 'POST',
    //     body:
    //     'Nombres=' + nombre+
    //     '&Dni=' + dni+
    //     '&Correo=' + email +
    //     '&Celular=' + telefono +
    //     '&utm_content=' + utm_content +
    //     '&utm_term=' + utm_term +
    //     '&utm_campaign=' + utm_campaign +
    //     '&utm_medium=' + utm_medium +
    //     '&utm_source=' + utm_source,
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     }
    //   })
    //   .then(res => res.json())
    //   .then(json => {
    //     if(json.status === "mail_sent"){
    //       event.target.reset()
    //       setNombre('')
    //       setDni('')
    //       setTelefono('')
    //       setEmail('')
    //       setEnvio(true)
    //       btnSubmitRef.current.disabled = false
    //     }
    //     else{
    //       setEnvio(false)
    //       btnSubmitRef.current.disabled = false
    //     }
    //   })
    // }else{
    //   btnSubmitRef.current.disabled = false
    // }

    if (errores === 0) {
      if (utm_campaign === undefined) utm_campaign = ""
      if (utm_content === undefined) utm_content = ""
      if (utm_medium === undefined) utm_medium = ""
      if (utm_source === undefined) utm_source = ""
      if (utm_term === undefined) utm_term = ""

      const dataPost = {
        IdTipoPortal: 12,
        // "IdTipoPortal": idProyecto == "1358" ? 6 : 12,
        IdProyecto: idProyecto,
        Nombres: nombre,
        Apellidos: dni,
        Correo: email,
        Celular: telefono,
        Comentario: `Proyecto: ${pageName}, DNI: ${dni} Tipo form: Cierra puertas`,
        IncluyeUtm: true,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_term: utm_term,
        // "numDormitorio": numDorm,
        usuario: process.env.GATSBY_PAGINA_USER_EVOLTA,
        clave: process.env.GATSBY_PAGINA_PASS_EVOLTA,
      }

      const rpta = await fetch(
        `${process.env.GATSBY_PAGINA_URL_EVOLTA}/lead/save`,
        {
          method: "POST",
          body: JSON.stringify(dataPost),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      const data = await rpta.json()
      if (data === "000") {
        event.target.reset()
        setNombre("")
        setDni("")
        setTelefono("")
        setEmail("")
        setEnvio(true)
        setToken()
        btnSubmitRef.current.disabled = false
        navigate(`/${slugGracias}`)
      } else {
        setEnvio(false)
        btnSubmitRef.current.disabled = false
      }
    } else {
      btnSubmitRef.current.disabled = false
    }
  }

  return (
    <div className="form-cierre">
      <h2 className="form-cierre-titulo font-roboto weight-300">
        Regístrate aquí y haz crecer tu patrimonio:
      </h2>
      <CaptchaForm
        event={handleSubmit}
        eventCaptcha={token => {
          setToken(token)
        }}
      >
        <div className="">
          <input type="hidden" name="_wpcf7" value="835" />
          <input type="hidden" name="utm_campaign" value={utm_campaign} />
          <input type="hidden" name="utm_content" value={utm_content} />
          <input type="hidden" name="utm_medium" value={utm_medium} />
          <input type="hidden" name="utm_source" value={utm_source} />
          <input type="hidden" name="utm_term" value={utm_term} />
        </div>
        <div className="form-control">
          <input
            type="text"
            value={nombre}
            onChange={e => setNombre(e.target.value)}
            name="nombre"
            placeholder="Nombre*"
          />
          {validNombres && (
            <span className="size-17 color-rojo">El campo es obligatorio.</span>
          )}
        </div>
        <div className="form-control">
          <input
            type="text"
            value={dni}
            onChange={e => setDni(e.target.value)}
            name="dni"
            placeholder="DNI*"
          />
          {validDni && (
            <span className="size-17 color-rojo">El campo es obligatorio.</span>
          )}
        </div>
        <div className="form-control">
          <input
            type="tel"
            value={telefono}
            onChange={e => setTelefono(e.target.value)}
            name="telefono"
            placeholder="Teléfono"
          />
          {validTelefono && (
            <span className="size-17 color-rojo">El campo es obligatorio.</span>
          )}
        </div>
        <div className="form-control">
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            placeholder="Email"
          />
          {validEmail && (
            <span className="size-17 color-rojo">El campo es obligatorio.</span>
          )}
        </div>
        <div className="form-cierre__info">
          *Todos los campos son obligatorios.
        </div>
        <div className="form-control">
          {/* <input type="text" name="hpionero" className="none" defaultValue="" ref={hpionero} />
                <input type="text" name="hpionero2" defaultValue="apros" style={{display:'none'}} ref={hpionero2} /> */}
          <label className="form-aceptar">
            <input type="checkbox" onChange={e => setAceptar(!aceptar)} />
            <span>
              He leído y acepto todos los{" "}
              <Link
                to="/terminos-y-condiciones/"
                className="color-blanco weight-bold"
                target="_blank"
                rel="noreferrer"
              >
                términos y condiciones
              </Link>
              , y la{" "}
              <Link
                to="/politica-privacidad/"
                className="color-blanco weight-bold"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad
              </Link>{" "}
              del Grupo Pionero Inmobiliaria
            </span>
          </label>
          {validAceptar && (
            <span className="size-17 color-rojo">El campo es obligatorio.</span>
          )}

          <label className="form-aceptar">
            {/* <input type="checkbox" onChange={e => setAceptar2(!aceptar2)} /> */}
            <input type="checkbox" />
            <span>
              Otorgo mi consentimiento para el envío de publicidad y/o anuncios
              comerciales y/o invitaciones a eventos así con el envío de
              encuentas sobre proyectos o servicios de la inmobiliaria
            </span>
          </label>
          {/* {
                  validAceptar2 && <span className="size-17 color-rojo">El campo es obligatorio.</span>
                } */}
        </div>
        <div className="text-right">
          <Button
            size="small"
            refEle={btnSubmitRef}
            type="submit"
            classEle="form-cierre__submit font-roboto "
            style={{ backgroundColor: `${color}` }}
          >
            ENVIAR{" "}
          </Button>
        </div>
        {envio === false && envio !== "" && (
          <div className="font-roboto line-heihgt-normal">
            Error en el envio
          </div>
        )}
        {envio === true && envio !== "" && (
          <div className="font-roboto line-heihgt-normal">Correo enviado</div>
        )}
      </CaptchaForm>
    </div>
  )
}

export default FormCierra
