import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link, StaticQuery } from "gatsby"
import changeTextSoltera from "../../help/changeTextSoltera"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import UpdateUri from "../../utils/UpdateUri"

const MOMENTOS_QUERY = graphql`
  {
    pionero {
      momentos(where: { orderby: NAME }) {
        edges {
          node {
            uri
            name
            momento_barrio {
              orden
              colorTexto
              parrafo
              filtroIdTag
              imagenDestacada {
                ...imagenDestacada
              }
            }
          }
        }
      }
    }
  }

  fragment imagenDestacada on Pionero_MediaItem {
    altText
    title
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
      }
    }
  }
`

const MomentosInicio = () => {
  return (
    <StaticQuery
      query={MOMENTOS_QUERY}
      render={data => {
        let momentos = data.pionero?.momentos?.edges

        const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
          <button
            {...props}
            className={
              "slick-prev slick-arrow" +
              (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
            tabIndex="-1"
          >
            <svg viewBox="0 0 44.34 84.45">
              <polyline
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
                strokeWidth="3px"
                points="43.28 1.06 2.12 42.22 43.28 83.39"
              />
            </svg>
          </button>
        )
        const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
          <button
            {...props}
            className={
              "slick-next slick-arrow" +
              (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
            tabIndex="-1"
          >
            <svg viewBox="0 0 44.34 84.45">
              <polyline
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
                strokeWidth="3px"
                points="1.06 1.06 42.22 42.22 1.06 83.39"
              />
            </svg>
          </button>
        )

        momentos.sort(function (a, b) {
          if (a.node.momento_barrio.orden > b.node.momento_barrio.orden)
            return 1
          if (a.node.momento_barrio.orden < b.node.momento_barrio.orden)
            return -1
          return 0
        })

        const settings = {
          dots: false,
          arrows: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 7000,
          pauseOnHover: false,
          pauseOnFocus: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
              },
            },
          ],
          prevArrow: <SlickArrowLeft />,
          nextArrow: <SlickArrowRight />,
        }

        return (
          <Slider {...settings} className="momentoInicio-slider">
            {momentos &&
              momentos.map(({ node: momento }, i) => (
                <div key={i}>
                  <Link
                    to={UpdateUri(momento.uri)}
                    className={` depa__link relative block zoom-animacion ${momento?.momento_barrio?.filtroIdTag}`}
                    tabIndex="-1"
                  >
                    <GatsbyImage
                      className="depa-item-imagen w-100"
                      image={
                        momento.momento_barrio.imagenDestacada.sourceUrlSharp
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        momento.momento_barrio.imagenDestacada.altText
                          ? momento.momento_barrio.imagenDestacada.altText
                          : momento.momento_barrio.imagenDestacada.title
                      }
                    />
                    <div className="depa-item-imagen-overlay overlay">
                      <h3 className="text-center titulo color-blanco size-49 weight-300 font-roboto mt-0">
                        {changeTextSoltera(momento.name)}
                      </h3>
                      <div
                        className="text-center parrafo color-blanco size-28 weight-300 font-lato parrafo-0"
                        dangerouslySetInnerHTML={{
                          __html: momento.momento_barrio.parrafo,
                        }}
                      />
                    </div>
                  </Link>
                </div>
              ))}
          </Slider>
        )
      }}
    />
  )
}

export default MomentosInicio
