import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerHomePet from "../../components/HomePet/BannerHomePet"
import NuestroHomePet from "../../components/HomePet/NuestroHomePet"
import ZonasHomePet from "../../components/HomePet/ZonasHomePet"
import ProyectosHomePet from "../../components/HomePet/ProyectosHomePet"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        home_pet_home {
          bannerTitulo
          bannerColorFondo
          bannerImagenFondo {
            title
            altText
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          tituloNuestraFilosofia
          parrafoNuestraFilosofia
          textoVideos
          videosNuestraFilosofia {
            link
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          tituloZonasHome
          parrafoZonasHome
          sliderZonasHome {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          infoZonasHome
          proyectos {
            ... on Pionero_Proyecto {
              id
              title
              barrios {
                edges {
                  node {
                    name
                  }
                }
              }
              proyecto {
                bannerColorFondo
                bannerTitulo
                imagenEnConstruccion {
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              slug
              uri
            }
          }
        }
      }
    }
  }
`

export default function Home_pet_home({ data }) {
  const page = data.pionero.page

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="43.28 1.06 2.12 42.22 43.28 83.39"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="1.06 1.06 42.22 42.22 1.06 83.39"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-pagina", "#E8D5CC")
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      <BannerHomePet
        titulo={page.title}
        fondoColor={page.home_pet_home.bannerColorFondo}
        imagen={page.home_pet_home.bannerImagenFondo}
      />
      <NuestroHomePet
        titulo={page.home_pet_home.tituloNuestraFilosofia}
        parrafo={page.home_pet_home.parrafoNuestraFilosofia}
        texto={page.home_pet_home.textoVideos}
        videos={page.home_pet_home.videosNuestraFilosofia}
      />
      <ZonasHomePet
        titulo={page.home_pet_home.tituloZonasHome}
        parrafo={page.home_pet_home.parrafoZonasHome}
        slider={page.home_pet_home.sliderZonasHome}
        info={page.home_pet_home.infoZonasHome}
        configuracion={settings}
      />
      <ProyectosHomePet
        tituloPage={page.title}
        proyectos={page.home_pet_home.proyectos}
      />
    </Layout>
  )
}

