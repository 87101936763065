import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerInversionistas from "../../components/Inversionistas/BannerInversionistas"
import RodeateInversionistas from "../../components/Inversionistas/RodeateInversionistas"
import ConoceInversionistas from "../../components/Inversionistas/ConoceInversionistas"
import ContactoInversionistas from "../../components/Inversionistas/ContactoInversionistas"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        inversionistas {
          celularIdTag
          whatsappIdTag
          emailIdTag
          bannerTitulo
          bannerColorFondo
          bannerImagenFondo {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          tituloRodeateDe
          parrafoRodeateDe
          iconosRodeateDe {
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            titulo
          }
          tituloConoceLos
          proyectosConoceLosProyectos {
            ... on Pionero_Proyecto {
              id
              title
              uri
              barrios {
                edges {
                  node {
                    name
                  }
                }
              }
              featuredImage {
                node {
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              proyecto {
                bannerTitulo
                imagenInversionistas {
                  altText
                  title
                  srcSet
                  sourceUrl
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                textoInversionistas
              }
            }
          }
          tituloContactaA
          linksContactaA {
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            texto
            tipo
            valor
          }
        }
      }
    }
  }
`

export default function Inversionistas({ data }) {
  const page = data.pionero.page

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty(
      "--color-pagina",
      page.inversionistas.bannerColorFondo
    )
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty(
      "--color-texto",
      page.inversionistas.bannerColorFondo
    )

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      <BannerInversionistas
        titulo={page.inversionistas.bannerTitulo}
        fondoColor={page.inversionistas.bannerColorFondo}
        imagen={page.inversionistas.bannerImagenFondo}
      />
      <RodeateInversionistas
        titulo={page.inversionistas.tituloRodeateDe}
        parrafo={page.inversionistas.parrafoRodeateDe}
        iconos={page.inversionistas.iconosRodeateDe}
      />
      <ConoceInversionistas
        titulo={page.inversionistas.tituloConoceLos}
        proyectos={page.inversionistas.proyectosConoceLosProyectos}
      />
      <ContactoInversionistas
        titulo={page.inversionistas.tituloContactaA}
        links={page.inversionistas.linksContactaA}
        celularIdTag={page.inversionistas.celularIdTag}
        whatsappIdTag={page.inversionistas.whatsappIdTag}
        emailIdTag={page.inversionistas.emailIdTag}
      />
    </Layout>
  )
}

