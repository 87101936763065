import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerBarrio from "../../components/BarrioMomento/BannerBarrioMomento"
import ProyectosMomento from "../../components/BarrioMomento/ProyectosMomento"

export const query = graphql`
  query($id: ID!) {
    pionero {
      momento(id: $id, idType: DATABASE_ID) {
        name

        seo {
          canonical
          focuskw
          breadcrumbs {
            text
            url
          }
          cornerstone
          fullHead
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            altText
            srcSet
            sourceUrl
          }
          schema {
            raw
          }
        }

        momento_barrio {
          bannerColorFondo
        }
        proyectos {
          edges {
            node {
              uri
              title
              barrios {
                edges {
                  node {
                    name
                  }
                }
              }
              proyecto {
                bannerTitulo
                imagenFamilia {
                  srcSet
                  sourceUrl
                  title
                  altText
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                imagenParejas {
                  srcSet
                  sourceUrl
                  title
                  altText
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                imagenRoommates {
                  srcSet
                  sourceUrl
                  title
                  altText
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
                imagenSoltero {
                  srcSet
                  sourceUrl
                  title
                  altText
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Momento({ data }) {
  const page = data.pionero.momento
  const proyectos = page.proyectos.edges

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-pagina", "#E8D5CC")
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")
  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page?.name}</h1>
      <BannerBarrio
        titulo={page.name}
        fondoColor={page.momento_barrio.bannerColorFondo}
        barrio=""
        momento="active"
      />
      <ProyectosMomento titulo={page.name} proyectos={proyectos} barrio="" />
    </Layout>
  )
}
