import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import "./Cierra_puertas.scss";
import FormCierra from "../../components/organisms/Form/FormCierra";
import Text from "../../components/atoms/Text/Text";

export const query = graphql`
    query($id: ID!) {
        pionero {
            page(id: $id, idType: DATABASE_ID) {
                title
                uri
                slug
                seo {
                    title
                    metaDesc
                    focuskw
                    metaKeywords
                    metaRobotsNoindex
                    metaRobotsNofollow
                    opengraphTitle
                    opengraphDescription
                    opengraphImage {
                        altText
                        sourceUrl
                        srcSet
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        altText
                        sourceUrl
                        srcSet
                    }
                    canonical
                    cornerstone
                    schema {
                        articleType
                        pageType
                        raw
                    }
                }
                cierra_puertas {
                    colorFondoDerecha
                    idProyectoEvoltaIzquierda
                    estilo
                    imagenFondoDerecha {
                        srcSet
                        sourceUrl
                        title
                        altText
                        sourceUrlSharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: [AUTO, WEBP, AVIF]
                                    placeholder: NONE
                                )
                            }
                        }
                    }
                    paginaDeGraciasIzquierda {
                        ... on Pionero_Page {
                          id
                          slug
                        }
                    }
                    listadoIzquierda
                    textoDistritoDerecha
                    textoEstadoDerecha
                    textoFechaDerecha
                    textoHoraDerecha
                    textoProyectoDerecha
                    textoUbicacionDerecha
                    textoUltimosDepasDerecha
                    tituloIzquierda
                }
            }
        }
    }
`

export default function Cierra_puertas ({data}) {

    const page = data.pionero.page;
    const template = page?.cierra_puertas;

    (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina','#E8D5CC');
    (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');
    if(typeof window !== `undefined` && window?.fbq){
        window.fbq('init', '782152056862947');
        window.fbq('track', 'PageView');
    }

    const bgImage = template?.imagenFondoDerecha?.sourceUrlSharp?.childImageSharp?.gatsbyImageData?.images?.sources[1].srcSet;
    // bgImageSrc = bgImageSrc.slic
    const bgImageSrc = bgImage.split(",")[2].split(" ")[0];

    return (
        <Layout>
            <Seo post={page}/>
            <h1 className="none">{page.title}</h1>
            <div className="banner-cierra font-adria-ligth">
                {
                    (template.estilo === "estilo2") ?
                    <div className="banner-cierra__item-left2 banner-cierra__item color-negro" style={{backgroundColor: template.colorFondoDerecha, backgroundImage: `url(${bgImageSrc}), url(/icon/ene.png)`}}>
                        <div className="banner-cierra-info-style2 w-100">
                            <Text className="font-adria-ligth banner-cierra__title-style-2" value={template?.textoUltimosDepasDerecha} />
                            <div className="banner-cierra__description-style2 banner-cierra__text-style2">
                                <div>
                                    <img className="banner-cierra__icon" src="/icon/calendar2.png" alt="Calendario" /> {template?.textoFechaDerecha} 
                                </div>
                                <span className="icon-separate">|</span>
                                <div>
                                    <img src="/icon/clock2.png" className="banner-cierra__icon" alt="Calendario"/> {template?.textoHoraDerecha}
                                </div>
                                <span className="icon-separate">|</span>
                                <div>
                                    <img src="/icon/location2.png" className="banner-cierra__icon" alt="Calendario" /> {template?.textoUbicacionDerecha}
                                </div>
                            </div>
                            <div className="text-left">
                                <div className="banner-cierra__district-style2">{template?.textoDistritoDerecha}</div>
                                <Text className="banner-cierra__proyect-style2" value={template?.textoProyectoDerecha} />
                                <div className="banner-cierra__status2-style2">{template?.textoEstadoDerecha}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="banner-cierra__item-left banner-cierra__item color-negro" style={{backgroundColor: template.colorFondoDerecha, backgroundImage: `url(${bgImageSrc}), url(/icon/lines.svg)`}}>
                        <div className="banner-cierra-info">
                            <Text className="banner-cierra__text1 font-adria-medium" value={template?.textoUltimosDepasDerecha} />
                            <div className="banner-cierra__line"></div>
                            <div className="banner-cierra__text2">
                                <img className="banner-cierra__icon" src="/icon/calendar2.png" alt="Calendario" /> {template?.textoFechaDerecha} <br /><img src="/icon/clock2.png" className="banner-cierra__icon" alt="Calendario"/> {template?.textoHoraDerecha}</div>
                            <div className="banner-cierra__text3">
                                <img src="/icon/location2.png" className="banner-cierra__icon" alt="Calendario" /> {template?.textoUbicacionDerecha}</div>
                            <div>
                                <div className="banner-cierra__text4">{template?.textoDistritoDerecha}</div>
                                <Text className="banner-cierra__text5" value={template?.textoProyectoDerecha} />
                                {/* <div className="banner-cierra__text5">Rivera </div>
                                <div className="banner-cierra__text5">Navarrete 548</div> */}
                                <div className="banner-cierra__text6">{template?.textoEstadoDerecha}</div>
                            </div>
                        </div>
                    </div>
                }
                
                <div className="banner-cierra__item-right banner-cierra__item bg-negro color-blanco">
                    {
                        (template.estilo === "estilo2") ?
                        <div className="banner-cierra__description-title-style2 font-adria-medium">{template?.tituloIzquierda}</div>
                        :
                        <div className="banner-cierra__text7 font-adria-medium">{template?.tituloIzquierda}</div>            
                    }
                    <div>
                        <Text className="banner-cierra__text8" value={template?.listadoIzquierda} />
                    </div>
                    <FormCierra idProyecto={template?.idProyectoEvoltaIzquierda} slugGracias={template?.paginaDeGraciasIzquierda?.slug} color={template.colorFondoDerecha} pageName={page.title}/>
                </div>
            </div>
        </Layout>
    )
}