import React from "react"
import "./DirectorioNosotros.scss"
import { Grid } from "unsemantic/assets/react/dist/index"
import { GatsbyImage } from "gatsby-plugin-image"
import GenerarAlt from "../../utils/GenerarAlt"

const DirectorioNosotros = ({ titulo, directorio, isGerencia, index }) => {
  const handleTab = e => {
    const hasClass = e.target.classList.contains("active")
    let tabsButton = document.querySelectorAll(
      `.tabs-button-Directorio${index}`
    )
    tabsButton.forEach(
      ele => ele.classList.contains("active") && ele.classList.remove("active")
    )
    let tabsPanel = document.querySelectorAll(`.tabs-panel-Directorio${index}`)
    tabsPanel.forEach(
      ele => ele.classList.contains("active") && ele.classList.remove("active")
    )
    let tabsPanel2 = document.querySelectorAll(
      `.tabs-panel2-Directorio${index}`
    )
    tabsPanel2.forEach(
      ele => ele.classList.contains("active") && ele.classList.remove("active")
    )
    let cards = document.querySelectorAll(
      `.directorionosotros-item-Directorio${index}`
    )
    cards.forEach(
      ele => ele.classList.contains("active") && ele.classList.remove("active")
    )
    if (!hasClass) {
      e.target.classList.add("active")
      e.target.parentElement.classList.add("active")
      e.target.nextSibling.classList.add("active")
      document.getElementById(e.target.dataset.id).classList.add("active")
    }
  }

  return (
    <section
      className={`directorionosotros-seccion directorionosotros-seccion-${titulo}${index} bg-negro`}
    >
      <div className="directorionosotros-contenido mwx-1680 mx-auto grid-p-0">
        <h3
          className="directorionosotros-titulo mx-auto mwx-1680 color-pagina font-roboto size-60 weight-300"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
        <Grid
          desktop="100"
          tablet="100"
          mobile="100"
          className="directorionosotros-grilla"
        >
          {directorio &&
            isGerencia &&
            directorio.map((persona, i) => (
              <div
                key={i}
                className={`directorionosotros-item directorionosotros-item-Directorio${index}`}
              >
                <GatsbyImage
                  className="directorionosotros-imagen"
                  image={
                    persona.imagen.sourceUrlSharp.childImageSharp
                      .gatsbyImageData
                  }
                  alt={GenerarAlt(persona.imagen)}
                />
                <h3
                  className="directorionosotros-nombre color-pagina size-25 font-roboto weight-300"
                  dangerouslySetInnerHTML={{ __html: persona.nombre }}
                />
                <h3
                  className="directorionosotros-cargo color-pagina size-15 font-lato weight-400"
                  dangerouslySetInnerHTML={{ __html: persona.cargo }}
                />
                <button
                  name="pluss"
                  type="button"
                  data-id={`tabs-panel-${i}-Directorio${index}`}
                  onClick={handleTab}
                  className={`directorionosotros-boton tabs-button-Directorio${index}`}
                >
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 34.38 34.38"
                    className="directorionosotros-svg-mas"
                  >
                    <g id="Capa_2" data-name="Capa 2">
                      <g id="Capa_1-2" data-name="Capa 1">
                        <line
                          className="cls-1"
                          fill="none"
                          stroke="var(--color-pagina)"
                          strokeMiterlimit="10"
                          strokeWidth="1.14px"
                          x1="17.19"
                          x2="17.19"
                          y2="34.38"
                        />
                        <line
                          className="cls-1"
                          fill="none"
                          stroke="var(--color-pagina)"
                          strokeMiterlimit="10"
                          strokeWidth="1.14px"
                          x1="34.38"
                          y1="17.19"
                          y2="17.19"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <div
                  className={`tabs-panel2-Directorio${index} mov-block`}
                  id={`tabs-panel2-${i}-Directorio${index}`}
                >
                  <div
                    className="directorionosotros-detalle color-pagina font-lato size-17 weight-400 parrafo-0"
                    dangerouslySetInnerHTML={{ __html: persona.detalle }}
                  />
                </div>
              </div>
            ))}
          {directorio &&
            isGerencia !== true &&
            directorio.map((persona, i) => (
              <Grid
                key={i}
                desktop="20"
                tablet="20"
                mobile="50"
                className={`directorionosotros-item-${titulo}${index}`}
              >
                <GatsbyImage
                  className="directorionosotros-imagen"
                  image={
                    persona.imagen.sourceUrlSharp.childImageSharp
                      .gatsbyImageData
                  }
                  alt={GenerarAlt(persona.imagen)}
                />
                <h3
                  className="directorionosotros-nombre color-pagina size-25 font-roboto weight-300"
                  dangerouslySetInnerHTML={{ __html: persona.nombre }}
                />
                <h3
                  className="directorionosotros-cargo color-pagina size-15 font-lato weight-400"
                  dangerouslySetInnerHTML={{ __html: persona.cargo }}
                />
                <button
                  name="pluss"
                  type="button"
                  data-id={`tabs-panel-${i}-${titulo}${index}`}
                  onClick={handleTab}
                  className={`directorionosotros-boton tabs-button-${titulo}${index}`}
                >
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 34.38 34.38"
                    className="directorionosotros-svg-mas"
                  >
                    <g id="Capa_2" data-name="Capa 2">
                      <g id="Capa_1-2" data-name="Capa 1">
                        <line
                          className="cls-1"
                          fill="none"
                          stroke="var(--color-pagina)"
                          strokeMiterlimit="10"
                          strokeWidth="1.14px"
                          x1="17.19"
                          x2="17.19"
                          y2="34.38"
                        />
                        <line
                          className="cls-1"
                          fill="none"
                          stroke="var(--color-pagina)"
                          strokeMiterlimit="10"
                          strokeWidth="1.14px"
                          x1="34.38"
                          y1="17.19"
                          y2="17.19"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <div
                  className={`tabs-panel2-${titulo}${index} mov-block`}
                  id={`tabs-panel2-${i}-${titulo}${index}`}
                >
                  <div
                    className="directorionosotros-detalle color-pagina font-lato size-17 weight-400 parrafo-0"
                    dangerouslySetInnerHTML={{ __html: persona.detalle }}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
        <div className="mov-none">
          {directorio &&
            directorio.map((persona, i) => (
              <div
                key={i}
                className={`tabs-panel-Directorio${index}`}
                id={`tabs-panel-${i}-Directorio${index}`}
              >
                <div
                  className="directorionosotros-detalle color-pagina font-lato size-17 weight-400 parrafo-0"
                  dangerouslySetInnerHTML={{ __html: persona.detalle }}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default DirectorioNosotros
