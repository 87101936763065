import React, { useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import "../../scss/FancyboxModal.scss"

function Fancybox({delegate,options,children}) {
  const delegate2 = delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = options || {};

    NativeFancybox.bind(delegate2, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate2, options]);

  return <>{children}</>;
}

export default Fancybox;