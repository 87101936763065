import React from "react"
import PropTypes from "prop-types"
import "./LayoutBlank.scss"
import "./Layout.scss"

export default function LayoutBlank({ children, classEle }) {
  return <main className={`main-pdf ${classEle}`}>{children}</main>
}

LayoutBlank.propTypes = {
  children: PropTypes.node.isRequired,
}

