import React, { useState } from 'react'
import 'react-tabs/style/react-tabs.scss';
import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import Fancybox from "../Fancybox/Fancybox"
import FormCotizar from '../Formularios/FormCotizar';

const DormitorioProyecto = ({urlWsp,dorm, nroDorm, configuracion, textoBoton, nomProyecto, urlGracias, correoCotiza, idProyecto}) => {

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <div>
      <Slider
        className="cotizar-slider-btns"
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={1}
        swipeToSlide={false}
        focusOnSelect={true}
        infinite={false}
      >
        {
          dorm.map((d,i)=>(
            <button className="pointer" key={i} data-val={i}>{d.modelo}</button>
          ))
        }
      </Slider>
      <Slider {...configuracion} className="cotizar-slider" asNavFor={nav2} ref={(slider1) => setNav1(slider1)} lazyLoad={true} draggable={false} touchMove={false} swipeToSlide={false}>
        {
          dorm.map((d,i)=>(
            <div key={i}>
              <div className="slider-cotizar-flex">
                <div className="slider-cotizar__left">
                  {
                    d.urlImagen ?
                      <>
                        <Fancybox>
                          <img
                            className="slider-cotizar-imagen w-100 pointer"
                            src={d.urlImagen}
                            data-fancybox="Galeria Cotizador"
                            data-caption={d.title}
                            data-src={d.urlImagen}
                            alt={d.title}
                          />
                        </Fancybox>
                      </>
                    :
                      <StaticImage
                        src="../../images/sin-imagen.png"
                        alt="Sin Imagen"
                        placeholder="NONE"
                        layout="fixed"
                        className="slider-cotizar-imagen w-100"
                      />
                  }
                </div>
                <div className="slider-cotizar__right">
                  <FormCotizar
                    tipoDepa={d.modelo}
                    nomProyecto={nomProyecto}
                    urlGracias={urlGracias}
                    codInmueble={d.codInmueble}
                    correoCotiza={correoCotiza}
                    idProyecto={idProyecto}
                    numDorm={d.nroDormitorio}
                    urlWsp={urlWsp}
                  />
                </div>
              </div>
            </div>
          ))
        }
      </Slider>
    </div>
  )
}

export default DormitorioProyecto
