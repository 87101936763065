const ObtenerTagIdPorTipo = (data, tags) =>{

  let tag = "";
    if(data === "whatsapp")
      tag = tags.whatsappIdTag;
    if(data === "email")
      tag = tags.emailIdTag;
    if(data === "telefono")
      tag = tags.celularIdTag;

    return tag;
}
  
export default ObtenerTagIdPorTipo;