import React,{Fragment} from "react";
import { Link } from "gatsby";
import "./GrupoTexto.scss";
import ObtenerUri from "../../utils/ObtenerUri";

const GrupoTextos = ({grupoTextos}) => {

    return(
        <section className="seccion-contenido">
            <div className="contenido mwx-1271 mx-auto font-lato size-22">
                {
                    grupoTextos && grupoTextos.map((item,i)=>(
                        <Fragment key={i}>
                            <div className="grupoTexto__titulo">
                                <h3 className="mb-0 mt-0">{item.titulo}</h3>
                                {
                                    item?.proyecto?.uri &&
                                    <Link to={ObtenerUri(item?.proyecto?.uri)} className="btn-proyect">Ver proyecto
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 15V0L7.5 7.5L0 15Z" fill="white"/>
                                        </svg>
                                    </Link>
                                }
                            </div>
                            {
                                item.texto && 
                                <div className="grupoTexto__texto">
                                    <div dangerouslySetInnerHTML={{__html: item.texto}}/>
                                </div>
                            }
                        </Fragment>
                    ))
                }
            </div>
        </section>
    )
}

export default GrupoTextos;