import React from "react"
import "./ConoceInversionistas.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerUri from "../../utils/ObtenerUri"

const ConoceInversionistas = ({ titulo, proyectos }) => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="43.28 1.06 2.12 42.22 43.28 83.39"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="1.06 1.06 42.22 42.22 1.06 83.39"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }
  return (
    <section className="conoceinversionista-seccion bg-negro">
      <h3 className="none">Conoce los proyectos en los que podrás invertir</h3>
      <div className="conoceinversionista-contenido mx-auto mwx-1152 grid-p-0">
        <div
          className="conoceinversionista-titulo size-56 font-roboto weight-300 mt-0 mb-0 color-pagina"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
        <Slider {...settings} className="momentoInicio-slider">
          {proyectos &&
            proyectos.map((proyecto, i) => (
              <div key={i} className="depa-item conoceinversionistas-item">
                <Link
                  to={`${ObtenerUri(proyecto.uri)}`}
                  className="relative block zoom-animacion"
                >
                  {/*<GatsbyImage*/}
                  {/*  className="conoceinversionistas-item-imagen"*/}
                  {/*  image={proyecto.featuredImage.node.sourceUrlSharp.childImageSharp.gatsbyImageData} */}
                  {/*  alt={GenerarAlt(proyecto.featuredImage.node)}*/}
                  {/*/>*/}
                  <GatsbyImage
                    className="conoceinversionistas-item-imagen"
                    image={
                      proyecto.proyecto.imagenInversionistas.sourceUrlSharp
                        .childImageSharp.gatsbyImageData
                    }
                    alt={GenerarAlt(proyecto.featuredImage.node)}
                  />
                  <div className="proyecto-bm-imagen-overlay overlay">
                    <h3 className="titulo color-blanco size-23 weight-300 font-roboto mt-0 mb-0">
                      {proyecto.barrios.edges[0].node.name}
                    </h3>
                    <div
                      className="titulo color-blanco size-49-1 weight-300 font-lato mt-0 parrafo-0"
                      dangerouslySetInnerHTML={{
                        __html: proyecto.proyecto.bannerTitulo,
                      }}
                    />
                    {/*<div className="parrafo color-blanco size-49-1 weight-300 font-lato mt-0 parrafo-0" dangerouslySetInnerHTML={{__html: proyecto.proyecto.textoInversionistas}} />*/}
                    <div
                      className="parrafo-inversionistas parrafo color-blanco size-28 weight-300 font-lato parrafo-0"
                      dangerouslySetInnerHTML={{
                        __html: proyecto.proyecto.textoInversionistas,
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  )
}

export default ConoceInversionistas
