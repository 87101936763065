import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import BannerPagina from "../../components/Banner/BannerPagina"
import GrupoTextos from "../../components/Parrafo/GrupoTexto";

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
        }
        
        legal_promociones {
          bannerTitulo
          bannerColorFondo
          grupoTextos {
            proyecto {
              ... on Pionero_Proyecto {
                id
                title
                uri
              }
            }
            texto
            titulo
          }
          bannerImagenFondo {
            altText
            srcSet
            sourceUrl
            title
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF] 
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`

export default function Legal ({data}) {
  
  const page = data.pionero.page;
  
  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina',page.legal_promociones.bannerColorFondo);
  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');

  return (
    <Layout>
      <Seo post={page}/>
      <h1 className="none">{page.title}</h1>
      <BannerPagina 
        titulo={page.legal_promociones.bannerTitulo}
        fondoColor={page.legal_promociones.bannerColorFondo}
        imagen={page.legal_promociones.bannerImagenFondo}
      />
      <GrupoTextos grupoTextos={page.legal_promociones.grupoTextos} />
    </Layout>
  )
}