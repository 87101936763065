import React from "react"
import { graphql, Link } from "gatsby"
import LayoutBlank from "../../components/Layout/LayoutBlank"
import Seo from "gatsby-plugin-wpgraphql-seo"
import ObtenerUri from "../../../src/utils/ObtenerUri"

export const query = graphql`
  query($id: ID!) {
    pionero {
      pdf(id: $id, idType: DATABASE_ID) {
        title
        uri

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Pdf({ data }) {
  const page = data?.pionero?.pdf
  const pageUrlApros = data?.pionero?.pdf?.uri
  const uri = ObtenerUri(pageUrlApros)
  const domain = process.env.GATSBY_PAGINA_COMPILADOR
  return (
    <LayoutBlank classEle="page-pdf">
      <Seo post={page} />
      <h1 className="d-none">{page?.title}</h1>
      <h2 className="d-none">Iframe {page?.title}</h2>
      <Link className="d-none" tabindex="-1" to="/">
        Inicio
      </Link>
      {uri && (
        <iframe
          src={`${domain}${uri}`}
          frameborder="0"
          title={`PDF ${page?.title}`}
        ></iframe>
      )}
    </LayoutBlank>
  )
}
