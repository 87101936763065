import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import BannerBarrio from "../../components/BarrioMomento/BannerBarrioMomento"
import ProyectosBarrio from "../../components/BarrioMomento/ProyectosBarrio"

export const query = graphql`
  query($id: ID!) {
    pionero {
      barrio(id: $id, idType: DATABASE_ID) {
        name

        seo {
          canonical
          focuskw
          breadcrumbs {
            text
            url
          }
          cornerstone
          fullHead
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            altText
            srcSet
            sourceUrl
          }
          schema {
            raw
          }
        }

        momento_barrio {
          bannerColorFondo
        }
        proyectos {
          edges {
            node {
              title
              uri
              proyecto {
                bannerTitulo
                imagenBarrio {
                  srcSet
                  sourceUrl
                  title
                  altText
                  sourceUrlSharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Barrio({ data }) {
  const page = data.pionero.barrio
  const proyectos = page.proyectos.edges
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-pagina", "#E8D5CC")
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty("--color-texto", "#2D2D2D")
  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page?.name}</h1>
      <BannerBarrio
        titulo={page.name}
        fondoColor={page.momento_barrio.bannerColorFondo}
        barrio="active"
        momento=""
      />
      <ProyectosBarrio
        titulo={page.name}
        proyectos={proyectos}
        barrio={page.name}
      />
    </Layout>
  )
}

