import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./BannerHomePet.scss"
import GenerarAlt from "../../utils/GenerarAlt"

const BannerHomePet = ({ titulo, fondoColor, imagen }) => {
  return (
    <section
      className="banner-home banner-home-pet"
      style={{ backgroundColor: fondoColor }}
    >
      <h2 className="none">Banner {titulo}</h2>
      <div className="banner-home-contenido mwx-1680 mx-auto relative">
        <div className="banner-home-flotante">
          <StaticImage
            src="../../images/iconohome.png"
            alt="Porcentaje"
            placeholder="NONE"
            layout="fixed"
            className="banner-home-flotante-imagen"
          />
          {/* <div className="banner-home-titulo size-87 font-adria-ligth" dangerouslySetInnerHTML={{__html: titulo}} /> */}
        </div>
        <GatsbyImage
          className="banner-home-imagen"
          image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={GenerarAlt(imagen)}
        />
        <svg
          className="banner-home-svg svg-animacion mov-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1680 631"
        >
          <path stroke="#2e2e2e" d="M 0,631 L 0,300 L 1680,626 L 1680,0" />
          <path stroke="#2e2e2e" d="M 3,631 L 3,300 L 1677,626 L 1677,0" />
        </svg>
        <svg
          className="banner-home-svg svg-animacion mov-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 362 677"
        >
          <path stroke="#2e2e2e" d="M 0,677 L 0,304 L 362,674 L 362,0" />
          <path stroke="#2e2e2e" d="M 2,677 L 2,304 L 359,674 L 359,0" />
        </svg>
      </div>
    </section>
  )
}

export default BannerHomePet
