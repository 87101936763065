import * as React from "react"
import "./NoEncontrado.scss"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Error 404 - Inmobiliaria Pionero" />
      <h1 className="title-404">Error 404: página no encontrada</h1>
  </Layout>
)

export default NotFoundPage
