const ObtenerUri = data => {
  if (!data) return "/"
  const regex = /https/i
  const urlSite1 = process.env.GATSBY_PAGINA_COMPILADOR
  const urlSite2 = urlSite1.replace(regex, "http")
  return data.replace(urlSite1, "").replace(urlSite2, "")
}

export default ObtenerUri

