import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link, StaticQuery } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import UpdateUri from "../../utils/UpdateUri"

const BARRIOS_QUERY = graphql`
  {
    pionero {
      barrios(where: { orderby: TERM_ID }) {
        edges {
          node {
            uri
            name
            momento_barrio {
              imagenDestacada {
                ...imagenDestacada
              }
              parrafo
              filtroIdTag
            }
          }
        }
      }
    }
  }
  fragment imagenDestacada on Pionero_MediaItem {
    altText
    title
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
      }
    }
  }
`

const BarriosInicio = () => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      tabIndex="-1"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="43.28 1.06 2.12 42.22 43.28 83.39"
        />
      </svg>
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
      tabIndex="-1"
    >
      <svg viewBox="0 0 44.34 84.45">
        <polyline
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="3px"
          points="1.06 1.06 42.22 42.22 1.06 83.39"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: false,
        },
      },
    ],
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  return (
    <StaticQuery
      query={BARRIOS_QUERY}
      render={data => {
        const barrios = data.pionero?.barrios?.edges
        return (
          <div className="mwx-1438 mx-auto">
            <Slider {...settings} className="momentoInicio-slider">
              {barrios &&
                barrios.map(({ node: barrio }, i) => (
                  <div key={i} className="depa-item">
                    <Link
                      to={UpdateUri(barrio.uri)}
                      className={`depa__link relative block zoom-animacion ${barrio?.momento_barrio?.filtroIdTag}`}
                      tabIndex="-1"
                    >
                      <GatsbyImage
                        className="depa-item-imagen w-100"
                        image={
                          barrio.momento_barrio.imagenDestacada.sourceUrlSharp
                            .childImageSharp.gatsbyImageData
                        }
                        alt={
                          barrio.momento_barrio.imagenDestacada.altText
                            ? barrio.momento_barrio.imagenDestacada.altText
                            : barrio.momento_barrio.imagenDestacada.title
                        }
                      />
                      <div className="depa-item-imagen-overlay overlay">
                        <h3 className="text-center titulo color-blanco size-49 weight-300 font-roboto mt-0">
                          {barrio.name}
                        </h3>
                        <div
                          className="text-center parrafo color-blanco size-28 weight-300 font-lato parrafo-0"
                          dangerouslySetInnerHTML={{
                            __html: barrio.momento_barrio.parrafo,
                          }}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
          </div>
        )
      }}
    />
  )
}

export default BarriosInicio
