import React from 'react'
import Button from '../atoms/Button/Button';
import DormitorioProyecto from './DormitorioProyecto';

const PestaniaProyecto = ({urlWsp,nroDorm, starsCount,configuracion,textoBoton, nomProyecto, urlGracias, correoCotiza, idProyecto}) => {

  const handleTab = (e) => {
    let tabsButton = document.querySelectorAll(".tabs-button");
    tabsButton.forEach( (ele) => ele.classList.contains("active") && ele.classList.remove("active"))
    let tabsPanel = document.querySelectorAll(".tabs-panel")
    tabsPanel.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"))
    e.target.classList.add("active")
    document.getElementById(e.target.dataset.id).classList.add("active")
  }
    return(
      <div className="tabs">
        {
          (nroDorm && starsCount) &&
          <>
            <div className="tabs-lista botones-gap">
            {
              nroDorm && nroDorm.map((nro,i)=>(
                <Button key={i} data-id={`tabs-panel-${i}`} eventEle={handleTab} classEle={`boton-tab-proyecto color-pagina bg-negro pointer tabs-button ${i === 0 ? "active" :""}`}>
                  {nro} Dormitorio{(nro >= 2) && 's'}
                </Button>
              ))
            }
            </div>
            {
              starsCount && starsCount.map((dorm,i)=>(
                <div key={i} className={`tabs-panel ${i === 0 ? "active" :""}`} id={`tabs-panel-${i}`}>
                  <div>
                    <DormitorioProyecto
                      dorm={dorm}
                      configuracion={configuracion}
                      textoBoton={textoBoton}
                      nomProyecto={nomProyecto}
                      urlGracias={urlGracias}
                      correoCotiza={correoCotiza}
                      idProyecto={idProyecto}
                      numDorm={nroDorm}
                      urlWsp={urlWsp}
                      />
                  </div>
                </div>
              ))
            }
          </>
        }
      </div>
    )
}

export default PestaniaProyecto
