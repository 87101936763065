import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "./Layout.scss"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

export default function Layout({ children, main, isProyecto, ...others }) {
  // const seleccionarTab = useCallback(()=>{
  //   const seccionBarrio = document.querySelector("#barrio")
  //   const seccionMomento = document.querySelector("#momento")
  //   const tabs = document.querySelectorAll(".react-tabs__tab");
  //   const urlPagina = window.location.href;
  //   if(urlPagina.indexOf("#momento") >= 0){
  //     if(seccionMomento && tabs) tabs[0].click();
  //   }else if(urlPagina.indexOf("#barrio") >= 0){
  //     if(seccionBarrio && tabs) tabs[1].click()
  //   }
  // }, []);

  // useEffect(() => {
  //   seleccionarTab();
  // }, [seleccionarTab])

  return (
    <>
      <Helmet htmlAttributes={{ lang: "es" }} />
      <main {...others}>
        <Header isProyecto={isProyecto} />
        <main>{children}</main>
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

