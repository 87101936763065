import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import {graphql} from 'gatsby'
import BannerProyectos from "../../components/organisms/Banner/BannerProyectos"
import GridEntregados from "../../components/organisms/Grid/GridEntregados";

export const query = graphql `
query ($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        proyectos_entregados {
          textoDescripcion
          tituloDescripcion
          proyectos {
            distrito
            texto
            imagen {
              title
              altText
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
                }
              }
            }
          }
        }
        
      }
    }
  }
  
`

export default function Proyectos_Entregados({data}) {
    const page = data.pionero.page;
    const pageData = page?.proyectos_entregados;

    (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina', '#b2d4c4');
    (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto', '#b2d4c4');

    return ( 
        <Layout className="header-dark">
            <Seo post = {page}/> 
            <h1 className="none"> {page.title}</h1>
            <BannerProyectos data={pageData}/>
            <GridEntregados data={pageData}/>
        </Layout>
    )
}