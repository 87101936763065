import React from "react";

const Email = () => {
    return(
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            style={{
            display: "block",
            fill: "none",
            stroke: "currentcolor",
            strokeWidth: 2,
            overflow: "visible",
            }}
        >
            <rect height={24} rx={4} width={28} x={2} y={4} />
            <path d="m3 6 10.416 8.814a4 4 0 0 0 5.168 0L29 6" />
        </svg>
    )
}

export default Email;