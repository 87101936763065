import React from "react"
import { Grid } from "unsemantic/assets/react/dist/index"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./ProyectosHomePet.scss"
import GenerarAlt from "../../utils/GenerarAlt"
import ObtenerUri from "../../utils/ObtenerUri"

const ProyectosHomePet = ({ proyectos, tituloPage }) => {
  return (
    <section className="proyectoshomepey-seccion">
      <h3 className="none">Otros proyectos {tituloPage}</h3>
      <div className="mx-auto mwx-1680 grid-div-center">
        <Grid desktop="100" tablet="100" mobile="100">
          {proyectos &&
            proyectos.map((proyecto, i) => (
              <Grid
                key={i}
                desktop="25"
                tablet="25"
                mobile="100"
                className="proyectoshomepey-item"
              >
                <Link
                  to={`${ObtenerUri(proyecto.uri)}`}
                  className="relative block zoom-animacion bg-overlay"
                >
                  <div className="proyectoshomepey-item-imagen relative line-0">
                    <GatsbyImage
                      className="proyectoshomepey-imagen"
                      image={
                        proyecto.proyecto.imagenEnConstruccion.sourceUrlSharp
                          .childImageSharp.gatsbyImageData
                      }
                      alt={GenerarAlt(proyecto.proyecto.imagenEnConstruccion)}
                    />
                    <div className="depa-item-imagen-overlay overlay"></div>
                  </div>

                  <h4 className="proyectoshomepey-distrito color-negro size-22 weight-300 font-adria-medium mt-0 mb-0">
                    {proyecto.barrios.edges[0].node.name}
                  </h4>
                  <h3
                    className="proyectoshomepey-titulo color-negro size-39 weight-300 font-adria-ligth mt-0 mb-0 parrafo-0"
                    dangerouslySetInnerHTML={{
                      __html: proyecto.proyecto.bannerTitulo,
                    }}
                  />
                </Link>
              </Grid>
            ))}
        </Grid>
      </div>
    </section>
  )
}

export default ProyectosHomePet
