import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./BannerInversionistas.scss"
import SoloTexto from "../../utils/SoloTexto"
import GenerarAlt from "../../utils/GenerarAlt"

const BannerInversionistas = ({ titulo, fondoColor, imagen }) => {
  return (
    <section className="banner-home bg-negro bannerinversionistas">
      <h2 className="none">{SoloTexto(titulo)}</h2>
      <div className="banner-home-contenido mwx-1680 mx-auto relative">
        <div className="banner-home-flotante">
          <div
            className="bannerinversionistas-titulo size-81 font-roboto color-pagina weight-300 mt-0 mb-0"
            dangerouslySetInnerHTML={{ __html: titulo }}
          />
        </div>
        <GatsbyImage
          className="banner-home-imagen bannerinversionistas-imagen"
          image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
          alt={GenerarAlt(imagen)}
        />
        <svg
          className="banner-home-svg svg-animacion mov-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1680 631"
        >
          <path stroke={fondoColor} d="M 0,631 L 0,300 L 1680,626 L 1680,0" />
          <path stroke={fondoColor} d="M 3,631 L 3,300 L 1677,626 L 1677,0" />
        </svg>
        <svg
          className="banner-home-svg svg-animacion mov-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 362 677"
        >
          <path stroke={fondoColor} d="M 0,677 L 0,304 L 362,674 L 362,0" />
          <path stroke={fondoColor} d="M 2,677 L 2,304 L 359,674 L 359,0" />
        </svg>
      </div>
    </section>
  )
}

export default BannerInversionistas
