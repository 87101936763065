import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import Button from "../atoms/Button/Button";

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 'none',
    overflow: 'initial',
  }
};

if (typeof(window) !== 'undefined') {
  ReactModal.setAppElement('body')
}

const BotonIframeProyecto = ({boton}) => {

  const {texto, idGa, link} = boton;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const modalOpen = localStorage.getItem('modal-open');
    if(modalOpen === "true") setIsModalOpen(true);
    localStorage.setItem('modal-open', "false");
  }, [])

  return(
    <>
      <Button classEle="uppercase bg-negro boton-hover color-pagina size-25 font-lato weight-300" id={idGa} eventEle={()=>setIsModalOpen(true)}>{texto}</Button>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Videos"
        style={customStyles}
      >
        <iframe src={link} frameBorder="0" className="nuestrohomepet-iframe" title="Modal iframe"/>
        <button onClick={handleModalClose} className="form-cerrar">x</button>
      </ReactModal>
    </>
  )
}
export default BotonIframeProyecto;
