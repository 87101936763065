import React from "react"
import MenuItem from "../components/Menu/MenuItem"
import ObtenerUri from "./ObtenerUri"

export const CreateLocalLink = (menuItem, wordPressUrl, blogURI = "blog/") => {
  const { url } = menuItem

  if (url === "#" || url === null || url === undefined) {
    return "#"
  }
  // let newUri = url.replace(wordPressUrl, '');
  let newUri = url.replace(wordPressUrl, "")

  return ObtenerUri(newUri)
}

export const CrearItemsHijos = (menuItems, wordPressUrl) => {
  if (!menuItems) return ""
  if (!menuItems.edges.length) return ""

  menuItems = menuItems.edges
  return (
    <ul>
      {menuItems.map((menuItem, i) => (
        <MenuItem
          key={i}
          menuItem={menuItem.node}
          wordPressUrl={wordPressUrl}
        />
      ))}
    </ul>
  )
}

