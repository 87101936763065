import React, { useState, useRef } from "react"
import { Grid } from "unsemantic/assets/react/dist/index"
import JSONDep from "../../Json/Departamentos.json"
import JSONPro from "../../Json/Provincias.json"
import JSONDis from "../../Json/Distritos.json"
import "./FormReclamaciones.scss"

const FormReclamaciones = () => {
  const [nombres, setNombres] = useState("")
  const [apellidos, setApellidos] = useState("")
  const [tipoDoc, setTipoDoc] = useState("")
  const [nroDoc, setNroDoc] = useState("")
  const [direccion, setDireccion] = useState("")
  const [telefono, setTelefono] = useState("")
  const [email, setEmail] = useState("")
  const [proyecto, setProyecto] = useState("")
  const [tipoReclamo, setTipoReclamo] = useState("")
  const [motivo, setMotivo] = useState("")
  const [detalle, setDetalle] = useState("")
  const [pedido, setPedido] = useState("")
  const [fecha, setFecha] = useState("")
  const [hora, setHora] = useState("")
  const [idDepartamento, setIdDepartamento] = useState("")
  const [nomDepartamento, setNomDepartamento] = useState("")
  const [nomProvincia, setNomProvincia] = useState("")
  const [idProvincia, setIdProvincia] = useState("")
  const [provincias, setProvincias] = useState([])
  const [nomDistrito, setNomDistrito] = useState("")
  const [distritos, setDistritos] = useState("")
  const [idDistrito, setIdDistrito] = useState("")
  const [aceptar, setAceptar] = useState("")
  const [envio, setEnvio] = useState(false)
  const [msgEnvio, setMsgEnvio] = useState("")
  const [departamentoProyecto, setDepartamentoProyecto] = useState("")
  const [estacionamientoProyecto, setEstacionamientoProyecto] = useState("")
  const [depositoProyecto, setDepositoProyecto] = useState("")

  const [validNombres, setValidNombres] = useState("")
  const [validApellidos, setValidApellidos] = useState("")
  const [validTipoDoc, setValidTipoDoc] = useState("")
  const [validNroDoc, setValidNroDoc] = useState("")
  const [validDireccion, setValidDireccion] = useState("")
  const [validTelefono, setValidTelefono] = useState("")
  const [validEmail, setValidEmail] = useState("")
  const [validTipoReclamo, setValidTipoReclamo] = useState("")
  const [validMotivo, setValidMotivo] = useState("")
  const [validDetalle, setValidDetalle] = useState("")
  const [validPedido, setValidPedido] = useState("")
  const [validProyecto, setValidProyecto] = useState("")
  const [validIdDepartamento, setValidIdDepartamento] = useState("")
  const [validIdProvincia, setValidIdProvincia] = useState("")
  const [validIdDistrito, setValidIdDistrito] = useState("")
  const [validAceptar, setValidAceptar] = useState("")
  const [validDepartamentoProyecto, setValidDepartamentoProyecto] = useState("")
  const [
    validEstacionamientoProyecto,
    setValidEstacionamientoProyecto,
  ] = useState("")
  const [validDepositoProyecto, setValidDepositoProyecto] = useState("")

  let formStep1 = useRef(null)
  let formStep2 = useRef(null)
  let formStep3 = useRef(null)
  let hpionero = useRef(null)
  let hpionero2 = useRef(null)

  setInterval(function () {
    let fecha = new Date()
    let fechaActual = `${fecha.getFullYear()}/${
      parseInt(fecha.getMonth()) < 9
        ? "0" + (parseInt(fecha.getMonth()) + 1)
        : parseInt(fecha.getMonth()) + 1
    }/${fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate()}`
    let hora = `${
      fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours()
    }:${
      fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes()
    }:${
      fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds()
    }`
    setFecha(fechaActual)
    setHora(hora)
  }, 1000)

  const handleSubmit = event => {
    event.preventDefault()

    let errores = 0

    if (nombres === "") {
      setValidNombres(true)
      errores++
    } else setValidNombres(false)

    if (departamentoProyecto === "") {
      setValidDepartamentoProyecto(true)
      errores++
    } else setDepartamentoProyecto(false)
    if (estacionamientoProyecto === "") {
      setValidEstacionamientoProyecto(true)
      errores++
    } else setEstacionamientoProyecto(false)
    if (depositoProyecto === "") {
      setValidDepositoProyecto(true)
      errores++
    } else setValidDepositoProyecto(false)

    if (apellidos === "") {
      setValidApellidos(true)
      errores++
    } else setValidApellidos(false)
    if (tipoDoc === "") {
      setValidTipoDoc(true)
      errores++
    } else setValidTipoDoc(false)
    if (nroDoc === "") {
      setValidNroDoc(true)
      errores++
    } else setValidNroDoc(false)
    if (direccion === "") {
      setValidDireccion(true)
      errores++
    } else setValidDireccion(false)
    if (telefono === "") {
      setValidTelefono(true)
      errores++
    } else setValidTelefono(false)
    if (
      email === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      setValidEmail(true)
      errores++
    } else setValidEmail(false)
    if (tipoReclamo === "") {
      setValidTipoReclamo(true)
      errores++
    } else setValidTipoReclamo(false)
    if (motivo === "") {
      setValidMotivo(true)
      errores++
    } else setValidMotivo(false)
    if (detalle === "") {
      setValidDetalle(true)
      errores++
    } else setValidDetalle(false)
    if (pedido === "") {
      setValidPedido(true)
      errores++
    } else setValidPedido(false)
    if (proyecto === "") {
      setValidProyecto(true)
      errores++
    } else setValidProyecto(false)
    if (idDepartamento === "") {
      setValidIdDepartamento(true)
      errores++
    } else setValidIdDepartamento(false)
    if (idProvincia === "") {
      setValidIdProvincia(true)
      errores++
    } else setValidIdProvincia(false)
    if (idDistrito === "") {
      setValidIdDistrito(true)
      errores++
    } else setValidIdDistrito(false)
    if (aceptar === "" || aceptar === false) {
      setValidAceptar(true)
      errores++
    } else setValidAceptar(false)
    if (hpionero.current.value !== "") errores++
    if (hpionero2.current.value !== "apros") errores++
    if (hpionero.current.value !== "" || hpionero2.current.value !== "apros")
      setEnvio(true)

    if (errores === 0) {
      fetch(
        `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/1136/feedback`,
        {
          method: "POST",
          body:
            "nombres=" +
            nombres +
            "&apellidos=" +
            apellidos +
            "&tipo-doc=" +
            tipoDoc +
            "&nro-doc=" +
            nroDoc +
            "&direccion=" +
            direccion +
            "&departamento=" +
            idDepartamento +
            "&provincia=" +
            idProvincia +
            "&distrito=" +
            idDistrito +
            "&telefono=" +
            telefono +
            "&email=" +
            email +
            "&proyecto=" +
            proyecto +
            "&tipo-reclamo=" +
            tipoReclamo +
            "&motivo=" +
            motivo +
            "&detalle=" +
            detalle +
            "&pedido=" +
            pedido +
            "&departamento-proyecto=" +
            departamentoProyecto +
            "&estacionamiento-proyecto=" +
            estacionamientoProyecto +
            "&deposito-proyecto=" +
            depositoProyecto +
            "&fecha=" +
            fecha +
            "&hora=" +
            hora +
            "&departamentoValor=" +
            nomDepartamento +
            "&provinciaValor=" +
            nomProvincia +
            "&distritoValor=" +
            nomDistrito,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json.status === "mail_sent") {
            setMsgEnvio(true)
            event.target.reset()
            setNombres("")
            setApellidos("")
            setTipoDoc("")
            setNroDoc("")
            setDireccion("")
            setTelefono("")
            setEmail("")
            setTipoReclamo("")
            setMotivo("")
            setDetalle("")
            setPedido("")
            setProyecto("")
            setIdDepartamento("")
            setIdProvincia("")
            setIdDistrito("")
            setProvincias("")
            setNomDepartamento("")
            setNomProvincia("")
            setDistritos("")
            setNomDistrito("")
            setDepartamentoProyecto("")
            setDepositoProyecto("")
            setEstacionamientoProyecto("")

            setValidNombres(false)
            setValidApellidos(false)
            setValidTipoDoc(false)
            setValidNroDoc(false)
            setValidDireccion(false)
            setValidTelefono(false)
            setValidEmail(false)
            setValidTipoReclamo(false)
            setValidMotivo(false)
            setValidDetalle(false)
            setValidPedido(false)
            setValidProyecto(false)
            setValidIdDepartamento(false)
            setValidIdProvincia(false)
            setValidIdDistrito(false)
            setValidAceptar(false)

            setValidDepartamentoProyecto(false)
            setValidEstacionamientoProyecto(false)
            setValidDepositoProyecto(false)

            formStep1.current.classList.add("active")
            formStep2.current.classList.remove("active")
            formStep3.current.classList.remove("active")
          } else {
            setMsgEnvio(false)
          }
        })
    }
  }

  const handleSiguiente1 = event => {
    let errores = 0

    if (departamentoProyecto === "") {
      setValidDepartamentoProyecto(true)
      errores++
    } else setValidDepartamentoProyecto(false)
    if (estacionamientoProyecto === "") {
      setValidEstacionamientoProyecto(true)
      errores++
    } else setValidEstacionamientoProyecto(false)
    if (depositoProyecto === "") {
      setValidDepositoProyecto(true)
      errores++
    } else setValidDepositoProyecto(false)
    if (nombres === "") {
      setValidNombres(true)
      errores++
    } else setValidNombres(false)
    if (apellidos === "") {
      setValidApellidos(true)
      errores++
    } else setValidApellidos(false)
    if (tipoDoc === "") {
      setValidTipoDoc(true)
      errores++
    } else setValidTipoDoc(false)
    if (nroDoc === "") {
      setValidNroDoc(true)
      errores++
    } else setValidNroDoc(false)
    if (direccion === "") {
      setValidDireccion(true)
      errores++
    } else setValidDireccion(false)
    if (telefono === "") {
      setValidTelefono(true)
      errores++
    } else setValidTelefono(false)
    if (
      email === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      setValidEmail(true)
      errores++
    } else setValidEmail(false)
    if (idDepartamento === "") {
      setValidIdDepartamento(true)
      errores++
    } else setValidIdDepartamento(false)
    if (idProvincia === "") {
      setValidIdProvincia(true)
      errores++
    } else setValidIdProvincia(false)
    if (idDistrito === "") {
      setValidIdDistrito(true)
      errores++
    } else setValidIdDistrito(false)
    if (idDepartamento === "") {
      setValidIdDepartamento(true)
      errores++
    } else setValidIdDepartamento(false)

    if (errores === 0) {
      formStep1.current.classList.remove("active")
      formStep2.current.classList.add("active")
    }
  }

  const handleAnterior2 = event => {
    formStep1.current.classList.add("active")
    formStep2.current.classList.remove("active")
  }

  const handleSiguiente2 = event => {
    let errores = 0

    if (tipoReclamo === "") {
      setValidTipoReclamo(true)
      errores++
    } else setValidTipoReclamo(false)

    if (errores === 0) {
      formStep2.current.classList.remove("active")
      formStep3.current.classList.add("active")
    }
  }

  const handleAnterior3 = event => {
    formStep2.current.classList.add("active")
    formStep3.current.classList.remove("active")
  }

  const handleDep = event => {
    setIdDepartamento(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomDepartamento(event.target.options[index].text)
      const pro = JSONPro[0][event.target.value].map((ele, i) => {
        return (
          <option key={i} value={ele.id_ubigeo}>
            {ele.nombre_ubigeo}
          </option>
        )
      })
      setProvincias(pro)
    } else {
      setProvincias("")
      setNomDepartamento("")
      setNomProvincia("")
      setDistritos("")
      setNomDistrito("")
    }
  }
  const handleProv = event => {
    setIdProvincia(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomProvincia(event.target.options[index].text)
      const dis = JSONDis[0][event.target.value].map((ele, i) => {
        return (
          <option key={i} value={ele.id_ubigeo}>
            {ele.nombre_ubigeo}
          </option>
        )
      })
      setDistritos(dis)
    } else {
      setNomProvincia("")
      setNomDistrito("")
      setDistritos("")
    }
  }
  const handleDis = event => {
    setIdDistrito(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomDistrito(event.target.options[index].text)
    } else {
      setNomDistrito("")
    }
  }

  return (
    <section className="form-reclamaciones mwx-1178 mx-auto">
      <h3 className="none">Formulario de Libro de reclamaciones</h3>
      <p className="size-22 px-20px">
        Conforme a lo establecido en el Código de Protección y Defensa del
        Consumidor (Ley N°29571) y el Reglamento del Libro de Reclamaciones,
        este establecimiento cuenta con un libro de reclamaciones de naturaleza
        virtual a su disposición, a efectos de que pueda formular su queja o
        reclamo
      </p>
      <form onSubmit={handleSubmit} encType="multipart/form-data" noValidate>
        <div className="form-step form-step-1 active" ref={formStep1}>
          <Grid
            desktop="100"
            tablet="100"
            mobile="100"
            className="mov-p-0 flex-wrap"
          >
            <Grid desktop="100" tablet="100" mobile="100" className=" mov-p-0">
              <div className="step__items">
                <div className="step__item active size-17">
                  1 Datos del Reclamante
                </div>
                <div className="step__item size-17">
                  2 Identificación del Reclamo/Queja
                </div>
                <div className="step__item size-17">
                  3 Detalle del Reclamo/Queja y detalle
                </div>
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input type="hidden" name="_wpcf7" value="1136" />
                <input
                  type="text"
                  value={nombres}
                  onChange={e => setNombres(e.target.value)}
                  name="nombres"
                  placeholder="*Nombres"
                />
                {validNombres && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={apellidos}
                  onChange={e => setApellidos(e.target.value)}
                  name="apellidos"
                  placeholder="*Apellidos"
                />
                {validApellidos && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <select
                  name="tipo-doc"
                  id=""
                  value={tipoDoc}
                  onChange={e => setTipoDoc(e.target.value)}
                  onBlur={e => setTipoDoc(e.target.value)}
                >
                  <option value="">*Tipo de documento</option>
                  <option value="Dni">DNI</option>
                  <option value="Pasaporte">PASAPORTE</option>
                  <option value="Ruc">RUC</option>
                  <option value="Carnet de extranjería">
                    Carnet de extranjería
                  </option>
                </select>
                {validTipoDoc && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="number"
                  value={nroDoc}
                  onChange={e => setNroDoc(e.target.value)}
                  name="nro-doc"
                  placeholder="Nro de Documento"
                />
                {validNroDoc && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={direccion}
                  onChange={e => setDireccion(e.target.value)}
                  name="direccion"
                  placeholder="*Dirección"
                />
                {validDireccion && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <select
                  name="id_departamento"
                  value={idDepartamento}
                  onChange={handleDep}
                  onBlur={handleDep}
                >
                  <option value="">Seleccionar</option>
                  {JSONDep &&
                    JSONDep.map((dep, i) => (
                      <option key={i} value={dep.id_ubigeo}>
                        {dep.nombre_ubigeo}
                      </option>
                    ))}
                </select>
                {validIdDepartamento && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <select
                  name="id_provincia"
                  value={idProvincia}
                  onChange={handleProv}
                  onBlur={handleProv}
                >
                  <option value="">Seleccionar</option>
                  {provincias}
                </select>
                {validIdProvincia && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <select
                  name="id_distrito"
                  value={idDistrito}
                  onChange={handleDis}
                  onBlur={handleDis}
                >
                  <option value="">Seleccionar</option>
                  {distritos}
                </select>
                {validIdDistrito && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={telefono}
                  onChange={e => setTelefono(e.target.value)}
                  name="telefono"
                  placeholder="*Teléfono fijo / Móvil"
                />
                {validTelefono && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="50" tablet="50" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                  placeholder="*Correo Electrónico"
                />
                {validEmail && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <select
                  name="proyecto"
                  value={proyecto}
                  onChange={e => setProyecto(e.target.value)}
                  onBlur={e => setProyecto(e.target.value)}
                >
                  <option value="">Seleccionar proyecto</option>
                  <option value="Const. E Inmob. Pionero Tres S.A.C - RUC 20548368210 - (Juan de Aliaga 488)">
                    Const. E Inmob. Pionero Tres S.A.C - RUC 20548368210 - (Juan
                    de Aliaga 488)
                  </option>
                  <option value="Const. E Inmob. Pionero Cuatro S.A.C - RUC 20556033839 - (Bolivia 848)">
                    Const. E Inmob. Pionero Cuatro S.A.C - RUC 20556033839 -
                    (Bolivia 848)
                  </option>
                  <option value="Pionero 6 S.A.C - RUC 20563685825 - (San Felipe 802)">
                    Pionero 6 S.A.C - RUC 20563685825 - (San Felipe 802)
                  </option>
                  <option value="Pionero 7 S.A.C - RUC 20563683709 - (Sol 135)">
                    Pionero 7 S.A.C - RUC 20563683709 - (Sol 135)
                  </option>
                  <option value="Pionero 8 S.A.C - RUC 20601832331 - (Rivera Navarrete 548)">
                    Pionero 8 S.A.C - RUC 20601832331 - (Rivera Navarrete 548)
                  </option>
                  <option value="Pionero 9 S.A.C - RUC 20602182569 - (Villarán 325)">
                    Pionero 9 S.A.C - RUC 20602182569 - (Villarán 325)
                  </option>
                  <option value="Pionero 10 S.A.C - RUC 20602182607	- (Tejada 275)">
                    Pionero 10 S.A.C - RUC 20602182607 - (Tejada 275)
                  </option>
                  <option value="Pionero 11 S.A.C - RUC 20603448279	- (Vidal 140)">
                    Pionero 11 S.A.C - RUC 20603448279 - (Vidal 140)
                  </option>
                  <option value="Pionero 12 S.A.C - RUC 20603734522	- (Dos de Mayo 348)">
                    Pionero 12 S.A.C - RUC 20603734522 - (Dos de Mayo 348)
                  </option>
                  <option value="Pionero 14 S.A.C RUC: 20607227951 - (Reducto 1421)">
                    Pionero 14 S.A.C RUC: 20607227951 - (Reducto 1421)
                  </option>
                  <option value="Asociación de las bienaventuranzas S.A.C RUC: 20557629964 - (Parque Blume 198)">
                    Asociación de las bienaventuranzas S.A.C RUC: 20557629964 -
                    (Parque Blume 198)
                  </option>
                </select>
                {validProyecto && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={departamentoProyecto}
                  onChange={e => setDepartamentoProyecto(e.target.value)}
                  name="departamento-proyecto"
                  placeholder="*N° departamento"
                />
                {validDepartamentoProyecto && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={estacionamientoProyecto}
                  onChange={e => setEstacionamientoProyecto(e.target.value)}
                  name="estacionamiento-proyecto"
                  placeholder="*N° estacionamiento"
                />
                {validEstacionamientoProyecto && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="33" tablet="33" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  value={depositoProyecto}
                  onChange={e => setDepositoProyecto(e.target.value)}
                  name="deposito-proyecto"
                  placeholder="*N° deposito"
                />
                {validDepositoProyecto && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid
              desktop="100"
              tablet="100"
              mobile="100"
              className="mov-p-0 parrafo-mb-15"
            >
              <p className="size-17">
                * Los campos indicados con asterisco son obligatorios.
              </p>
              <p className="size-17">
                ** Al brindar mi correo electrónico, autorizo a que cualquier
                comunicación respecto al reclamo se realice a través del mismo.
              </p>
              <p className="size-17">
                *** El tratamiento de sus datos personales en este portal tiene
                por finalidad gestionar de manera correcta su reclamo o queja
                conforme a las disposiciones legales establecida.
              </p>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className=" mov-p-0">
              <button
                type="button"
                className="btn-reclamaciones bg-negro color-blanco size-17 pointer"
                onClick={handleSiguiente1}
              >
                Siguiente
              </button>
            </Grid>
          </Grid>
        </div>
        <div className="form-step form-step-2" ref={formStep2}>
          <Grid
            desktop="100"
            tablet="100"
            mobile="100"
            className="mov-p-0 flex-wrap"
          >
            <Grid desktop="100" tablet="100" mobile="100" className=" mov-p-0">
              <div className="step__items">
                <div className="step__item active size-17">
                  1 Datos del Reclamante
                </div>
                <div className="step__item size-17 active">
                  2 Identificación del Reclamo/Queja
                </div>
                <div className="step__item size-17">
                  3 Detalle del Reclamo/Queja y detalle
                </div>
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <label className="form-check">
                  <input
                    type="radio"
                    value="Queja: Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público."
                    name="tipo-reclamo"
                    onChange={e => setTipoReclamo(e.target.value)}
                  />
                  <span className="size-17">
                    Queja: Disconformidad no relacionada a los productos o
                    servicios; o, malestar o descontento respecto a la atención
                    al público.
                  </span>
                </label>
              </div>
              <div className="form-control">
                <label>
                  <input
                    type="radio"
                    value="Reclamo: Disconformidad relacionada a los productos y servicios."
                    onChange={e => setTipoReclamo(e.target.value)}
                    name="tipo-reclamo"
                  />
                  <span className="size-17">
                    Reclamo: Disconformidad relacionada a los productos y
                    servicios.
                  </span>
                </label>
                {validTipoReclamo && (
                  <div className="size-17 color-rojo">
                    El campo es obligatorio.
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              desktop="100"
              tablet="100"
              mobile="100"
              className="mov-p-0 parrafo-mb-15"
            >
              <p className="size-17">
                * El proveedor deberá dar respuesta al reclamo en un plazo no
                mayor a treinta (30) días calendario, pudiendo ampliar el plazo
                hasta por treinta (30) días más, previa comunicación al
                consumidor.
              </p>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className=" mov-p-0">
              <button
                type="button"
                className="btn-reclamaciones bg-negro color-blanco size-17 pointer"
                onClick={handleAnterior2}
              >
                Anterior
              </button>
              <button
                type="button"
                className="btn-reclamaciones bg-negro color-blanco size-17 pointer"
                onClick={handleSiguiente2}
              >
                Siguiente
              </button>
            </Grid>
          </Grid>
        </div>
        <div className="form-step form-step-3" ref={formStep3}>
          <Grid
            desktop="100"
            tablet="100"
            mobile="100"
            className="mov-p-0 flex-wrap"
          >
            <Grid desktop="100" tablet="100" mobile="100" className=" mov-p-0">
              <div className="step__items">
                <div className="step__item active size-17">
                  1 Datos del Reclamante
                </div>
                <div className="step__item size-17">
                  2 Identificación del Reclamo/Queja
                </div>
                <div className="step__item size-17">
                  3 Detalle del Reclamo/Queja y detalle
                </div>
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <div className="">
                  <label className="form-check">
                    <input
                      type="radio"
                      value="Servicio"
                      name="motivo"
                      onChange={e => setMotivo(e.target.value)}
                    />
                    <span className="size-17">Servicio</span>
                  </label>
                </div>
                <div className="">
                  <label className="form-check">
                    <input
                      type="radio"
                      value="Productos"
                      name="motivo"
                      onChange={e => setMotivo(e.target.value)}
                    />
                    <span className="size-17">Productos</span>
                  </label>
                </div>
                <div className="">
                  <label className="form-check">
                    <input
                      type="radio"
                      value="Otros"
                      name="motivo"
                      onChange={e => setMotivo(e.target.value)}
                    />
                    <span className="size-17">Otros</span>
                  </label>
                  {validMotivo && (
                    <span className="size-17 color-rojo">
                      El campo es obligatorio.
                    </span>
                  )}
                </div>
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <textarea
                  name="detalle"
                  cols="30"
                  rows="3"
                  placeholder="Detalle del reclamo o queja"
                  value={detalle}
                  onChange={e => setDetalle(e.target.value)}
                />
                {validDetalle && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <textarea
                  name="pedido"
                  id=""
                  cols="30"
                  rows="3"
                  placeholder="Pedido del reclamo o queja"
                  value={pedido}
                  onChange={e => setPedido(e.target.value)}
                />
                {validPedido && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <label className="size-17">
                  Fecha:{" "}
                  <input
                    name="fechaNula"
                    type="text"
                    value={fecha}
                    onChange={e => setFecha(e.target.value)}
                  />
                </label>
                <input
                  name="fecha"
                  type="hidden"
                  value={fecha}
                  onChange={e => setFecha(e.target.value)}
                />
              </div>
              <div className="form-control">
                <label className="size-17">
                  Hora:{" "}
                  <input
                    name="horaNula"
                    type="text"
                    value={hora}
                    onChange={e => setHora(e.target.value)}
                  />
                </label>
                <input
                  type="hidden"
                  name="hora"
                  value={hora}
                  onChange={e => setHora(e.target.value)}
                />
              </div>
              <p className="size-17">
                El proveedor conservará como mínimo por el lapso de dos (02)
                años desde la fecha de presentación de la queja o reclamo en el
                Libro de Reclamaciones, la siguiente información: 1) las Hojas
                de Reclamaciones de naturaleza física o virtual; y b) la
                constancia de la respuesta al reclamo y de la ampliación del
                plazo, de corresponder.
              </p>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <div className="form-control">
                <input
                  type="text"
                  name="hpionero"
                  className="none"
                  ref={hpionero}
                  defaultValue=""
                />
                <input
                  type="text"
                  name="hpionero2"
                  defaultValue="apros"
                  style={{ display: "none" }}
                  ref={hpionero2}
                />
                <label className="form-aceptar">
                  <input type="checkbox" onChange={e => setAceptar(!aceptar)} />
                  <span className="size-17">
                    Al enviar este reclamo / queja usted está conforme con los
                    datos consignados.
                  </span>
                </label>
                {validAceptar && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </div>
            </Grid>
            <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
              <button
                type="button"
                className="btn-reclamaciones bg-negro color-blanco size-17 pointer"
                onClick={handleAnterior3}
              >
                Anterior
              </button>
              <button
                type="submit"
                className="btn-reclamaciones bg-negro color-blanco size-17 pointer"
                disabled={envio}
              >
                Enviar
              </button>
            </Grid>
          </Grid>
        </div>
        <Grid desktop="100" tablet="100" mobile="100" className="mov-p-0">
          {msgEnvio === true && msgEnvio !== "" && (
            <div className="size-17 font-roboto weight-bold text-center">
              Gracias por tu mensaje. Ha sido enviado.
            </div>
          )}
          {msgEnvio === false && envio !== "" && (
            <div className="font-roboto">Error en el envio</div>
          )}
        </Grid>
      </form>
    </section>
  )
}

export default FormReclamaciones
