import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import BannerPagina from "../../components/Banner/BannerPagina"
import FormReclamaciones from "../../components/Formularios/FormReclamaciones"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
        }
        
        libro_de_reclamaciones {
          bannerTitulo
          bannerColorFondo
          bannerImagenFondo {
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`

export default function Libro ({data}) {

  const page = data.pionero.page;

  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina',page.libro_de_reclamaciones.bannerColorFondo);
  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');
  
  return (
    <Layout>
      <Seo post={page}/>
      <h1 className="none">{page.title}</h1>
      <BannerPagina 
        titulo={page.libro_de_reclamaciones.bannerTitulo}
        fondoColor={page.libro_de_reclamaciones.bannerColorFondo}
        imagen={page.libro_de_reclamaciones.bannerImagenFondo}
      />
      <FormReclamaciones />
    </Layout>
  )
}