import React, { useEffect, useState } from "react"
// import pionero from '../../video/pionero.mp4'
// import pionero from '../../images/mensaje.jpg'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactModal from 'react-modal'
import './Modalnicio.scss';

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-46%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 'none',
    overflow: 'initial',
  }
};

if (typeof(window) !== 'undefined') {
  ReactModal.setAppElement('body')
}

const ModalInicio = ({imagen}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (typeof(window) !== 'undefined') {
      setTimeout(()=>{
        setIsModalOpen(true);
      },5000);
    }
  }, []);



  const handleModalClose = event => {
    setIsModalOpen(false)
  }
  return (
    <div>
      <ReactModal
          isOpen={isModalOpen}
          onRequestClose={handleModalClose}
          contentLabel="Modal Video1"
          style={customStyles}
        >
        <div>
          <GatsbyImage image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="Imagen Modal" />
          {/*<video controls autoPlay muted playsInline src={pionero} className="modal-inicio">*/}
          {/*</video>*/}
        </div>

        <button onClick={handleModalClose} className="form-cerrar">x</button>
      </ReactModal>
    </div>
  )
}

export default ModalInicio