import React from "react"
import "react-tabs/style/react-tabs.scss"
import "./DepasInicio.scss"
import SliderDepas from "../Slider/SliderDepas"

const DepasInicio = ({
  tituloDepas,
  tituloTab1,
  tituloTab2,
  textDepas,
  momentoId,
  barrioId,
}) => {
  return (
    <>
      <section id="momento" />
      <section id="barrio" />
      <section className="depa-seccion bg-negro" id="depa">
        <div>
          <h3 className="depa-titulo weight-300 mt-0 size-60 text-center color-marron font-roboto mwx-1136 mx-auto">
            {tituloDepas}
          </h3>
          {textDepas && (
            <h4 className="depa-subtitulo text-center color-marron font-lato weight-500 size-28">
              {textDepas}
            </h4>
          )}
          <SliderDepas
            title1={tituloTab2}
            title2={tituloTab1}
            momentoId={momentoId}
            barrioId={barrioId}
          />
        </div>
      </section>
    </>
  )
}

export default DepasInicio
