import React from "react"
import "./BannerProyecto.scss"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import GenerarAlt from "../../utils/GenerarAlt"
import IconEstadoProyecto from "../Icon/IconEstadoProyecto"
import Button from "../atoms/Button/Button"

const BannerProyecto = ({
  slug,
  distrito,
  fondoColor,
  titulo,
  detalle,
  imagen,
  info,
  isHome,
  imagenResponsive,
  estado,
  textoSecundario,
  brochure,
  idGaBrochure,
  logoInfo,
  cotizarBotonFlotanteIdTag,
  cotizarIdTag,
}) => {
  const handleTarget = e => {
    e.preventDefault()
    const offsetTop = document.querySelector("#cotizar").offsetTop - 70

    window.scroll({
      top: offsetTop,
      behavior: "smooth",
    })
  }

  return (
    <>
      <a
        onClick={handleTarget}
        className={`btn-float-cotizar ${cotizarBotonFlotanteIdTag}`}
        href="#cotizar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 112.87 112.87"
          className="no-events"
        >
          <defs></defs>
          <g id="Capa_2" data-name="Capa 2">
            <g id="Layer_1" data-name="Layer 1">
              <path
                d="M56.43 110.87a54.44 54.44 0 1 1 54.44-54.44 54.5 54.5 0 0 1-54.44 54.44Z"
                style={{
                  fill: "var(--color-pagina)",
                  fillRule: "evenodd",
                }}
              />
              <path
                className="cls-2"
                d="M56.43 4A52.44 52.44 0 1 1 4 56.43 52.5 52.5 0 0 1 56.43 4m0-4A56.59 56.59 0 0 0 0 56.43a56.6 56.6 0 0 0 56.43 56.44 56.61 56.61 0 0 0 56.44-56.44A56.6 56.6 0 0 0 56.43 0Z"
              />
              <text
                transform="translate(21.89 75.86)"
                style={{
                  fontSize: "22.69px",
                  fontFamily: "Lato-Bold,Lato",
                  fontWeight: 700,
                  fill: "#2d2d2d",
                }}
              >
                {"Cotiza"}
                <tspan x={10.86} y={19.51}>
                  {"aqu\xED"}
                </tspan>
              </text>
              <path
                className="cls-4"
                d="M48.52 39.6c1.32 1.31 2.64 2.62 3.95 3.95.21.21.32.24.56 0Q60.29 36.27 67.57 29L70 26.54a5.87 5.87 0 0 1-4.42-4.49Q57.06 30.6 48.5 39.14c-.19.2-.16.28.02.46ZM45.3 46.81l4.79-1.6L46.9 42Z"
              />
              <path
                className="cls-5"
                d="M67.25 16.57c.16-.16.34-.29.51-.43-.19.17-.36.28-.51.43ZM77.41 21.35v.01l.01-.01h-.01z"
              />
              <path
                className="cls-2"
                d="M67.16 16.66v-.05c0-.05.02.03 0 .05ZM67.72 16.18l.35-.28c-.13.1-.23.19-.35.28ZM72.21 17.52a3.14 3.14 0 0 0-3.21 1.2l4.31 4.33c.1.09.15.11.25 0a3.15 3.15 0 0 0 1-3.27 3 3 0 0 0-2.35-2.26ZM75.41 25l.17-.18-.14.15Z"
              />
              <path
                className="cls-2"
                d="M71.32 14.9a5.78 5.78 0 0 0-3.25 1l-.35.28c-.17.14-.35.27-.51.43v.05L45.64 38.17a2.61 2.61 0 0 0-.64 1.08c-1 3-2 6-3 9.08a1.55 1.55 0 0 0-.09 1.06 1.25 1.25 0 0 0 .67.8c.1.05.24.06.22.23h.63c0-.18.15-.15.25-.19l9-3a3.44 3.44 0 0 0 1.43-.88Q64.74 35.67 75.41 25l.14-.15a5.89 5.89 0 0 0-4.26-9.93Zm.89 2.62a3 3 0 0 1 2.36 2.27 3.15 3.15 0 0 1-1 3.27c-.1.1-.15.08-.25 0L69 18.72a3.14 3.14 0 0 1 3.21-1.2ZM45.3 46.81 46.9 42l3.19 3.19ZM67.57 29Q60.3 36.28 53 43.56c-.24.23-.35.2-.56 0-1.31-1.33-2.63-2.64-3.95-3.95-.18-.18-.21-.26 0-.46q8.56-8.53 17.11-17.09l1.15-1.16c.15-.15.23-.19.41 0l4.07 4.07a1.18 1.18 0 0 0 .22.14l-.26.29L70 26.54Z"
              />
            </g>
          </g>
        </svg>
      </a>
      <section
        className="bannerproyecto-seccion relative"
        style={{ backgroundColor: fondoColor }}
      >
        <h2
          className="d-none"
          dangerouslySetInnerHTML={{ __html: titulo }}
        ></h2>
        <div className="banner bannerproyecto-contenido mwx-1680 mx-auto w-100">
          <div className="bannerproyecto-item-seccion relative">
            {estado && (
              <div className="bannerproyecto-estado">
                <IconEstadoProyecto text={estado} classEle="color-pagina" />
              </div>
            )}
            {distrito && (
              <h3 className="bannerproyecto-titulo-distrito size-51 font-roboto weight-300">
                {distrito}
              </h3>
            )}
            {titulo && (
              <div
                className="bannerproyecto-titulo-proyecto font-roboto weight-300"
                dangerouslySetInnerHTML={{ __html: titulo }}
              />
            )}
            {textoSecundario && (
              <h3
                className="bannerproyecto-texto-secundario font-roboto weight-300"
                dangerouslySetInnerHTML={{ __html: textoSecundario }}
              />
            )}
            <div className="botones-gap items-center">
              <Button
                size="small"
                eventEle={handleTarget}
                classEle={`js-animated-button font-lato weight-bold btn-quote ${cotizarIdTag}`}
                href="#cotizar"
              >
                <span className="btn--svg">
                  <span className="btn--svg__label font-lato">COTIZAR</span>
                  <svg
                    className="btn--svg__circle"
                    width="190"
                    x="0px"
                    y="0px"
                    viewBox="0 0 60 60"
                    enableBackground="new 0 0 60 60"
                  >
                    <circle
                      className="js-discover-circle"
                      fill="#2D2D2D"
                      cx="30"
                      cy="30"
                      r="28.7"
                    />
                  </svg>
                  <svg
                    className="btn--svg__border"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    viewBox="2 29.3 56.9 13.4"
                    enableBackground="new 2 29.3 56.9 13.4"
                    width="190"
                  >
                    <g
                      className="btn--svg__border--left js-discover-left-border"
                      id="Calque_2"
                    >
                      <path
                        fill="none"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="1"
                        d="M30.4,41.9H9c0,0-6.2-0.3-6.2-5.9S9,30.1,9,30.1h21.4"
                      />
                    </g>
                    <g
                      className="btn--svg__border--right js-discover-right-border"
                      id="Calque_3"
                    >
                      <path
                        fill="none"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="1"
                        d="M30.4,41.9h21.5c0,0,6.1-0.4,6.1-5.9s-6-5.9-6-5.9H30.4"
                      />
                    </g>
                  </svg>
                </span>
              </Button>
              {/* {
              brochure &&
              <Button type='pdf' size="small" rel="noreferrer" href={`/pdf/?url=${base64_encode(brochure)}`} classEle={`brochure-boton ${idGaBrochure} `} target="_blank">
                <span className='weight-bold'>{ObtenerTextoBrochure(slug)}</span>
              </Button>
            } */}
            </div>
            <div className="bannerproyecto-flotante">
              <div className="bannerproyecto-flotante-lista">
                {detalle &&
                  detalle.map((detalle, i) => (
                    <div key={i} className="bannerproyecto-flotante-item">
                      {detalle?.imagen && (
                        <GatsbyImage
                          className="bannerproyecto-flotante-imagen"
                          image={
                            detalle?.imagen.sourceUrlSharp.childImageSharp
                              .gatsbyImageData
                          }
                          alt={GenerarAlt(detalle.imagen)}
                        />
                      )}
                      {detalle?.texto && (
                        <div
                          className="size-19 parrafo-0 nowrap"
                          dangerouslySetInnerHTML={{ __html: detalle?.texto }}
                        />
                      )}
                    </div>
                  ))}
              </div>
              <div className="bannerproyecto-flotante-item">
                {info && (
                  <div
                    className="bannerproyecto-flotante-areas size-19"
                    dangerouslySetInnerHTML={{ __html: info }}
                  />
                )}
              </div>
            </div>
            <div className="bannerproyecto-espacio" />
            {isHome && (
              <StaticImage
                src="../../images/iconohome.png"
                alt="Icono Homer Pet Proyecto"
                placeholder="NONE"
                layout="fixed"
                className="bannerproyecto-flotante-icono mov-block"
              />
            )}
            <div className="bannerproyecto-flotante bannerproyecto-flotante-2">
              {isHome && (
                <StaticImage
                  src="../../images/iconohome.png"
                  alt="Icono Homer Pet Proyecto"
                  placeholder="NONE"
                  layout="fixed"
                  className="bannerproyecto-flotante-icono mov-none"
                />
              )}
              {logoInfo && (
                <GatsbyImage
                  className="bannerproyecto-flotante-logo"
                  image={
                    logoInfo.sourceUrlSharp.childImageSharp.gatsbyImageData
                  }
                  alt={GenerarAlt(logoInfo)}
                />
              )}
              <svg
                className="bannerproyecto-flotante-svg svg-animacion mov-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 705 466"
              >
                <path stroke="#2e2e2e" d="M 0,466 L 0,270 L 705,461 L 705,0" />
                <path stroke="#2e2e2e" d="M 3,466 L 3,270 L 702,461 L 702,0" />
              </svg>
            </div>
            <svg
              className="banner-svg svg-animacion mov-block w-100"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 362 540"
            >
              <path stroke="#2e2e2e" d="M 0,540 L 0,200 L 362,540 L 362,0" />
              <path stroke="#2e2e2e" d="M 2,540 L 2,200 L 360,540 L 360,0" />
            </svg>
          </div>
          {imagen && (
            <GatsbyImage
              className="mov-none bannerproyecto-item-seccion bannerproyecto-item-seccion bannerproyecto-imagen"
              image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
              alt={GenerarAlt(imagen)}
            />
          )}
          {imagenResponsive && (
            <GatsbyImage
              className="mov-block bannerproyecto-item-seccion bannerproyecto-item-seccion bannerproyecto-imagen"
              image={
                imagenResponsive.sourceUrlSharp.childImageSharp.gatsbyImageData
              }
              alt={GenerarAlt(imagenResponsive)}
            />
          )}
          <div className="bannerproyecto-espacio-mov" />
        </div>
      </section>
    </>
  )
}

export default BannerProyecto
