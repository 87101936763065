import React, { useState, useRef } from "react"
import { Grid } from "unsemantic/assets/react/dist/index"
import JSONDep from "../../Json/Departamentos.json"
import JSONPro from "../../Json/Provincias.json"
import JSONDis from "../../Json/Distritos.json"
import { navigate, Link } from "gatsby"
import Button from "../atoms/Button/Button"

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1)
  var sURLVariables = sPageURL.split("&")
  var sParameterName
  var i
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=")

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1])
    }
  }
}

const FormCotizar = ({
  urlWsp,
  tipoDepa,
  nomProyecto,
  urlGracias,
  codInmueble,
  correoCotiza,
  numDorm,
  idProyecto,
}) => {
  const [nombre, setNombre] = useState("")
  const [apellidos, setApellidos] = useState("")
  const [telefono, setTelefono] = useState("")
  const [email, setEmail] = useState("")
  const [idDepartamento, setIdDepartamento] = useState("")
  const [nomDepartamento, setNomDepartamento] = useState("")
  const [nomProvincia, setNomProvincia] = useState("")
  const [idProvincia, setIdProvincia] = useState("")
  const [provincias, setProvincias] = useState([])
  const [nomDistrito, setNomDistrito] = useState("")
  const [distritos, setDistritos] = useState("")
  const [idDistrito, setIdDistrito] = useState("")
  const [envio, setEnvio] = useState("")
  const [aceptar, setAceptar] = useState("")

  const [validNombres, setValidNombres] = useState("")
  const [validApellidos, setValidApellidos] = useState("")
  const [validTelefono, setValidTelefono] = useState("")
  const [validEmail, setValidEmail] = useState("")
  const [validIdDepartamento, setValidIdDepartamento] = useState("")
  const [validIdProvincia, setValidIdProvincia] = useState("")
  const [validIdDistrito, setValidIdDistrito] = useState("")
  const [validAceptar, setValidAceptar] = useState("")

  let hpionero = useRef(null)
  let hpionero2 = useRef(null)
  let whatsapp = useRef(null)
  const btnSubmitRef = useRef(null)

  let utm_source = ""
  let utm_medium = ""
  let utm_campaign = ""
  let utm_term = ""
  let utm_content = ""

  utm_campaign = getUrlParameter("utm_campaign")
  utm_content = getUrlParameter("utm_content")
  utm_medium = getUrlParameter("utm_medium")
  utm_source = getUrlParameter("utm_source")
  utm_term = getUrlParameter("utm_term")

  const handleSubmit = async event => {
    event.preventDefault()

    let errores = 0

    if (nombre === "") {
      setValidNombres(true)
      errores++
    } else setValidNombres(false)
    if (apellidos === "") {
      setValidApellidos(true)
      errores++
    } else setValidApellidos(false)
    if (telefono === "") {
      setValidTelefono(true)
      errores++
    } else setValidTelefono(false)
    if (
      email === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      setValidEmail(true)
      errores++
    } else setValidEmail(false)
    if (idDepartamento === "") {
      setValidIdDepartamento(true)
      errores++
    } else setValidIdDepartamento(false)
    if (idProvincia === "") {
      setValidIdProvincia(true)
      errores++
    } else setValidIdProvincia(false)
    if (idDistrito === "") {
      setValidIdDistrito(true)
      errores++
    } else setValidIdDistrito(false)
    if (aceptar === "" || aceptar === false) {
      setValidAceptar(true)
      errores++
    } else setValidAceptar(false)
    if (hpionero.current.value !== "") errores++
    if (hpionero2.current.value !== "apros") errores++
    if (hpionero.current.value !== "" || hpionero2.current.value !== "apros")
      setEnvio(true)

    if (errores === 0) {
      if (codInmueble === undefined) codInmueble = ""
      if (utm_campaign === undefined) utm_campaign = ""
      if (utm_content === undefined) utm_content = ""
      if (utm_medium === undefined) utm_medium = ""
      if (utm_source === undefined) utm_source = ""
      if (utm_term === undefined) utm_term = ""

      const dataPost = {
        IdTipoPortal: 6,
        IdProyecto: idProyecto,
        CodInmueble: codInmueble,
        Nombres: nombre,
        Apellidos: apellidos,
        Correo: email,
        Celular: telefono,
        Comentario: `Proyecto: ${nomProyecto}, Tipo departamento: ${tipoDepa}, Departamento: ${nomDepartamento}, Provincia: ${nomProvincia}, Distrito: ${nomDistrito}`,
        IncluyeUtm: true,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_term: utm_term,
        numDormitorio: numDorm,
        usuario: process.env.GATSBY_PAGINA_USER_EVOLTA,
        clave: process.env.GATSBY_PAGINA_PASS_EVOLTA,
      }

      const whatsappValue = whatsapp.current.value

      const rpta = await fetch(
        `${process.env.GATSBY_PAGINA_URL_EVOLTA}/lead/save`,
        {
          method: "POST",
          body: JSON.stringify(dataPost),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      const data = await rpta.json()
      if (data === "000") {
        if (whatsappValue === "1") {
          if (urlWsp) window.open(urlWsp, "_blank")
        }
        setEnvio(true)
        navigate(urlGracias)
      } else {
        setEnvio(false)
      }
    } else {
      whatsapp.current.value = 1
    }
  }

  const handleDep = event => {
    setIdDepartamento(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomDepartamento(event.target.options[index].text)
      const pro = JSONPro[0][event.target.value].map((ele, i) => {
        return (
          <option key={i} value={ele.id_ubigeo}>
            {ele.nombre_ubigeo}
          </option>
        )
      })
      setProvincias(pro)
    } else {
      setProvincias("")
      setNomDepartamento("")
      setNomProvincia("")
      setDistritos("")
      setNomDistrito("")
    }
  }
  const handleProv = event => {
    setIdProvincia(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomProvincia(event.target.options[index].text)
      const dis = JSONDis[0][event.target.value].map((ele, i) => {
        return (
          <option key={i} value={ele.id_ubigeo}>
            {ele.nombre_ubigeo}
          </option>
        )
      })
      setDistritos(dis)
    } else {
      setNomProvincia("")
      setNomDistrito("")
      setDistritos("")
    }
  }
  const handleDis = event => {
    setIdDistrito(event.target.value)
    if (event.target.value !== "") {
      let index = event.target.selectedIndex
      setNomDistrito(event.target.options[index].text)
    } else {
      setNomDistrito("")
    }
  }

  const handleWhatsapp = () => {
    whatsapp.current.value = "1"
    btnSubmitRef.current.click()
  }

  return (
    <div className="form-cotizar">
      <h2 className="form-cotizar-titulo font-roboto weight-300">
        Ingrese sus Datos
      </h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data" noValidate>
        <Grid desktop="100" tablet="100" mobile="100">
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input type="hidden" name="_wpcf7" value="835" />
              <input type="hidden" name="proyecto" value={nomProyecto} />
              <input type="hidden" name="tipo-departamento" value={tipoDepa} />
              <input type="hidden" name="cod-inmueble" value={codInmueble} />
              <input type="hidden" name="correo-evolta" value={correoCotiza} />
              <input type="hidden" name="utm_campaign" value={utm_campaign} />
              <input type="hidden" name="utm_content" value={utm_content} />
              <input type="hidden" name="utm_medium" value={utm_medium} />
              <input type="hidden" name="utm_source" value={utm_source} />
              <input type="hidden" name="utm_term" value={utm_term} />
              <input type="hidden" name="whatsapp" value="" ref={whatsapp} />
              <input
                type="text"
                value={tipoDepa}
                placeholder="Tipo de Departamento"
                disabled
              />
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input
                type="text"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                name="nombre"
                placeholder="Nombre"
              />
              {validNombres && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input
                type="text"
                value={apellidos}
                onChange={e => setApellidos(e.target.value)}
                name="apellidos"
                placeholder="Apellidos"
              />
              {validApellidos && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input
                type="tel"
                value={telefono}
                onChange={e => setTelefono(e.target.value)}
                name="telefono"
                placeholder="Teléfono"
              />
              {validTelefono && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                name="email"
                placeholder="Email"
              />
              <input
                type="hidden"
                name="departamento"
                value={nomDepartamento}
              />
              <input type="hidden" name="provincia" value={nomProvincia} />
              <input type="hidden" name="distrito" value={nomDistrito} />
              {validEmail && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </div>
          </Grid>
          <Grid desktop="33" tablet="33" mobile="100">
            <div className="form-control">
              <label>
                <span className="form-control-texto">Departamento</span>
                <select
                  name="id_departamento"
                  value={idDepartamento}
                  onChange={handleDep}
                  onBlur={handleDep}
                >
                  <option value="">Seleccionar</option>
                  {JSONDep &&
                    JSONDep.map((dep, i) => (
                      <option key={i} value={dep.id_ubigeo}>
                        {dep.nombre_ubigeo}
                      </option>
                    ))}
                </select>
                {validIdDepartamento && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </label>
            </div>
          </Grid>
          <Grid desktop="33" tablet="33" mobile="100">
            <div className="form-control">
              <label>
                <span className="form-control-texto">Provincia</span>
                <select
                  name="id_provincia"
                  value={idProvincia}
                  onChange={handleProv}
                  onBlur={handleProv}
                >
                  <option value="">Seleccionar</option>
                  {provincias}
                </select>
                {validIdProvincia && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </label>
            </div>
          </Grid>
          <Grid desktop="33" tablet="33" mobile="100">
            <div className="form-control">
              <label>
                <span className="form-control-texto">Distrito</span>
                <select
                  name="id_distrito"
                  value={idDistrito}
                  onChange={handleDis}
                  onBlur={handleDis}
                >
                  <option value="">Seleccionar</option>
                  {distritos}
                </select>
                {validIdDistrito && (
                  <span className="size-17 color-rojo">
                    El campo es obligatorio.
                  </span>
                )}
              </label>
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="form-control">
              <input
                type="text"
                name="hpionero"
                className="none"
                defaultValue=""
                ref={hpionero}
              />
              <input
                type="text"
                name="hpionero2"
                defaultValue="apros"
                style={{ display: "none" }}
                ref={hpionero2}
              />
              <label className="form-aceptar">
                <input type="checkbox" onChange={e => setAceptar(!aceptar)} />
                <span>
                  He leído y acepto todos los{" "}
                  <Link
                    to="/terminos-y-condiciones/"
                    className="color-negro weight-bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    términos y condiciones
                  </Link>
                  , y la{" "}
                  <Link
                    to="/politica-privacidad/"
                    className="color-negro weight-bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    política de privacidad
                  </Link>{" "}
                  del Grupo Pionero Inmobiliaria
                </span>
              </label>
              {validAceptar && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </div>
          </Grid>
          <Grid desktop="100" tablet="100" mobile="100">
            <div className="group-form-buttons text-center">
              <Button
                size="small"
                eventEle={handleWhatsapp}
                classEle="font-roboto form-submit bg-negro color-pagina"
                disabled={envio}
              >
                <svg className="form-icon" viewBox="0 0 70.03 70.2">
                  <path
                    fill="none"
                    stroke="var(--color-pagina)"
                    strokeMiterlimit="10"
                    d="M.71,69.5,5.66,51a34.14,34.14,0,1,1,13.6,13.49Z"
                  />
                  <path
                    fill="var(--color-pagina)"
                    fillRule="evenodd"
                    d="M48.08,39.65c-1.43-.85-3.28-1.8-5-1.12-1.28.53-2.11,2.54-2.94,3.58a1.23,1.23,0,0,1-1.6.34,22.45,22.45,0,0,1-11.21-9.6A1.38,1.38,0,0,1,27.55,31a7.63,7.63,0,0,0,2-3.3,7.17,7.17,0,0,0-.91-3.91c-.67-1.46-1.43-3.54-2.89-4.36a3.94,3.94,0,0,0-4.3.64,8.68,8.68,0,0,0-3,6.91,9.6,9.6,0,0,0,.27,2.2A18.61,18.61,0,0,0,20.83,34c.68,1.15,1.41,2.26,2.2,3.34a33.79,33.79,0,0,0,9.53,8.85A29,29,0,0,0,38.51,49c2.33.76,4.4,1.57,6.91,1.08a8.37,8.37,0,0,0,6.28-4.66A4.15,4.15,0,0,0,52,43.06c-.36-1.64-2.58-2.62-3.91-3.41"
                  />
                  <path
                    fill="none"
                    stroke="var(--color-pagina)"
                    strokeMiterlimit="10"
                    d="M.71,69.5,5.66,51a34.14,34.14,0,1,1,13.6,13.49Z"
                  />
                </svg>
                WHATSAPP
              </Button>
              <Button
                size="small"
                refEle={btnSubmitRef}
                type="submit"
                classEle="font-roboto bg-negro color-pagina"
                disabled={envio}
              >
                ENVIAR COTIZACIÓN
              </Button>
            </div>
            {envio === false && envio !== "" && (
              <div className="font-roboto line-heihgt-normal">
                Error en el envio
              </div>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default FormCotizar
