import React from "react"
import "./ZonasHomePet.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GatsbyImage } from "gatsby-plugin-image"
import SoloTexto from "../../utils/SoloTexto"
import GenerarAlt from "../../utils/GenerarAlt"

const ZonasHomePet = ({ titulo, parrafo, slider, info, configuracion }) => {
  return (
    <section className="zonas-seccion">
      <h3 className="none">{SoloTexto(titulo)}</h3>
      <div className="zonas-contenido mwx-1680 mx-auto">
        <div className="zonas-texto">
          <h3
            className="zonas-titulo size-44 font-roboto weight-300 mt-0 mb-0"
            dangerouslySetInnerHTML={{ __html: titulo }}
          />
          <div
            className="zonas-parrafo size-21 font-lato weight-300 mt-0 mb-0"
            dangerouslySetInnerHTML={{ __html: parrafo }}
          />
        </div>
        <Slider
          {...configuracion}
          className="banner-slider zonas-banner-slider"
        >
          {slider.map((imagen, i) => (
            <GatsbyImage
              key={i}
              className="zonas-imagen"
              image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
              alt={GenerarAlt(imagen)}
            />
          ))}
        </Slider>
        <div
          className="zonas-info font-roboto weight-300 size-39 mb-0 parrafo-0"
          dangerouslySetInnerHTML={{ __html: info }}
        />
      </div>
    </section>
  )
}

export default ZonasHomePet
