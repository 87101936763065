import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import Slider from "react-slick"
import Button from "../atoms/Button/Button";
import ImagenSliderRodeate from "./ImagenSliderRodeate"

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 'none',
    overflow: 'initial',
  }
};

if (typeof(window) !== 'undefined') {
  ReactModal.setAppElement('body')
}

const BotonGaleriaProyecto = ({boton, galeriaObra, settings, parrafoAvance}) => {

  const {texto, idGa} = boton;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const modalOpen = localStorage.getItem('modal-open');
    if(modalOpen === "true") setIsModalOpen(true);
    localStorage.setItem('modal-open', "false");
  }, [])

  return(
    <>
      <Button classEle="uppercase boton-hover rodeate-boton bg-negro color-pagina size-25 font-lato weight-300" id={idGa} eventEle={()=>setIsModalOpen(true)}>{texto}</Button>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal"
        style={customStyles}
      >
        { galeriaObra ?
          <div className="rodeate-slider bg-negro">
            <Slider {...settings} className="rodeate-slider1">
              {
                galeriaObra.map((imagen,i)=>(
                  <ImagenSliderRodeate key={i} imagen={imagen} titulo={imagen.titulo} />
                ))
              }
            </Slider>
            <div style={{backgroundColor: "var(--color-pagina)"}} className="text-galeria-obra">
              <div dangerouslySetInnerHTML={{__html: parrafoAvance}} />
            </div>
          </div>
          : <h3 className="text-center">Sin galeria</h3>
        }
        <button onClick={handleModalClose} className="form-cerrar">x</button>
      </ReactModal>
    </>
  )
}
export default BotonGaleriaProyecto;
