import React from 'react'
import './Contenido.scss'

const Contenido = ({contenido}) => {
  if((typeof contenido) === 'object'){
    return (
      <section className="seccion-contenido">
        {
          Object.values(contenido).map((parrafo,i)=>(
            <div key={i} className="contenido mwx-1271 mx-auto font-lato size-22" dangerouslySetInnerHTML={{__html: parrafo.parrafo}} />
          ))
        }
      </section>
    )
  }else{
    return (
      <section className="seccion-contenido">
        <div className="contenido mwx-1271 mx-auto font-lato size-22" dangerouslySetInnerHTML={{__html: contenido}} />
      </section>
    )
  }
}

export default Contenido
