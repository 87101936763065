import React from "react";
import "./IconEstado.scss";

const IconEstadoProyecto = ({text,classEle, ...others}) =>{

    return(
        <div className={`icon-estado-proyecto bg-negro ${classEle}`} {...others}>
            <div className="icon-estado-proyecto-texto relative">{text}</div>
        </div>
    )
}

export default IconEstadoProyecto