import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {navigate} from 'gatsby'

const UniversalLink = ({ children, to, activeClassName, partiallyActive, menuItem, ...other}) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <>
      {
        menuItem.id === "cG9zdDoxMTIy" ?
        <a href={to} {...other}
        onClick={
          (e)=>{
            e.preventDefault()
            navigate("/#momento");
            const btnClose = document.querySelector(".header-btn-icon");
            const tabs = document.querySelectorAll(".react-tabs__tab");
            const momento = document.querySelector("#momento");
            if(tabs && btnClose && momento){
              const offsetTop = momento.offsetTop - 70;
              btnClose.click()
              // tabs[0].click()
              window.scroll({
                top: offsetTop,
                behavior: "smooth"
              });
            }
          }
        }
        >
          {children}
        </a>
        : (menuItem.id === "cG9zdDoxMTIz") ?
          <a href={to} {...other}
          onClick={
            (e)=>{
              e.preventDefault()
              navigate("/#barrio");
              const btnClose = document.querySelector(".header-btn-icon");
              const tabs = document.querySelectorAll(".react-tabs__tab");
              const barrio = document.querySelector("#barrio");
              if(tabs && btnClose && barrio){
                const offsetTop = barrio.offsetTop - 70;
                btnClose.click()
                // tabs[1].click()
                window.scroll({
                  top: offsetTop,
                  behavior: "smooth"
                });
              }
            }
          }
          >
            {children}
          </a>
        : (menuItem.id === "cG9zdDo2OTc=") ?
          <a href={to} {...other}
          onClick={
            (e)=>{
              e.preventDefault()
              e.target.parentElement.parentElement.classList.toggle("active")
            }
          }
          >
            {children}
          </a>:(menuItem.id === "cG9zdDo2OTg=") ?
          <a href={to} {...other}
          onClick={
            (e)=>{
              e.preventDefault()
              e.target.parentElement.parentElement.classList.toggle("active")
            }
          }
          >
            {children}
          </a>:
        <a href={to} {...other}>
          {children}
        </a>
      }
    </>
  )
}
export default UniversalLink