import React from "react"
import "./ImagenesProyecto.scss"
import SliderProyectoFancybox from "../organisms/Slider/SliderProyectoFancybox"

const ImagenesProyecto = ({
  galeria,
  tituloPage,
  titulo,
  classTitulo = "",
  arrow = true,
}) => {
  return (
    <section className="galeria-seccion">
      <h3 className="none">Galería {tituloPage}</h3>
      <div className="mwx-1320 mx-auto grid100-p-0 grid50-p-0">
        {titulo && (
          <div
            className={`slider-proyecto-text-container font-roboto ${classTitulo}`}
          >
            <div className="text-center">{titulo}</div>
          </div>
        )}
        <SliderProyectoFancybox
          data={galeria}
          nombre="Galeria"
          classTitulo={classTitulo}
          arrow={arrow}
        />
      </div>
    </section>
  )
}

export default ImagenesProyecto
