import React from "react"
import "./ContactoProyecto.scss"

const ContactoProyecto = ({ titulo, parrafo, telefonos, celularIdTag }) => {
  return (
    <section className="contactoproyecto-seccion bg-negro">
      <div className="contactoproyecto-contenido mx-auto mwx-1680">
        {titulo && (
          <h3 className="contactoproyecto-titulo font-roboto color-pagina weight-300 mt-0 mb-0">
            {titulo}
          </h3>
        )}
        {parrafo && (
          <div
            className="contactoproyecto-parrafo font-roboto weight-300 color-blanco mt-0 mb-0"
            dangerouslySetInnerHTML={{ __html: parrafo }}
          />
        )}
        <div className="contactoproyecto-contactos">
          {telefonos &&
            telefonos.map((telefono, i) => (
              <div key={i} className="contactoproyecto-contacto">
                {telefono?.telefono && (
                  <a
                    className={`contactoproyecto-telefono font-roboto weight-bold color-blanco mt-0 mb-0 ${celularIdTag}`}
                    href={`tel:051${telefono.telefono}`}
                  >
                    {telefono?.telefono}
                  </a>
                )}
                {telefono?.nombre && (
                  <h3 className="contactoproyecto-nombre font-roboto weight-300 color-blanco mt-0 mb-0">
                    {telefono?.nombre}
                  </h3>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default ContactoProyecto
