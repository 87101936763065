import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import MenuItem from "../Menu/MenuItem"

import "./Header.scss"
import { useEffect } from "react"

const HEADER_QUERY = graphql`
  {
    pionero {
      page(id: "675", idType: DATABASE_ID) {
        general {
          links {
            texto
            tipo
            valor
          }
          redes {
            texto
            tipo
            valor
          }
          logoIdTag
          whatsappIdTag
          botonMenuIdTag
        }
      }
      menuItems(where: { parentId: "", location: HCMS_MENU_HEADER }) {
        nodes {
          id
          label
          url
          title
          target
          cssClasses
          childItems(before: "") {
            edges {
              node {
                id
                label
                url
                title
                target

                connectedNode {
                  node {
                    ... on Pionero_Proyecto {
                      id
                      estados {
                        nodes {
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`
const Header = ({ isProyecto }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  useEffect(() => {
    const itemDepa = document.querySelector(".header-item-depas")
    // console.log(itemDepa)
    if (itemDepa) {
      itemDepa.addEventListener("click", function (e) {
        const sectionDepa = document.querySelector("#depa")
        if (sectionDepa) {
          e.preventDefault()
          const offsetTop = sectionDepa.offsetTop - 30
          window.scroll({
            top: offsetTop,
            behavior: "smooth",
          })
          setNavbarOpen(false)
        }
      })
    }
  }, [])

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", () => {
      const header = document.querySelector("body")
      if (window.scrollY > 20) header.classList.add("isScroll")
      else header.classList.remove("isScroll")
    })

  const handleAbrirCerrarMenu = () => {
    setNavbarOpen(!navbarOpen)
  }

  // const handleTarget = (e) =>{
  //   e.preventDefault();
  //   const offsetTop = document.querySelector("#cotizar").offsetTop - 70;

  //   window.scroll({
  //     top: offsetTop,
  //     behavior: "smooth"
  //   });
  // }

  return (
    <StaticQuery
      query={HEADER_QUERY}
      render={data => {
        const menuItems = data.pionero.menuItems.nodes
        const wordPressUrl = data.pionero.generalSettings.url
        const general = data?.pionero?.page?.general
        const links = general.links
        const redes = general.redes

        return (
          <header className={`header header-${navbarOpen}`}>
            <div className="header-contenido mx-auto mwx-1680">
              <Link to="/" className={general?.logoIdTag} aria-label="Logo web">
                <svg
                  className="header-logo-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1695.08 233.01"
                >
                  <polygon
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    points="167.63 107.63 223.46 107.63 223.46 203.12 167.63 203.12 167.63 231.96 320.71 231.96 320.71 203.12 261.12 203.12 261.12 107.63 320.71 107.63 320.71 78.78 167.63 78.78 167.63 107.63"
                  />
                  <path
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    d="M114.55,126.94c0,7.35-2.25,12.4-6.76,15.25S96.4,146.4,87.27,146.4H37.45V107.47H87.27a57.15,57.15,0,0,1,15,1.61,17.06,17.06,0,0,1,9.1,5.87c2.12,2.75,3.19,6.69,3.19,11.75Zm21.57-37.42Q119.26,78.76,89,78.79H0V232H37.45V174.43H88.67q30.37,0,47.33-11t17-37.1v-.19q0-25.87-16.85-36.64"
                  />
                  <path
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    d="M465.15,191.14q12.57-13,12.55-35.46V155q0-22.45-12.55-35.44t-40.92-13q-28.37,0-40.93,13T370.74,155v.65q0,22.46,12.56,35.46t40.93,13q28.36,0,40.92-13m-90.93,32.61q-20.46-9.2-30.89-26.58t-10.4-41.49V155q0-24.14,10.4-41.49T374.21,87q20.5-9.27,50-9.26T474.18,87q20.44,9.24,30.84,26.56T515.41,155v.65q0,24.14-10.4,41.49t-30.84,26.58Q453.75,233,424.23,233t-50-9.27"
                  />
                  <path
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    d="M1564.63,191.14q12.54-13,12.56-35.46V155q0-22.45-12.56-35.44t-40.92-13q-28.38,0-40.93,13T1470.21,155v.65q0,22.46,12.57,35.46t40.93,13q28.35,0,40.92-13m-90.94,32.61q-20.49-9.2-30.9-26.58t-10.39-41.49V155q0-24.14,10.39-41.49T1473.69,87q20.5-9.27,50-9.26T1573.66,87q20.44,9.24,30.84,26.56T1614.88,155v.65q0,24.14-10.39,41.49t-30.84,26.58q-20.42,9.26-49.95,9.27t-50-9.27"
                  />
                  <polygon
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    points="1014.87 78.78 1014.87 196.97 575.43 78.78 536.96 78.78 536.96 231.95 574.35 231.95 574.35 114.4 1014.32 231.95 1052.27 231.95 1052.27 78.78 1014.87 78.78"
                  />
                  <polygon
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    points="1081.47 231.86 1234.56 231.86 1234.56 202.37 1118.62 202.37 1118.62 168.85 1225.41 168.85 1225.41 140.42 1118.62 140.42 1118.62 108.25 1234.56 108.25 1234.56 78.73 1081.47 78.73 1081.47 231.86"
                  />
                  <path
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    d="M1369.36,137.79c-4.06,2.41-10.22,3.65-18.48,3.65h-51V107.7h48.91a67.3,67.3,0,0,1,14.72,1.3,15.87,15.87,0,0,1,8.79,4.95c2.08,2.46,3.1,6,3.1,10.57,0,6.46-2,10.89-6.07,13.27m21.27,15.79c7.32-2.47,13-6.45,17-12.12s6-12.95,6-21.93q0-22.66-16-31.84t-44.82-9.07H1262.2V231.81h37.7V169.4H1352c8.82,0,15.32,1.81,19.5,5.35s6.23,9.24,6.23,17v40h37.58V189.57c0-9.54-2.12-17.36-6.39-23.38s-10.34-10.25-18.26-12.62"
                  />
                  <path
                    fill="var(--color-texto)"
                    className="header-logo-texto"
                    stroke="var(--color-texto)"
                    strokeMiterlimit="10"
                    d="M1694.58,24.61c0,14.44-9.67,24.11-24.11,24.11s-24.12-9.67-24.12-24.11S1656,.5,1670.47.5,1694.58,10.17,1694.58,24.61Zm-3.56,0C1691,12.23,1682.85,4,1670.47,4S1650,12.23,1650,24.61s8.18,20.63,20.49,20.63S1691,37,1691,24.61ZM1675.23,28,1682,37.7h-5.19l-6.12-9.39h-5.48V37.7h-4.48V11.38h11c6.19,0,9.89,3.13,9.89,8.53A7.72,7.72,0,0,1,1675.23,28Zm-4.12-13h-5.9v9.67h5.9c3.91,0,5.83-1.56,5.83-4.84S1675,14.94,1671.11,14.94Z"
                  />
                </svg>
              </Link>
              <div className="header-btns">
                {/* {
                  isProyecto &&
                  <a className="header-btn-cotizar bg-negro color-pagina font-lato weight-bold size-19" href="#cotizar" onClick={handleTarget}>COTIZAR</a>
                } */}
                {links[0] && (
                  <a
                    href={links[0].valor}
                    target="_blank"
                    className={`header-icon-menu header-btn-wsp ${general?.whatsappIdTag}`}
                    rel="noreferrer"
                    aria-label="boton whatsapp"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 70.03 70.2"
                    >
                      <path
                        fill="none"
                        stroke="var(--color-texto)"
                        strokeMiterlimit="10"
                        d="M.71,69.5,5.66,51a34.14,34.14,0,1,1,13.6,13.49Z"
                      />
                      <path
                        fill="var(--color-texto)"
                        fillRule="evenodd"
                        d="M48.08,39.65c-1.43-.85-3.28-1.8-5-1.12-1.28.53-2.11,2.54-2.94,3.58a1.23,1.23,0,0,1-1.6.34,22.45,22.45,0,0,1-11.21-9.6A1.38,1.38,0,0,1,27.55,31a7.63,7.63,0,0,0,2-3.3,7.17,7.17,0,0,0-.91-3.91c-.67-1.46-1.43-3.54-2.89-4.36a3.94,3.94,0,0,0-4.3.64,8.68,8.68,0,0,0-3,6.91,9.6,9.6,0,0,0,.27,2.2A18.61,18.61,0,0,0,20.83,34c.68,1.15,1.41,2.26,2.2,3.34a33.79,33.79,0,0,0,9.53,8.85A29,29,0,0,0,38.51,49c2.33.76,4.4,1.57,6.91,1.08a8.37,8.37,0,0,0,6.28-4.66A4.15,4.15,0,0,0,52,43.06c-.36-1.64-2.58-2.62-3.91-3.41"
                      />
                      <path
                        fill="none"
                        stroke="var(--color-texto)"
                        strokeMiterlimit="10"
                        d="M.71,69.5,5.66,51a34.14,34.14,0,1,1,13.6,13.49Z"
                      />
                    </svg>
                  </a>
                )}

                <button
                  aria-label="boton menu"
                  onClick={handleAbrirCerrarMenu}
                  className={`header-btn-icon ${general?.botonMenuIdTag}`}
                  type="button"
                >
                  <svg
                    className="header-icon-menu"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 70.36 70.36"
                  >
                    <circle
                      className="header-icon-menu-1"
                      stroke="var(--color-texto)"
                      cx="35.18"
                      cy="35.18"
                      r="32"
                    />
                    <line
                      className="header-icon-menu-2"
                      stroke="var(--color-texto)"
                      x1="14.17"
                      y1="23.87"
                      x2="56.19"
                      y2="23.87"
                    />
                    <line
                      className="header-icon-menu-2"
                      stroke="var(--color-texto)"
                      x1="56.19"
                      y1="47.3"
                      x2="14.17"
                      y2="47.3"
                    />
                    <line
                      className="header-icon-menu-2"
                      stroke="var(--color-texto)"
                      x1="19.42"
                      y1="35.18"
                      x2="50.94"
                      y2="35.18"
                    />
                  </svg>
                  <svg
                    className="header-icon-close"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39 39"
                  >
                    <line
                      stroke="var(--color-pagina)"
                      className="header-icon-close-1"
                      x1="38.65"
                      y1="0.35"
                      x2="0.35"
                      y2="38.65"
                    />
                    <line
                      stroke="var(--color-pagina)"
                      className="header-icon-close-1"
                      x1="0.35"
                      y1="0.35"
                      x2="38.65"
                      y2="38.65"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className={`header-modal bg-negro relative`}>
              <nav className="header-modal-menu">
                <ul className="header-menu-lista mx-auto mwx-1680">
                  {menuItems &&
                    menuItems.map(menuItem => (
                      <MenuItem
                        key={menuItem.id}
                        menuItem={menuItem}
                        wordPressUrl={wordPressUrl}
                      />
                    ))}
                </ul>
              </nav>
              <div className="header-menu-contactos mx-auto mwx-1680">
                {links &&
                  links.map((link, i) => (
                    <span key={i}>
                      {link.tipo === "email" && (
                        <a
                          href={
                            link.tipo === "email"
                              ? `mailto:${link.valor}`
                              : link.valor
                          }
                          target={link.tipo === "whatsapp" ? "_blank" : "_self"}
                          className="header-menu-contacto color-pagina"
                          rel="noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`header-menu-imagen-${link.tipo}`}
                            viewBox="0 0 31.93 20.44"
                          >
                            <g id="Capa_2" data-name="Capa 2">
                              <g id="Capa_1-2" data-name="Capa 1">
                                <rect
                                  className="cls-1"
                                  fill="none"
                                  stroke="var(--color-pagina)"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.29px"
                                  x="0.65"
                                  y="0.65"
                                  width="30.64"
                                  height="19.15"
                                />
                                <polyline
                                  className="cls-1"
                                  fill="none"
                                  stroke="var(--color-pagina)"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.29px"
                                  points="31.28 0.65 15.96 10.22 0.65 0.65"
                                />
                              </g>
                            </g>
                          </svg>
                          <span className="header-menu-texto size-27 color-pagina font-lato weight-400">
                            {link.texto}
                          </span>
                        </a>
                      )}
                    </span>
                    // <a
                    //   key={i}
                    //   href={(link.tipo === "email") ? `mailto:${link.valor}` : link.valor}
                    //   target={(link.tipo === "whatsapp") ? "_blank": "_self"}
                    //   className="header-menu-contacto color-pagina"
                    //   rel="noreferrer"
                    // >
                    //   {
                    //     (link.tipo === "email") &&
                    //     <>
                    //       <svg xmlns="http://www.w3.org/2000/svg" className={`header-menu-imagen-${link.tipo}`} viewBox="0 0 31.93 20.44"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><rect className="cls-1" fill="none" stroke="var(--color-pagina)" strokeMiterlimit="10" strokeWidth="1.29px" x="0.65" y="0.65" width="30.64" height="19.15"/><polyline className="cls-1" fill="none" stroke="var(--color-pagina)" strokeMiterlimit="10" strokeWidth="1.29px" points="31.28 0.65 15.96 10.22 0.65 0.65"/></g></g></svg>
                    //       <span className="header-menu-texto size-27 color-pagina font-lato weight-400">{link.texto}</span>
                    //     </>
                    //   }
                    //   {
                    //     (link.tipo === "whatsapp") &&
                    //       <svg xmlns="http://www.w3.org/2000/svg" className={`header-menu-imagen-${link.tipo}`} viewBox="0 0 60.2 60.82"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path className="cls-1" fill="none" stroke="var(--color-pagina)" strokeMiterlimit="10" strokeWidth="2.42px" d="M30.1,59a28.78,28.78,0,0,1-14.41-3.88L4.44,59,6.75,47.07a28.56,28.56,0,0,1-5.54-17A28.89,28.89,0,1,1,30.1,59Z"/><path className="cls-2" fill="var(--color-pagina)" d="M15.52,19s1.69-3,3.08-3.13,3.16-.17,3.64.73,2.62,6.15,2.62,6.15a2.06,2.06,0,0,1-.2,1.78,17.61,17.61,0,0,1-1.83,2.14,1.57,1.57,0,0,0,0,1.86,33.92,33.92,0,0,0,4.07,5c2.26,2.26,6.6,3.88,6.6,3.88a1.33,1.33,0,0,0,1-.31c.39-.4,2.54-3.08,2.54-3.08a1.5,1.5,0,0,1,1.83-.37c1.14.52,6.1,3,6.1,3a1.07,1.07,0,0,1,.58,1.09c0,.88-.35,3-1.08,3.74a8.62,8.62,0,0,1-6,3c-3.18,0-10.75-2.59-14.79-6.62s-7.62-8.13-8.47-11.85S14.47,20.54,15.52,19Z"/></g></g></svg>
                    //   }
                    // </a>
                  ))}
                <div className="header-menu-redes color-pagina">
                  {redes &&
                    redes.map((red, i) => (
                      <a
                        key={i}
                        href={red.valor}
                        target="_blank"
                        className="header-menu-red"
                        rel="noreferrer"
                        aria-label={`Red social ${red.tipo}`}
                      >
                        {red.tipo === "facebook" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="header-menu-imagen"
                            viewBox="0 0 34.34 34.34"
                          >
                            <g id="Capa_2" data-name="Capa 2">
                              <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                  className="cls-1"
                                  fill="var(--color-pagina)"
                                  d="M22.24,33.66V21.75h4l.6-4.64h-4.6v-3c0-1.34.37-2.26,2.3-2.26H27V7.73a33,33,0,0,0-3.58-.18c-3.54,0-6,2.16-6,6.14v3.42h-4v4.64h4V33.66Z"
                                />
                                <rect
                                  className="cls-2"
                                  fill="none"
                                  stroke="var(--color-pagina)"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.33px"
                                  x="0.67"
                                  y="0.67"
                                  width="33.01"
                                  height="33.01"
                                  rx="6.87"
                                  ry="6.87"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                        {red.tipo === "tiktok" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30.24 30.24"
                            className="header-menu-imagen"
                          >
                            <rect
                              fill="none"
                              stroke="var(--color-pagina)"
                              strokeMiterlimit="10"
                              x="0.5"
                              y="0.5"
                              width="29.24"
                              height="29.24"
                              rx="7.65"
                            />
                            <path
                              fill="var(--color-pagina)"
                              d="M22.7,13.44l-.45,0a4.89,4.89,0,0,1-4.09-2.21v7.52a5.56,5.56,0,1,1-5.56-5.56l.35,0V16c-.12,0-.23,0-.35,0a2.84,2.84,0,1,0,0,5.67,2.9,2.9,0,0,0,3-2.8L15.58,6H18.2a4.89,4.89,0,0,0,4.5,4.36v3"
                            />
                          </svg>
                        )}
                        {red.tipo === "instagram" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="header-menu-imagen"
                            viewBox="0 0 35.88 34.31"
                          >
                            <g id="Capa_2" data-name="Capa 2">
                              <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                  className="cls-1"
                                  fill="none"
                                  stroke="var(--color-pagina)"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.33px"
                                  d="M24.56,33.64H11.32A10.67,10.67,0,0,1,.67,23V11.32A10.67,10.67,0,0,1,11.32.67H24.56A10.67,10.67,0,0,1,35.22,11.32V23A10.67,10.67,0,0,1,24.56,33.64Z"
                                />
                                <path
                                  className="cls-2"
                                  fill="var(--color-pagina)"
                                  d="M17.94,26.64a9.49,9.49,0,1,1,9.49-9.49A9.5,9.5,0,0,1,17.94,26.64Zm0-16.6a7.12,7.12,0,1,0,7.12,7.12A7.12,7.12,0,0,0,17.94,10Z"
                                />
                                <circle
                                  className="cls-2"
                                  fill="var(--color-pagina)"
                                  cx="27.28"
                                  cy="7.96"
                                  r="1.97"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                        {red.tipo === "linkedin" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="header-menu-imagen"
                            viewBox="0 0 34.34 34.34"
                          >
                            <g id="Capa_2" data-name="Capa 2">
                              <g id="Capa_1-2" data-name="Capa 1">
                                <rect
                                  className="cls-1"
                                  fill="none"
                                  stroke="var(--color-pagina)"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.33px"
                                  x="0.67"
                                  y="0.67"
                                  width="33.01"
                                  height="33.01"
                                  rx="6.87"
                                  ry="6.87"
                                />
                                <path
                                  className="cls-2"
                                  fill="var(--color-pagina)"
                                  d="M8.25,14.15h4V27h-4Zm2-6.37a2.31,2.31,0,1,1-2.31,2.31,2.31,2.31,0,0,1,2.31-2.31"
                                />
                                <path
                                  className="cls-2"
                                  fill="var(--color-pagina)"
                                  d="M14.75,14.15h3.82V15.9h.05a4.19,4.19,0,0,1,3.78-2.07c4,0,4.78,2.65,4.78,6.11v7h-4V20.74c0-1.49,0-3.4-2.07-3.4S18.73,19,18.73,20.63V27h-4Z"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                        {red.tipo === "youtube" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="header-menu-imagen"
                            viewBox="0 0 46.54 33.27"
                          >
                            <g id="Capa_2" data-name="Capa 2">
                              <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                  className="cls-1"
                                  fill="var(--color-pagina)"
                                  d="M20.36,33.27l-2.4,0-1.6,0c-1.69,0-3.45,0-5.17-.15a22.12,22.12,0,0,1-6.68-1.17C.42,30.26.23,25.47.09,22,0,19.39,0,16.83,0,14c0-.32,0-.65,0-1,0-2.77,0-5.9,1.19-8.47C2.74,1.23,6.06.7,9.33.44c2.52-.2,5.07-.26,7.54-.31,4.8-.11,9.76-.22,14.67,0,2.61.1,5,.27,7.25.53,1.94.22,4.12.6,5.55,2.33s1.86,4.39,2.06,7c0,.52.07,1.06.09,1.64,0,1.3,0,2.61,0,3.89,0,.59,0,1.18,0,1.77,0,.41,0,.83,0,1.24A43.28,43.28,0,0,1,46.25,25c-.41,2.83-1.22,5.86-4.16,6.86a31.6,31.6,0,0,1-9.67,1.2h-.27l-3.21.07C26.12,33.2,23.24,33.27,20.36,33.27ZM25.76,1.16c-3,0-5.94.07-8.85.13-2.46.05-5,.11-7.48.31-3.05.24-5.9.69-7.17,3.43-1.09,2.34-1.09,5.34-1.08,8,0,.33,0,.66,0,1,0,2.83,0,5.38.08,7.94.13,3.36.3,7.55,3.7,8.92a21.24,21.24,0,0,0,6.32,1.08c1.7.11,3.43.13,5.11.14l1.61,0c3.64.05,7.34,0,10.92-.11l3.21-.07h.27a30.73,30.73,0,0,0,9.32-1.14c2.35-.8,3-3.33,3.39-5.93a42.45,42.45,0,0,0,.27-6.31c0-.42,0-.83,0-1.24,0-.59,0-1.19,0-1.78,0-1.26,0-2.56,0-3.84h0c0-.56,0-1.08-.09-1.59-.19-2.44-.47-4.76-1.8-6.37-1.16-1.4-3-1.71-4.79-1.92-2.24-.25-4.59-.42-7.17-.52C29.6,1.18,27.68,1.16,25.76,1.16ZM18.22,9.49V23.68l12.08-7.1Z"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </header>
        )
      }}
    />
  )
}

export default Header
