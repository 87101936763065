import React from 'react'

const FormGracias = ({titulo,parrafo}) => {
  return (
    <div className="form-gracias">
      <svg xmlns="http://www.w3.org/2000/svg" className="form-gracias-icono block mx-auto" viewBox="0 0 185.95 164.48"><path fill="#2d2d2d" d="M180.61,90c9.42-9.38,6.51-28.35-9.81-28.35l-42.93,0c1.63-9.09,4-24.16,3.9-25.58-.9-13.48-9.49-29.88-9.86-30.55-1.57-2.92-9.48-6.87-17.44-5.18C94.18,2.61,93.13,9.18,93.17,11c0,0,.44,18,.49,22.75-4.92,10.81-21.89,39.21-27,41.4A7.84,7.84,0,0,0,62.55,74H8.31A8.3,8.3,0,0,0,0,82.31v75a7.88,7.88,0,0,0,7.81,7.21H56.54a7.84,7.84,0,0,0,7.83-7.83v-2.49a5.6,5.6,0,0,1,2.63.39c3.13,2,7,4.49,12.05,4.49h72.7c27.18,0,24.26-24.13,21.78-27.42,4.59-5,7.43-13.82,3.56-20.79C180.06,107.69,185.3,99,180.61,90ZM54.86,156.63H7.8V81.82H54.86ZM167.93,89.39l-.44,1.85c12.52,3.56,5.86,18-3.13,18.93l-.44,1.85c12,3.05,6.28,17.92-3.13,18.93l-.44,1.84c9.81,1.61,7.43,18.35-7.43,18.35l-73.95,0c-5.22,0-10-6-13.82-6H61.81V87.65A117.93,117.93,0,0,0,74,79.27c5.39-4.6,27.47-40.27,27.47-44.73S101,10.76,101,10.76s4.33-5.58,14-1.51c0,0,8.13,15.25,9,27.4,0,0-3.66,25-5.63,32.89h51.26C182.14,69.56,179.73,87.54,167.93,89.39Z"/></svg>
      <div className="form-gracias-titulo text-center size-30 font-roboto weight-400 parrafo-0" dangerouslySetInnerHTML={{__html: titulo}} />
      <div className="form-gracias-parrafo text-center size-18 font-roboto weight-300 mx-auto parrafo-0" dangerouslySetInnerHTML={{__html: parrafo}} />
    </div>
  )
}

export default FormGracias