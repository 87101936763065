import React  from "react"
import Layout from "../../components/Layout/Layout"
import { graphql, navigate } from 'gatsby'
import BannerProyecto from "../../components/Proyecto/BannerProyecto"
import RodeateProyecto from "../../components/Proyecto/RodeateProyecto"
import ImagenesProyecto from "../../components/Proyecto/ImagenesProyecto"
import UbicacionProyecto from "../../components/Proyecto/UbicacionProyecto"
import OtrosProyecto from "../../components/Proyecto/OtrosProyecto"
import ContactoProyecto from "../../components/Proyecto/ContactoProyecto"
import CotizarProyecto from "../../components/Proyecto/CotizarProyecto"
import Seo from "gatsby-plugin-wpgraphql-seo"
import ObtenerUri from "../../utils/ObtenerUri"
import IframeProyectoEnVenta from "../../components/organisms/Iframe/IframeProyectoEnVenta"

export const query = graphql`
    query($id: ID!) {
        pionero {
            page(id: $id, idType: DATABASE_ID) {
                databaseId
                title
                slug
                uri
                seo {
                    title
                    metaDesc
                    focuskw
                    metaKeywords
                    metaRobotsNoindex
                    metaRobotsNofollow
                    opengraphTitle
                    opengraphDescription
                    opengraphImage {
                        altText
                        sourceUrl
                        srcSet
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        altText
                        sourceUrl
                        srcSet
                    }
                    canonical
                    cornerstone
                    schema {
                        articleType
                        pageType
                        raw
                    }
                }
                proyecto {
                    tituloGracias
                    parrafoGracias
                    proyectoGracias {
                        ... on Pionero_Proyecto {
                            barrios {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                            estados {
                                edges {
                                    node {
                                        name
                                        slug
                                    }
                                }
                            }
                            proyecto {
                                brochure {
                                  mediaItemUrl
                                }
                                idGaBrochure
                                bannerTitulo
                                bannerTextoSecundario
                                bannerColorFondo
                                bannerInfo
                                bannerImagenProyecto {
                                  altText
                                  title
                                  srcSet
                                  sourceUrl
                                  sourceUrlSharp {
                                    childImageSharp {
                                      gatsbyImageData(
                                        formats: [AUTO, WEBP, AVIF]
                                        placeholder: NONE
                                      )
                                    }
                                  }
                                }
                                tituloIframe
                                urlIframeMultimedia
                                bannerResponsiveHomeProyecto{
                                  altText
                                  title
                                  srcSet
                                  sourceUrl
                                  sourceUrlSharp {
                                    childImageSharp {
                                      gatsbyImageData(
                                        formats: [AUTO, WEBP, AVIF]
                                        placeholder: NONE
                                        quality: 100
                                      )
                                    }
                                  }
                                }
                                bannerDetallle {
                                  texto
                                  imagen {
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                tituloRodeateDe
                                parrafoRodeateDe
                                parrafoParaGaleriaAvanceDeObra
                                numeroColumnasRodeateDe
                                anchoColumnasRodeateDe
                                botonesRodeateDe {
                                  tipo
                                  texto
                                  link
                                  idGa
                                  ocultar
                                }
                                galeriaAvanceRodeateDe {
                                  titulo
                                  imagen{
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                detalleRodeateDe {
                                  texto
                                  imagen {
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                esHomePet
                                imagenPremioRodeateDe {
                                  altText
                                  title
                                  srcSet
                                  sourceUrl
                                  sourceUrlSharp {
                                    childImageSharp {
                                      gatsbyImageData(
                                        formats: [AUTO, WEBP, AVIF]
                                        placeholder: NONE
                                      )
                                    }
                                  }
                                }
                                parrafoPremioRodeateDe
                                galeriaImagenes {
                                  ancho
                                  texto
                                  textoSecundario
                                  imagen {
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                sliderImagenes {
                                  texto
                                  textoSecundario
                                  imagen{
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                direccionMapa
                                imagenMapa {
                                  altText
                                  title
                                  srcSet
                                  sourceUrl
                                  sourceUrlSharp {
                                    childImageSharp {
                                      gatsbyImageData(
                                        formats: [AUTO, WEBP, AVIF]
                                        placeholder: NONE
                                      )
                                    }
                                  }
                                }
                                botonesMapa {
                                  link
                                  imagen {
                                    altText
                                    title
                                    srcSet
                                    sourceUrl
                                    sourceUrlSharp {
                                      childImageSharp {
                                        gatsbyImageData(
                                          formats: [AUTO, WEBP, AVIF]
                                          placeholder: NONE
                                        )
                                      }
                                    }
                                  }
                                }
                                tituloSliderImagenes
                                tituloImagenes
                                tituloCotiza
                                idProyectoCotiza
                                textoBotonCotiza
                                correoCotiza
                                urlWhatsappCotiza
                                esTourMultimedia
                                imagenMultimedia{
                                  ...imagen
                                }
                                urlGraciasCotiza{
                                  ... on Pionero_Proyecto {
                                    uri
                                  }
                                }
                                tituloOtrosDepas
                                proyectoAnteriorOtrosDepas {
                                  ... on Pionero_Proyecto {
                                    uri
                                    title
                                    barrios {
                                        edges {
                                            node {
                                                name
                                            }
                                        }
                                    }
                                    proyecto{
                                      imagenNavegacionAnterior {
                                        ...imagen
                                      }
                                    }
                                  }
                                }
                                proyectoSiguienteOtrosDepas {
                                  ... on Pionero_Proyecto {
                                    uri
                                    title
                                    barrios {
                                        edges {
                                            node {
                                                name
                                            }
                                        }
                                    }
                                    proyecto{
                                      imagenNavegacionSiguiente {
                                        ...imagen
                                      }
                                    }
                                  }
                                }
                                tituloContactanos
                                parrafoContactanos
                                telefonoContactanos {
                                  nombre
                                  telefono
                                }
                              }
                        }
                    }
                }
            }
        }
    }
`

export default function Proyecto_gracias ({data}) {

    const page = data.pionero?.page;
    const proyecto = page.proyecto?.proyectoGracias;

    setTimeout(()=>{
        navigate("/");
    }, 4000);

    const pageUri = ObtenerUri(page.uri);

    if(pageUri === "/gracias-cierra-puertas-navarrete/"){
        if(typeof window !== "undefined"){
          window.fbq('track', 'CompleteRegistration');
        }
    }

        
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
    };

    (typeof window !== `undefined` && proyecto?.proyecto?.bannerColorFondo) && document.documentElement.style.setProperty('--color-pagina',proyecto?.proyecto?.bannerColorFondo);
    (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
        >
        <svg viewBox="0 0 44.34 84.45"><polyline fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3px" points="43.28 1.06 2.12 42.22 43.28 83.39"/></svg>
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
        >
        <svg viewBox="0 0 44.34 84.45"><polyline fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3px" points="1.06 1.06 42.22 42.22 1.06 83.39"/></svg>
        </button>
    );

    const settings2 = {
        dots: true,
        fade: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

  return (
    <Layout className="proyecto" isProyecto={true}>
      <Seo post={page}/>
      <BannerProyecto
        slug={proyecto?.slug}
        distrito={proyecto?.barrios?.edges[0]?.node.name}
        estado={proyecto?.estados?.edges[0]?.node.name}
        titulo={proyecto?.proyecto?.bannerTitulo}
        detalle={proyecto?.proyecto?.bannerDetallle}
        imagen={proyecto?.proyecto?.bannerImagenProyecto}
        fondoColor={proyecto?.proyecto?.bannerColorFondo}
        info={proyecto?.proyecto?.bannerInfo}
        isHome={proyecto?.proyecto?.esHomePet}
        imagenResponsive={proyecto?.proyecto?.bannerResponsiveHomeProyecto}
        textoSecundario={proyecto?.proyecto?.bannerTextoSecundario}
        brochure={proyecto?.proyecto?.brochure?.mediaItemUrl}
        idGaBrochure={proyecto?.proyecto?.idGaBrochure}
      />
      <RodeateProyecto
        titulo={proyecto?.proyecto?.tituloRodeateDe}
        parrafoAvance={proyecto?.proyecto?.parrafoParaGaleriaAvanceDeObra}
        parrafo={proyecto?.proyecto?.parrafoRodeateDe}
        detalle={proyecto?.proyecto?.detalleRodeateDe}
        botones={proyecto?.proyecto?.botonesRodeateDe}
        nColumnas={proyecto?.proyecto?.numeroColumnasRodeateDe}
        aColumnas={proyecto?.proyecto?.anchoColumnasRodeateDe}
        imagenPremio={proyecto?.proyecto?.imagenPremioRodeateDe}
        parrafoPremio={proyecto?.proyecto?.parrafoPremioRodeateDe}
        galeriaObra={proyecto?.proyecto?.galeriaAvanceRodeateDe}
        id={proyecto?.databaseId}
        settings={settings2}
        brochure={proyecto?.proyecto?.brochure?.mediaItemUrl}
      />
      <ImagenesProyecto
        tituloPage={page?.title}
        galeria={proyecto?.proyecto?.sliderImagenes}
        configuracion={settings}
        classTitulo="bg-verde"
        titulo={proyecto?.proyecto?.tituloSliderImagenes}
      />
      <ImagenesProyecto
        tituloPage={page?.title}
        configuracion={settings}
        galeria={proyecto?.proyecto?.galeriaImagenes}
        arrow={false}
        titulo={proyecto?.proyecto?.tituloImagenes}
      />
      <IframeProyectoEnVenta
        titulo={proyecto?.proyecto?.tituloIframe}
        iframe={proyecto?.proyecto?.urlIframeMultimedia}
        isTour={proyecto?.proyecto?.esTourMultimedia}
        imagen={proyecto?.proyecto?.imagenMultimedia}
      />
      <UbicacionProyecto
        tituloPage={page?.title}
        direccion={proyecto?.proyecto?.direccionMapa}
        imagen={proyecto?.proyecto?.imagenMapa}
        botones={proyecto?.proyecto?.botonesMapa}
      />
      <CotizarProyecto
        titulo={proyecto?.proyecto?.tituloCotiza}
        textoBoton={proyecto?.proyecto?.textoBotonCotiza}
        idProyecto={proyecto?.proyecto?.idProyectoCotiza}
        nomProyecto={proyecto?.title}
        isGracias={true}
        tituloGracias={page?.proyecto?.tituloGracias}
        parrafoGracias={page?.proyecto?.parrafoGracias}
      />
      <OtrosProyecto
        titulo={proyecto?.proyecto?.tituloOtrosDepas}
        proyectoAnterior={proyecto?.proyecto?.proyectoAnteriorOtrosDepas}
        proyectoSiguiente={proyecto?.proyecto?.proyectoSiguienteOtrosDepas}
      />
      <ContactoProyecto
        titulo={proyecto?.proyecto?.tituloContactanos}
        parrafo={proyecto?.proyecto?.parrafoContactanos}
        telefonos={proyecto?.proyecto?.telefonoContactanos}
      />
    </Layout>
  )

}
