import React  from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import BannerPagina from "../../components/Banner/BannerPagina"
import Contenido from "../../components/Parrafo/Contenido"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
          title
          uri
          slug
          
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
                altText
                sourceUrl
                srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
                altText
                sourceUrl
                srcSet
            }
            canonical
            cornerstone
            schema {
                articleType
                pageType
                raw
            }
          }
          
          proteccion_al_consumidor {
            bannerTitulo
            bannerColorFondo
            bannerImagenFondo {
              altText
              srcSet
              sourceUrl
              title
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            contenido {
              parrafo
            }
          }
      }
    }
  }
`

export default function Proteccion_consumidor ({data}) {
  const page = data.pionero.page;

  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-pagina',page.proteccion_al_consumidor.bannerColorFondo);
  (typeof window !== `undefined`) && document.documentElement.style.setProperty('--color-texto','#2D2D2D');
  
  return (
    <Layout>
      <Seo post={page}/>
      <h1 className="none">{page.title}</h1>
      <BannerPagina 
        titulo={page.proteccion_al_consumidor.bannerTitulo}
        fondoColor={page.proteccion_al_consumidor.bannerColorFondo}
        imagen={page.proteccion_al_consumidor.bannerImagenFondo}
      />
      <Contenido 
        contenido={page.proteccion_al_consumidor.contenido}
      />
    </Layout>
  )
}